import { FC } from 'react'

const WatchesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="watches-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle
          cx="47.24"
          cy="77"
          r="45.24"
          transform="translate(-38.55 105.28) rotate(-76.72)"
        />
        <circle cx="47.24" cy="77" r="36.08" />
        <path d="M50.8,77c0,1.97-1.6,3.56-3.56,3.56s-3.56-1.6-3.56-3.56,1.6-3.56,3.56-3.56,3.56,1.6,3.56,3.56Z" />
        <line x1="47.24" y1="40.92" x2="47.24" y2="50.33" />
        <line x1="47.24" y1="103.67" x2="47.24" y2="113.08" />
        <line x1="83.32" y1="77" x2="73.91" y2="77" />
        <line x1="20.57" y1="77" x2="11.15" y2="77" />
        <line x1="47.24" y1="77" x2="28.63" y2="57.38" />
        <line x1="47.24" y1="77" x2="61.74" y2="63.65" />
        <path d="M19.65,41.16V10.6c0-4.73,3.87-8.6,8.6-8.6h37.98c4.73,0,8.6,3.87,8.6,8.6v30.55" />
        <path d="M74.83,112.84v30.56c0,4.73-3.87,8.6-8.6,8.6H28.25c-4.73,0-8.6-3.87-8.6-8.6v-30.55" />
      </g>
    </symbol>
  </svg>
)

export default WatchesDefinition
