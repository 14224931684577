import { FC } from 'react'

const MaternityDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="maternity-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M70.46,38.34l24.54,59.55,15.44-13.65L80.11,10.5c-1.09-2.24-3.05-3.94-5.44-4.68l-12.29-3.82" />
        <path d="M62.38,2s-6.35,19.87-33.01,13.37l-7.15,30.14C-1.1,53.7-6.34,87.73,17.62,102.81l-1.27,49.19h57.76v-48.75c0-5.89-1.65-11.4-4.53-16.11-3.41-5.59-4.66-12.23-3.93-18.74,1.04-9.2,2.69-21.58,4.79-30.06" />
        <path d="M30.63,46.34c1.73,.61,3.45,1.22,5.18,1.83" />
      </g>
    </symbol>
  </svg>
)

export default MaternityDefinition
