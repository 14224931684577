import { FC } from 'react'

const SandalsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sandals-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M70.93,23.21L15.48,2.11c-.92-.35-1.94,.19-2.17,1.15l-1.42,6c-.19,.79,.24,1.61,1,1.9L62.76,30.23" />
        <path d="M2,85.3H23.72v-26h0c13.94,3.89,27.27,9.7,39.61,17.26l11.98,7.34c2.47,1.51,5.31,2.32,8.21,2.32h0c8.79,0,17.56-.79,26.21-2.37l42.27-7.7v-7.25c0-1.25-1.14-2.19-2.37-1.94-12.31,2.49-45.97,9.17-50.38,8.75-5.41-.52-16.23-5.28-20.74-8.89-4.51-3.61-28.98-19.32-34.52-21.39-4.11-1.53-25.22-7.17-35.85-9.98-3.11-.82-6.14,1.52-6.14,4.74v45.11Z" />
        <path d="M61.21,55.28S99.76,21.14,99.76,21.14c-6.24-6.54-11.84-12.55-11.84-12.55L44.73,45.74" />
        <path d="M125.38,71.7s-6.04-32.25-6.04-32.25c4.54,2.81,11.37,6.73,14.3,8.4,.91,.52,1.56,1.38,1.8,2.4l4.51,18.64" />
      </g>
    </symbol>
  </svg>
)

export default SandalsWomenDefinition
