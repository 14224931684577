import { FC } from 'react'

const TowelsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="towels-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.99,76.99L2.48,23.89C-.15,12.71,8.33,2,19.81,2h57.25c3.62,0,6.85,2.29,8.03,5.72l20.09,57.95,.14,.4" />
        <path d="M92.66,29.53l37.13-.05,22.08,63.67,.14,.4-.14,.2H31.75c-9.25,0-16.76-7.5-16.76-16.76s7.5-16.76,16.76-16.76H97.34c4.63,0,8.38,3.75,8.38,8.38s-3.75,8.38-8.38,8.38H36.06" />
        <line x1="109.9" y1="29.48" x2="131.98" y2="93.15" />
      </g>
    </symbol>
  </svg>
)

export default TowelsDefinition
