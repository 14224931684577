import { FC } from 'react'

const PyjamasMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="pyjamas-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M120.59,47.03L103.03,10.13h.02L81.74,2H40.88l-17.23,6.58c-2.62,1-4.69,2.85-5.79,5.16L2,47.03l17.86,8.64,9.5-15.31V105.17h63.89V40.42l9.47,15.26,17.86-8.64Z" />
        <path d="M41.42,2c0,9.89,8.9,17.91,19.87,17.91s19.87-8.02,19.87-17.91" />
        <polyline points="29.24 104.95 15.46 146.08 56.09 152 61.25 135.06 66.41 152 107.13 146.08 93.26 104.95" />
      </g>
    </symbol>
  </svg>
)

export default PyjamasMenDefinition
