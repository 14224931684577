import { Config } from '@/core/config'
import { NextRouter } from 'next/router'

export const makeCdnUrl = (path: string, useCdn = true): string => {
  if (!useCdn || path.startsWith('http') || path.startsWith('data:')) {
    return path
  }
  return `${Config.cdnImageBaseUrl}${path.startsWith('/') ? path : `/${path}`}`
}

export const createUrl = (path: string): URL =>
  path
    ? new URL(
        path.startsWith('http')
          ? path
          : `https://example.com${path.startsWith('/') ? path : `/${path}`}`
      )
    : null

const getPageFromPath = (path: string) => {
  const url = createUrl(path)
  return url.searchParams.get('page')
}

const getPageOrDefault = (page: string): number => +(page ?? '1')

export const getPageFromPathOrDefault = (path: string): number =>
  getPageOrDefault(getPageFromPath(path))

export const getPageFromRouterOrDefault = (router: NextRouter): number =>
  getPageOrDefault(getPageFromRouter(router))

export const getPageFromRouter = (router: NextRouter): string => {
  return getPageFromPath(router.asPath) ?? (router.query?.page as string)
}
