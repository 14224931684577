import { useEffect, useState } from 'react'

export const useScroll = (): number => {
  const [scroll, setScroll] = useState(0)

  const onScroll = () => {
    setScroll(document.documentElement.scrollTop)
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  return scroll
}

export const useScrollCondition = (
  condition: (scroll: number) => boolean
): boolean => {
  const [result, setResult] = useState<boolean>(false)

  const onScroll = () => {
    setResult(condition(document.documentElement.scrollTop))
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  return result
}
