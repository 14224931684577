import { FC } from 'react'

const SwimwearMenFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="swimwearmen-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <rect x="37.14" y="4" width="83.73" height="16.07" />
        <path d="M79,66.27s.12,20.15-11.15,37.23L4,90.73c0-29.41,13.12-55.35,33.08-70.66H120.92c19.96,15.31,33.08,41.24,33.08,70.66l-63.85,12.77c-11.26-17.08-11.15-37.23-11.15-37.23" />
        <line x1="72.12" y1="93.7" x2="5.94" y2="80.52" />
        <line x1="86.32" y1="93.7" x2="152.51" y2="80.52" />
        <path d="M74.37,12.04c0,1.33-1.06,2.41-2.38,2.41s-2.38-1.08-2.38-2.41,1.06-2.41,2.38-2.41,2.38,1.08,2.38,2.41Z" />
        <path d="M88.38,12.04c0,1.33-1.06,2.41-2.38,2.41s-2.38-1.08-2.38-2.41,1.06-2.41,2.38-2.41,2.38,1.08,2.38,2.41Z" />
        <path d="M74.26,12.04s-4.18-.34-2.38,12.48c1.8,12.82-2.55,18.68-2.55,18.68" />
        <path d="M83.74,12.04s4.67,9.18,2.38,17.61c-2.29,8.43,3.5,11.36,3.5,11.36" />
      </g>
    </symbol>
  </svg>
)

export default SwimwearMenFrogiesDefinition
