import {
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexDirection,
  flexBasis,
  flexGrow,
  position,
  display
} from 'styled-system'
import Box, { BoxSpan } from './Box'
import styled, { css } from 'styled-components'

const BaseFlex = css`
  display: flex;
  ${alignItems};
  ${alignContent};
  ${justifyContent};
  ${flexWrap};
  ${flexDirection};
  ${flexBasis};
  ${flexGrow};
  ${position};
  ${display};
`

const Flex = styled(Box)`
  ${BaseFlex}
`

export const FlexSpan = styled(BoxSpan)`
  ${BaseFlex}
`

export default Flex
