import { FC } from 'react'

const GlovesFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="gloves-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M54.24,72.84c-1.42-.4-2.8-1.41-3.73-2.91l-14.2-22.75c-1.73-2.77-1.32-6.17,.91-7.56,2.23-1.39,5.47-.27,7.2,2.5l6.16,9.87" />
        <path d="M82.79,12.59c-2.59,.45-4.25,3.46-3.68,6.67l2.62,14.94-1.67,.29-4.15-23.68c-.56-3.21-3.14-5.47-5.73-5.02-2.59,.45-4.25,3.46-3.68,6.67l4.15,23.68-1.67,.29-4.8-27.36c-.56-3.21-3.14-5.47-5.73-5.02-2.59,.45-4.25,3.46-3.68,6.67l4.8,27.36-1.67,.29-4.15-23.68c-.56-3.21-3.14-5.47-5.73-5.02-2.59,.45-4.25,3.46-3.68,6.67l4.15,23.68,.48,2.74,1.91,10.89,1.7,9.7,1.7,9.7c1.19,6.78,7.21,11.55,13.89,11.43l-3.19,.56-.75,31.19,38.05-6.68-11.32-29.07-3.19,.56c6.33-2.16,10.36-8.69,9.17-15.48l-1.7-9.7-1.7-9.7-1.91-10.89-.48-2.74-2.62-14.94c-.56-3.21-3.14-5.47-5.73-5.02Z" />
        <path d="M45.42,73.7l-14.21-19.38c-1.93-2.63-5.24-3.51-7.36-1.96-2.12,1.55-2.28,4.98-.35,7.61l14.21,19.38-1.37,1-16.43-22.4c-1.93-2.63-5.24-3.51-7.36-1.96-2.12,1.55-2.28,4.98-.35,7.61l16.43,22.4-1.37,1-14.21-19.38c-1.93-2.63-5.24-3.51-7.36-1.96-2.12,1.55-2.27,4.98-.35,7.61l14.21,19.38,1.65,2.24,5.53,7.54-9.9-6.12c-2.78-1.72-6.18-1.29-7.56,.94-1.38,2.24-.24,5.47,2.53,7.18l22.81,14.1c1.5,.93,16.34,8.3,22.29,5.23l-2.62,1.92,13.15,28.29,31.15-22.84-8.06-7.37" />
      </g>
    </symbol>
  </svg>
)

export default GlovesFrogiesDefinition
