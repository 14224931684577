import { MarketCode } from '@/network/graphql.g'
export const getTld = (subdomain: string): string => {
  switch (subdomain) {
    case MarketCode.Cs:
      return 'cz'
    default:
      return subdomain
  }
}

export const getMarketFromTld = (tld: string): string => {
  switch (tld) {
    case 'cz':
      return MarketCode.Cs
    default:
      return tld
  }
}

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
export const isAkamai = (domain: string, subdomain: string): boolean => false
