import { FC } from 'react'

const BlazersMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="blazers-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="52.23" y1="2" x2="101.8" y2="2" />
        <line x1="77.01" y1="69.7" x2="77.01" y2="132.9" />
        <path d="M104.4,7.64l18.78,8.89c4.04,1.91,6.93,5.65,7.75,10.05l21.06,111.85-20.29,5.67-15.81-81.76v88.31h-19.75c-10.58,0-19.16-8.49-19.16-18.95h.01c0,10.47-8.58,18.95-19.16,18.95h-19.76V62.33l-15.81,81.76-20.29-5.67L23.06,26.58c.83-4.4,3.71-8.13,7.75-10.05L49.6,7.64" />
        <polyline points="54.28 25.75 48.61 22.79 49.6 7.64 52.23 2 77.01 69.7" />
        <polyline points="57.97 17.67 50.97 33 77.01 69.7" />
        <polyline points="99.74 25.75 105.4 22.79 104.41 7.64 101.78 2 77 69.7" />
        <polyline points="96.05 17.67 103.04 33 77 69.7" />
        <path d="M38.07,108.43v7.89c0,1.33,1.11,2.43,2.46,2.43h15.56c1.35,0,2.46-1.09,2.46-2.43v-7.89h-20.47Z" />
        <path d="M95.37,108.43v7.89c0,1.33,1.11,2.43,2.46,2.43h15.56c1.35,0,2.46-1.09,2.46-2.43v-7.89h-20.47Z" />
        <line x1="93.97" y1="59.19" x2="109.11" y2="59.19" />
        <line x1="38.1" y1="150.65" x2="115.9" y2="150.65" />
      </g>
    </symbol>
  </svg>
)

export default BlazersMenDefinition
