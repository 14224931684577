import { FC } from 'react'

const BeltsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="belts-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="2" y="13.11" width="38.89" height="50" />
        <polyline points="102 63.11 118.67 63.11 118.67 13.11 102 13.11" />
        <path d="M102,13.11H52V63.11h50v11.11H52c-6.14,0-11.11-4.97-11.11-11.11V13.11c0-6.14,4.97-11.11,11.11-11.11h50V13.11Z" />
        <path d="M77,43.67h-19.44c-3.07,0-5.56-2.49-5.56-5.56h0c0-3.07,2.49-5.56,5.56-5.56h19.44c3.07,0,5.56,2.49,5.56,5.56h0c0,3.07-2.49,5.56-5.56,5.56Z" />
        <rect x="118.67" y="7.56" width="11.11" height="61.11" />
        <rect
          x="129.78"
          y="13.11"
          width="22.22"
          height="50"
          transform="translate(281.78 76.22) rotate(180)"
        />
      </g>
    </symbol>
  </svg>
)

export default BeltsDefinition
