import React, { useContext } from 'react'
import useLoading from 'hooks/useLoading'

export const LoadingContext = React.createContext<LoadingContextType>(null)

type LoadingContextType = {
  loading: boolean
  setLoading: (loading: boolean, delayed?: boolean) => void
}

export const useLoadingProvider = (): LoadingContextType =>
  useContext(LoadingContext)

const LoadingProvider: React.FC = ({ children }) => {
  const { loading, setLoading } = useLoading()

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
