import { FC } from 'react'

const WellingtonsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="wellingtons-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M149.22,130.61c-4.84-16.21-60.72-20.1-68.15-42.01L85.59,2H2c1.14,22.07,2.29,44.14,3.43,66.2,.27,5.28,.55,10.55,.82,15.83,.55,10.62,.77,16.19-.52,26.42-1.33,10.54-.93,41.46-.93,41.46H43.18s-.53-16.24-.79-24.36c3.85-1.1,8.04-.12,11.63,1.68,3.58,1.8,6.71,4.37,9.98,6.68,16.01,11.3,6.6,16.88,61.33,16,29.05,0,27.65-9.3,23.9-21.3Z" />
        <line x1="84.58" y1="21.47" x2="2.91" y2="21.47" />
        <path d="M5.73,110.45c-2.14,19.66,33.61,10.33,50.24,14.22,13.41,3.13,15.53,5.83,24.15,12.83,8.26,6.7,43.93,6.76,43.93,6.76,0,0,24.68,2.45,25.67-11.97" />
      </g>
    </symbol>
  </svg>
)

export default WellingtonsDefinition
