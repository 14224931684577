import { InformationH2 } from '@/components/atoms/typography'
import {
  HomeBanner,
  HomeBannerSize,
  SpecialPageBanner
} from '@/network/graphql.g'
import styled from 'styled-components'

export const toHomeBanner = (
  specialPageBanner: SpecialPageBanner,
  size: HomeBannerSize,
  removeTitle = false
): HomeBanner =>
  specialPageBanner
    ? {
        id: specialPageBanner.id,
        imageBig: specialPageBanner.description?.desktopImage,
        imageSmall: specialPageBanner.description?.mobileImage,
        url1: specialPageBanner.bannerUrl,
        size,
        title: removeTitle ? '' : specialPageBanner.description?.description
      }
    : null

export const SpecialLandingPageInformationH2 = styled(InformationH2)`
  text-transform: uppercase;
`

export const SpecialLandingPageButton = styled.span`
  background-color: ${({ theme }) => theme.colors.specialLandingPageBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.onBackground};
  border-left: 2px solid ${({ theme }) => theme.colors.onBackground};
  border-radius: 10px;
  border-right: 2px solid ${({ theme }) => theme.colors.onBackground};
  color: ${({ theme }) => theme.colors.background};
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 auto;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: ${({ enableHoverState, theme }) =>
      enableHoverState ? theme.colors.onBackground : theme.colors.background};
  }
`
