import { FC } from 'react'

const BootsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="boots-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M134.67,138.65c-5.38,2.15-36.07,5.91-44.41,5.91s-22.7-2.15-33.46-3.95c-3.75-.62-9.1-.94-14.88-1.1-2.53-.09-5.15-.13-7.75-.15-8.55-.1-16.94-.01-21.7-.18-9.52-.35-7.35-12.18-5.57-23.29,1.8-11.1,10.41-20.78,1.8-47.65C.09,41.36,2.24,12.56,2.24,12.56l32.5-2.24c0-.12-.02-.25-.03-.37-.34-4.29,3.05-7.95,7.36-7.95h27.81s-5.73,65.7-4.38,84.24c1.35,18.54,25.84,33.05,30.15,36.28,4.31,3.22,19.65,7.52,27.46,7.79,7.81,.27,11.57,3.49,11.57,3.49,0,0,5.38,2.69,0,4.84Z" />
        <path d="M34.74,10.32c2.72,34.04,10.05,101.31,17.03,115.96" />
        <path d="M41.92,139.52c-2.53-.09-5.15-.13-7.75-.15H6.73c-1.79,0-3.23,1.44-3.23,3.22v9.41H41.92v-5.05h0c5.79,.17,11.14,.49,14.88,1.11,10.77,1.79,25.12,3.94,33.47,3.94,1.03,0,2.05-.03,3.08-.08,7.32-.29,14.65-.73,21.91-1.79,6.41-.94,14.07-1.68,19.32-5.64,2.16-1.63,2.42-5.09,2.42-7.65" />
      </g>
    </symbol>
  </svg>
)

export default BootsDefinition
