import { FC, createContext, useContext, useEffect, useRef } from 'react'
import { GetWishlistQuery, Product, Sdk } from '@/network/graphql.g'
import { useCookie } from 'next-cookie'
import { CookieContext } from '@/utils/sessionService'
import useGraphQl from 'hooks/useGraphQl'
import { request, RequestResponse } from '@/utils/graphQl'
import { useUser } from '@/providers/userProvider'
import { notifySuccess } from '@/core/toast'
import useTranslation from 'next-translate/useTranslation'
import { useWishlist } from './wishlistProvider'
import { useMhub } from './mhubProvider'

const WishlistContextDispatch = createContext<WishlistDispatchContextType>(null)

type WishlistDispatchContextType = {
  addToWishlist: (item: Product) => Promise<void>
  removeFromWishlist: (item: Product) => Promise<void>
  clearWishlist: (products: Product[]) => Promise<void>
}

export const useWishlistDispatch = (): WishlistDispatchContextType =>
  useContext(WishlistContextDispatch)

const WishlistDispatchProvider: FC<Rest> = ({ children }) => {
  const { requestNotifyError } = useGraphQl()
  const { isLoggedIn } = useUser()
  const { pushWishlistAddEvent, pushWishlistRemoveEvent } = useMhub()
  const { t } = useTranslation('common')
  const { wishlist: internalWishlist, setWishlist } = useWishlist()
  const wishlist = useRef<Product[]>()

  useEffect(() => {
    wishlist.current = internalWishlist
  }, [internalWishlist])

  const sort = (wishlist: Product[]): Product[] =>
    [...wishlist].sort((a, b) => +a?.id - +b?.id)

  const addToWishlist = async (product: Product): Promise<void> => {
    const newWishlist = !wishlist.current?.find(
      (item) => item?.id === product?.id
    )
      ? sort([...(wishlist.current ?? []), ...[product]])
      : wishlist.current ?? []
    setWishlist(newWishlist)
    if (isLoggedIn) {
      const result = await requestNotifyError((sdk: Sdk) => ({
        method: sdk.addToWishlist,
        variables: {
          productId: product.id
        }
      }))
      if (result.success) {
        pushWishlistAddEvent(product, newWishlist)
      }
    } else {
      setCookie([...getCookie(), product.id])
      pushWishlistAddEvent(product, newWishlist)
    }
    notifySuccess(t('Wishlist.productAdded'))
  }

  const removeFromWishlist = async (product: Product): Promise<void> => {
    const newWishlist =
      wishlist.current?.filter((item) => item?.id !== product.id) ?? []
    setWishlist(newWishlist)
    if (isLoggedIn) {
      const result = await requestNotifyError((sdk: Sdk) => ({
        method: sdk.removeFromWishlist,
        variables: {
          productId: product.id
        }
      }))
      if (result.success) {
        pushWishlistRemoveEvent([product], newWishlist)
      }
    } else {
      setCookie([...getCookie().filter((id) => id !== product.id)])
      pushWishlistRemoveEvent([product], newWishlist)
    }
    notifySuccess(t('Wishlist.productRemoved'))
  }

  const clearWishlist = async (products: Product[]): Promise<void> => {
    setWishlist([])
    if (isLoggedIn) {
      const result = await requestNotifyError((sdk: Sdk) => ({
        method: sdk.clearWishlist
      }))
      if (result.success) {
        pushWishlistRemoveEvent(products, [])
      }
    } else {
      setCookie([])
      pushWishlistRemoveEvent(products, [])
    }
  }

  return (
    <WishlistContextDispatch.Provider
      value={{
        addToWishlist,
        removeFromWishlist,
        clearWishlist
      }}
    >
      {children}
    </WishlistContextDispatch.Provider>
  )
}

export const getWishlistSSR = async (
  sdk: Sdk,
  isLoggedIn: boolean,
  context?: CookieContext
): Promise<Product[]> =>
  getWishlistInternal(
    (ids) =>
      request({
        method: sdk.getWishlist,
        variables: {
          isLoggedIn,
          ids: isLoggedIn ? [] : ids
        }
      }),
    isLoggedIn,
    context
  )

const getWishlistInternal = async (
  getWishlist: (ids: string[]) => Promise<RequestResponse<GetWishlistQuery>>,
  isLoggedIn: boolean,
  context?: CookieContext
): Promise<Product[]> => {
  try {
    const ids = getLocalWishlistIds(context) ?? []
    if (!isLoggedIn && ids.length === 0) {
      return []
    }
    const { success, response } = await getWishlist(ids)
    if (!success) {
      return []
    }
    return isLoggedIn
      ? response?.loggedInWishlist?.wishlistProducts ?? []
      : response?.loggedOutWishlist ?? []
  } catch {
    return []
  }
}

export const getLocalWishlistIds = (context?: CookieContext): string[] =>
  getCookie(context)

export const reuseAmbienteCookie = (context: CookieContext): void => {
  const ids = getLocalWishlistIds(context)
  const cookie = useCookie(context)
  cookie.set(
    cookiePropertyName,
    JSON.stringify(ids.map((item) => item.toString())),
    cookieOptions
  )
}

const cookiePropertyName = 'wishlist'
const cookieOptions = {
  maxAge: 30 * 24 * 60 * 60, // 30 days
  path: '/'
}

const getCookie = (context?: CookieContext): string[] => {
  const cookie = useCookie(context)
  return cookie.get(cookiePropertyName) ?? []
}

const setCookie = (data: string[]): void => {
  const cookie = useCookie()
  cookie.set(cookiePropertyName, data, cookieOptions)
}

export default WishlistDispatchProvider
