import NextLink, { LinkProps } from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'
import { width, height, padding, color } from 'styled-system'

// https://github.com/vercel/next.js/issues/7915
const Link: FC<Rest & LinkProps & { textDecoration?: string }> = ({
  textDecoration,
  children,
  ...rest
}) => (
  <NextLink passHref prefetch={false} {...rest}>
    <StyledA decoration={textDecoration} {...rest}>
      {children}
    </StyledA>
  </NextLink>
)

export default Link

export const StyledA = styled.a`
  width: inherit;
  color: inherit;
  text-decoration: ${(props) => props.decoration ?? 'none'};
  display: inherit;
  ${width};
  ${height};
  ${padding};
  ${color};
`
