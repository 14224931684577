import { FC } from 'react'

const TiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="ties-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="48.19 46.33 58.52 25.78 23.58 25.78 33.91 46.33 48.19 46.33" />
        <polygon points="58.52 136.22 48.19 46.33 33.92 46.33 23.58 136.22 23.58 136.22 40.91 152 58.25 136.22 58.52 136.22" />
        <polygon points="35.84 25.78 23.58 25.78 2 10.35 2 2 35.84 25.78" />
        <polygon points="46.18 25.78 58.45 25.78 80.03 10.35 80.03 2 46.18 25.78" />
        <line x1="2" y1="2" x2="80.03" y2="2" />
        <line x1="68.34" y1="10.21" x2="13.69" y2="10.21" />
      </g>
    </symbol>
  </svg>
)

export default TiesDefinition
