import { FC } from 'react'

const HikingshoesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="hikingshoes-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.39,7.92c-1.84,.18-3.29,1.66-3.43,3.51-.49,6.49-1.59,20.82-1.81,23.53-.18,2.2-.82,12.16-1.28,19.34-.28,4.44,2.81,8.38,7.19,9.15,6.62,1.18,16.92,2.79,28.55,3.81,20.1,1.76,25.67,3.23,33.59,4.7,7.92,1.47,24.06,5.58,39.61,4.7,15.55-.88,38.14-7.93,38.14-7.93,0,0,1.17-9.69-9.39-12.63-10.56-2.94-23.18-2.06-32.27-7.63-7.89-4.84-29.89-36.4-35.6-44.7-.85-1.23-2.3-1.9-3.79-1.76L11.39,7.92Z" />
        <path d="M15,63.97l-8.74-.77c-1.64-.14-3.08,1.07-3.21,2.71l-1.05,13.17c-.14,1.79,1.18,3.37,2.97,3.55l4.46,.43,38.07,3.67c1.34,.13,2.62-.57,3.24-1.76l3.14-6.03c.66-1.27,2.08-1.96,3.49-1.69,7.12,1.37,27.81,5.41,27.99,5.96,.12,.35,.92,1.4,1.65,2.29,.77,.94,1.87,1.55,3.08,1.68,7.13,.76,33.55,2.62,59.15-7.46,1.65-.65,2.75-2.24,2.74-4.02l-.03-6.95" />
        <line x1="69.52" y1="10.52" x2="79.4" y2="10.52" />
        <line x1="75.68" y1="19.77" x2="86.03" y2="19.77" />
        <line x1="83.16" y1="28.8" x2="92.73" y2="28.8" />
        <line x1="88.34" y1="36.94" x2="98.79" y2="36.94" />
        <path d="M6.06,38.06l9.28,.62c10.79,1.09,19.55,9.21,21.45,19.89l.71,7.71" />
        <line x1="9.76" y1="80.45" x2="14.45" y2="80.85" />
        <line x1="24.55" y1="82.1" x2="29.25" y2="82.5" />
        <line x1="39.35" y1="83.63" x2="44.05" y2="84.04" />
        <line x1="98.63" y1="85.31" x2="103.35" y2="85.37" />
        <line x1="117.23" y1="84.79" x2="121.91" y2="84.23" />
        <line x1="135.9" y1="81.68" x2="140.45" y2="80.44" />
      </g>
    </symbol>
  </svg>
)

export default HikingshoesDefinition
