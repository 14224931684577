import { useEffect } from 'react'

export const useAsyncCss = (id: string): void => {
  useEffect(() => {
    const link = document.getElementById(id) as HTMLLinkElement
    if (link && link.nodeName === 'LINK') {
      link.media = 'all'
    }
  }, [])
}
