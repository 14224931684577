import { notifyError } from '@/core/toast'
import useTranslation from 'next-translate/useTranslation'
import Script from 'next/script'
import React, { useContext } from 'react'
import { useLocale } from './storeProvider'

export const useFacebook = (): FacebookContextType => {
  return useContext(FacebookContext)
}

const FacebookContext = React.createContext<FacebookContextType>(null)

type FacebookContextType = {
  getAccessToken: () => Promise<string>
}

declare global {
  interface Window {
    FB: any
  }
}

const FacebookProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('common')
  const locale = useLocale()

  const getAccessToken = () => {
    return new Promise((resolve: (value: string) => void) => {
      const isLoggedIn = (response) =>
        response.status === 'connected' && response.authResponse.accessToken

      window.FB.login(
        function (response) {
          if (isLoggedIn(response)) {
            resolve(response.authResponse.accessToken)
          } else {
            notifyError(t('Login.loginFailed'))
            resolve(null)
          }
        },
        { scope: 'public_profile, email' }
      )
    })
  }

  return (
    <FacebookContext.Provider
      value={{
        getAccessToken
      }}
    >
      <Script strategy="lazyOnload">
        {`window.fbAsyncInit = function() {
                FB.init({
                  appId      : '${process.env.FACEBOOK_ID}',
                  cookie     : true,                    // Enable cookies to allow the server to access the session.
                  xfbml      : true,                    // Parse social plugins on this webpage.
                  version    : 'v14.0'                  // Use this Graph API version for this call.
                })
              };

              (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/${locale?.replace(
                  '-',
                  '_'
                )}/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));`}
      </Script>
      {children}
    </FacebookContext.Provider>
  )
}

export { FacebookProvider }
