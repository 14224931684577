import DateUtils from '@/core/date'
import { Cookies } from '@/core/graphQlCookieManager'
import { useCookie } from 'next-cookie'

type MhubAdsDataOld = {
  adCode: string
  adType: string
  visitAt: number
}

type MhubAdsData = {
  adPlatform: string
  adCodes:
    | {
        fbclid: 'facebook'
      }
    | {
        gclid: 'googleads'
      }
    | {
        wbraid: 'googleads'
      }
    | {
        gbraid: 'googleads'
      }
    | {
        cjevent: 'cjaffiliate'
      }
  visitAt: number
}

type MhubAds = {
  ads: MhubAdsData[]
}

enum Events {
  Cjevent = 'cjevent',
  Fbclid = 'fbclid',
  Gclid = 'gclid',
  Wbraid = 'wbraid',
  Gbraid = 'gbraid'
}

const Platforms: Record<Events, string> = {
  [Events.Cjevent]: 'cjaffiliate',
  [Events.Fbclid]: 'facebook',
  [Events.Gclid]: 'googleads',
  [Events.Wbraid]: 'googleads',
  [Events.Gbraid]: 'googleads'
}

const possibleClickAds = [
  Events.Cjevent,
  Events.Fbclid,
  Events.Gclid,
  Events.Wbraid,
  Events.Gbraid
]

const validDaysPeriod: Record<Events, number> = {
  [Events.Cjevent]: 120,
  [Events.Fbclid]: 90,
  [Events.Gclid]: 90,
  [Events.Wbraid]: 90,
  [Events.Gbraid]: 90
}

const cookieOptions = {
  maxAge: 365 * 24 * 60 * 60, // 1 year
  path: '/',
  secure: true
}

export const getEmptyMhubAds = (): MhubAds => ({ ads: null })

const getRootDomain = (): string => {
  const host = window.location.hostname
  const parts = host.split('.')
  if (parts.length > 2) {
    return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`
  }
  return host
}

export const getMhubAds = (url: string): MhubAds => {
  const events = getEvents()
  possibleClickAds.forEach((adType) => {
    const adCode = getEventCodeFromUrl(url, adType)
    if (
      adCode &&
      !events.find(
        (event) =>
          event.adPlatform === Platforms[adType] &&
          event.adCodes[Object.keys(event.adCodes)?.[0]] === adCode
      )
    ) {
      events.push({
        adPlatform: Platforms[adType],
        adCodes: {
          [adType]: adCode
        },
        visitAt: Math.round(DateUtils.toSeconds(DateUtils.now()))
      } as MhubAdsData)
    }
  })
  useCookie().set(Cookies.clickedAds, events, cookieOptions)
  const cjevent = [...events]
    .reverse()
    .find((event) => event.adPlatform === Platforms[Events.Cjevent])
  if (cjevent) {
    const cjeventAdCode = cjevent.adCodes[Events.Cjevent]
    const existingCjevent = useCookie().get(Cookies.cje)
    const rootDomain = getRootDomain()
    useCookie().remove(Cookies.cje)
    if (!existingCjevent || cjeventAdCode !== existingCjevent) {
      const cjeCookieOptions = {
        ...cookieOptions,
        domain: rootDomain,
        maxAge: 395 * 24 * 60 * 60
      }
      useCookie().set(Cookies.cje, cjeventAdCode, cjeCookieOptions)
    }
  }
  return {
    ads: events.length > 0 ? events : null
  }
}

const getEventCodeFromUrl = (path: string, eventType: Events): string => {
  const url = new URL(`http://example.com${path}`)
  return url.searchParams.get(eventType) ?? null
}

const getEvents = (): MhubAdsData[] => {
  const events: MhubAdsData[] = []
  try {
    const cookieEvents =
      useCookie().get<MhubAdsData[] | MhubAdsDataOld[]>(Cookies.clickedAds) ??
      []
    cookieEvents.forEach((item: MhubAdsData | MhubAdsDataOld) => {
      let event: MhubAdsData
      if ('adCode' in item) {
        // old format
        event = {
          adPlatform: Platforms[item.adType],
          adCodes: {
            [item.adType]: item.adCode
          },
          visitAt: item.visitAt
        } as MhubAdsData
        // new format
      } else if (item.adPlatform === 'google') {
        event = { ...item, adPlatform: 'googleads' } as MhubAdsData
      } else {
        event = item as MhubAdsData
      }

      const adType = Object.keys(event.adCodes)?.[0]
      if (
        event.adPlatform &&
        event.visitAt &&
        event.adCodes &&
        possibleClickAds.map((event) => event.toString()).includes(adType) &&
        DateUtils.addDays(
          DateUtils.fromSeconds(event.visitAt),
          validDaysPeriod[adType]
        ) > DateUtils.now()
      ) {
        events.push(event)
      }
    })
    // eslint-disable-next-line no-empty
  } catch {}

  return events
}
