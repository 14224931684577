import { FC } from 'react'

const BoxersFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="boxer-frogies-icon-defintion">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <rect x="12.77" y="4" width="132.49" height="14.38" />
        <path d="M53.39,18.38s-6.99,90.87,21.23,95.78" />
        <path d="M104.61,18.38s6.99,90.87-21.23,95.78" />
        <path d="M154,132.76l-8.77-114.37H12.77L4,132.76H74.61v-22.63c0-2.42,1.96-4.39,4.39-4.39h0c2.42,0,4.39,1.96,4.39,4.39v22.63h70.61Z" />
      </g>
    </symbol>
  </svg>
)

export default BoxersFrogiesDefinition
