import { FC } from 'react'

const BlousesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="blouses-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M98.68,2c.13,6.48-2.11,17.15-6.39,28.9-3.16,8.69-10.09,19.18-15.29,26.25h0c-5.2-7.07-12.13-17.56-15.29-26.25-4.28-11.75-6.52-22.42-6.39-28.9" />
        <path d="M116.7,65.02c.23-1.54,2.28,7.07,3.3,12.5l10.37,50.02c1.02,5.43,6.15,8.64,11.4,7.14l1.38-.4c5.25-1.5,8.72-7.18,7.73-12.62l-18.95-97.03c-1-5.44-3.82-10.86-6.28-12.05-2.02-.98-20.39-8.07-26.97-10.58" />
        <polyline points="136.31 134.6 139.09 142.95 152 138.52 149.07 129.74" />
        <path d="M37.47,65.21s4.51,5.82,3.99,27.84l-11.24,46.14H123.89l-11.34-46.14c-.53-22.18,4.08-28.07,4.08-28.07" />
        <line x1="77" y1="57.15" x2="77" y2="139.19" />
        <line x1="41.45" y1="93.05" x2="77" y2="76.37" />
        <line x1="55.83" y1="85.68" x2="55.83" y2="71.36" />
        <line x1="112.55" y1="93.05" x2="77" y2="76.37" />
        <line x1="98.17" y1="85.68" x2="98.17" y2="71.36" />
        <line x1="55.32" y1="2" x2="98.68" y2="2" />
        <path d="M55.32,2c-6.65,2.56-24.87,9.6-26.88,10.58-2.46,1.19-5.12,5.75-5.93,10.11-.68,3.69-19.3,98.97-19.3,98.97-1,5.44,2.48,11.11,7.73,12.62l1.38,.4c5.25,1.5,10.37-1.71,11.4-7.14l10.37-50.02c1.02-5.43,2.28-11.08,2.79-12.55" />
        <polyline points="17.69 134.6 14.91 142.95 2 138.52 4.93 129.74" />
      </g>
    </symbol>
  </svg>
)

export default BlousesDefinition
