import { FC } from 'react'

const CourtshoesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="courtshoes-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          id="Courtshoes"
          d="M141.86,94c-9.94-4.41-28.72-12.41-31.31-13.37,0,0-41.95,31.99-97.87-78.63C12.68,2-.3,15.09,2.36,35.24c.37,2.77,1.1,5.07,2.1,7.02h-.01s.01,.02,.04,.06c.24,.46,.49,.9,.76,1.32,2.82,5.24,12.18,25.8,12.18,67.52h7.24l.97-55.94c3.69,1.35,7.04,2.92,9.35,5.54,7.26,8.2,13.58,17.34,19.9,26.28,4.65,6.57,8.25,14.08,14.26,19.56,5.29,4.82,11.1,4.64,17.95,4.51,12.08-.23,24.16-1.67,36.15-3.1,8.32-.99,16.64-2.06,24.94-3.27,0,0,11.88-2.68-6.33-10.74Z"
        />
      </g>
    </symbol>
  </svg>
)

export default CourtshoesDefinition
