import { FC } from 'react'

const ShortsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="shorts-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="68.48 121.64 76.93 85.98 85.38 121.64 152 109.18 129.31 22.61 24.55 22.61 2 109.18 68.48 121.64" />
        <rect x="24.55" y="2" width="104.65" height="20.61" />
      </g>
    </symbol>
  </svg>
)

export default ShortsWomenDefinition
