import { isSSR } from '@/core/utils'
import { useEffect } from 'react'

const useSnapshotInvalidation = (): void => {
  // Disable scroll retention for ios

  const checkIsSafari = async () => {
    return (await import('react-device-detect')).isSafari
  }

  const checkIsIOS = async () => {
    return (await import('react-device-detect')).isIOS
  }

  useEffect(() => {
    if (
      !isSSR &&
      checkIsIOS() &&
      checkIsSafari() &&
      history &&
      history.scrollRestoration &&
      history.scrollRestoration === 'auto'
    ) {
      history.scrollRestoration = 'manual'
    }
  }, [
    !isSSR &&
      checkIsIOS() &&
      checkIsSafari() &&
      history &&
      history.scrollRestoration &&
      history.scrollRestoration === 'auto'
  ])

  // https://github.com/vercel/next.js/issues/10465
  // this will disable scrollRestoration for ios/safari, do we need it? seems to work without that
  /*const alternator = useRef<number>(0)
  // Scroll slightly and alternate between pages to always invalidate image snapshot.
  // See https://git.vtsmedia.com/vtsfans/web-frontend/-/issues/318 for explanation on this effect and the previous
  useEffect(() => {
    const slightScroll = () => {
      if (isIOS && isSafari) {
        window.scrollTo({ left: 0, top: alternator.current })
        alternator.current = Number(!alternator.current)
      }
    }

    router.events.on('routeChangeComplete', slightScroll)

    return () => {
      router.events.off('routeChangeComplete', slightScroll)
    }
  }, [])*/
}

export default useSnapshotInvalidation
