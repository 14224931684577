import { getHost } from '@/providers/storeProvider'
import { Cookie, useCookie } from 'next-cookie'
import { AppContext } from 'next/app'
import { createEmptySession } from './helpers'
import { SSRSession } from './session'

export const getNoAuthSSRSession = (
  context: AppContext | { host: string; cookie: Cookie }
): SSRSession => {
  return {
    session: createEmptySession(),
    host: 'host' in context ? context.host : getHost(context),
    cookie: 'ctx' in context ? useCookie(context.ctx) : context.cookie
  }
}
