import { FC } from 'react'
import { SvgProps } from './svgProps'

const MenuCloseIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 14}
    height={height || 14}
    viewBox="0 0 14 14"
    data-cy="closeIcon"
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="square">
      <g stroke={color || '#000'} strokeWidth="2">
        <g>
          <path
            d="M11.571 0.429L0.429 11.571"
            transform="translate(-119 -172) translate(120 173)"
          />
          <path
            d="M11.571 0.429L0.429 11.571"
            transform="translate(-119 -172) translate(120 173) matrix(-1 0 0 1 12 0)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default MenuCloseIcon
