import { FC } from 'react'

const ShawlsFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="shawls-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M146.62,99.39l-36.11-36.11,20.74-20.74c4.02-4.02,4.02-10.54,0-14.56L107.26,4l-28.02,28.02L51.22,4,27.24,27.98c-4.02,4.02-4.02,10.54,0,14.56l20.74,20.74L11.87,99.39l31.26,31.26,36.11-36.11,36.11,36.11,31.26-31.26Z" />
        <line x1="51.22" y1="4" x2="107.26" y2="4" />
        <line x1="79.24" y1="32.02" x2="110.51" y2="63.28" />
        <line x1="47.98" y1="63.28" x2="79.24" y2="94.54" />
        <line x1="11.87" y1="99.39" x2="4" y2="107.26" />
        <line x1="43.26" y1="130.44" x2="35.39" y2="138.3" />
        <line x1="19.71" y1="107.92" x2="11.85" y2="115.78" />
        <line x1="27.56" y1="116.45" x2="19.7" y2="124.31" />
        <line x1="35.41" y1="123.9" x2="27.54" y2="131.76" />
        <line x1="115.09" y1="130.36" x2="122.96" y2="138.22" />
        <line x1="146.13" y1="98.97" x2="154" y2="106.83" />
        <line x1="123.62" y1="122.51" x2="131.48" y2="130.38" />
        <line x1="132.15" y1="114.66" x2="140.01" y2="122.53" />
        <line x1="139.59" y1="106.82" x2="147.46" y2="114.68" />
      </g>
    </symbol>
  </svg>
)

export default ShawlsFrogiesDefinition
