import { FC } from 'react'

const SneakersDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sneakers-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.42,25.35s.01-.05,.01-.05c1.49-5.16,4.95-9.32,4.95-9.32,0,0,5.02-7.69,10.18-4.74,5.8,3.32,8.92,6.12,16.29,7.27,6.51,1.02,13.9,.41,20.75-3.73,6.85-4.14,10.99-13.93,15.54-12.68,2.63,.72,5.41,2.19,8.21,4.04,7.23,4.78,39.83,28.98,53.93,35.6,1.41,.66,15.82,4.81,16.38,6.12,.67,1.57,.28,2.95-.89,4.17-6.89,7.18-41.06,8.46-45.31,8.44-13.65-.06-59.03,.35-68.23,.56-10,.22-24.17,.72-33.27-3.35-.32-.14-.59-.39-.82-.72-.63-.89-.94-2.39-1.05-4.22-.46-7.08,.76-15.27,1.85-20.85,.37-2.32,.88-4.51,1.47-6.54Z" />
        <path d="M151.91,50.1s.04,8.62-1.13,9.83c-6.89,7.18-41.06,8.46-45.31,8.44-13.65-.06-59.03,.35-68.23,.56-10,.22-24.17,.72-33.27-3.35-.32-.14-.59-.39-.82-.72-.63-.89-1.04-7.46-1.15-9.28" />
        <line x1="103.07" y1="32.06" x2="109.72" y2="25.99" />
        <line x1="94.19" y1="25.99" x2="100.84" y2="19.91" />
        <line x1="85.63" y1="19.91" x2="92.28" y2="13.83" />
      </g>
    </symbol>
  </svg>
)

export default SneakersDefinition
