interface IRoutes {
  home: string
  loginReferer: string
  discount: string
  warehouse: string
  dostavaOdmah: string
  manufacturer: string
  manufacturerDetail: string
  campaign: string
  login: string
  cart: string
  wishlist: string
  search: string
  searchOur: string
  product: string
  about: string
  conditions: string
  b2b: string
  privacyPolicy: string
  cookies: string
  postage: string
  deliveryCost: string
  hiring: string
  contact: string
  sizesGrid: string
  account: string
  orderHistory: string
  orderDetail: string
  checkOrder: string
  cancelOrder: string
  return: string
  returnAdd: string
  returnSuccess: string
  returnDetail: string
  deliveryAddress: string
  deliveryPayment: string
  signUp: string
  registration: string
  forgottenPassword: string
  accountInfo: string
  accountAddAddress: string
  accountChangePassword: string
  accountNewsletter: string
  notFound: string
  internalServerError: string
  unauthorized: string
  giftCard: string
  giftCardCheckout: string
  giftCardCheckoutSuccess: string
  shop: string
  adventCalendar: string
  impressum: string
  thankYou: string
  checkoutSuccess: string
  checkoutWait: string
  micropage: string
  email: string
  top100: string
  gifts: string
  giftsProducts: string
  frogies: string
  valentineCollection: string
  valentineCampaign: string
  summerWithChildren: string
  christmas: string
  summer: string
  blog: string
  checkoutStandby: string
}

export const Routes: IRoutes = {
  login: '/login',
  loginReferer: '/login?referer=/',
  home: '/',
  discount: '/discount',
  warehouse: '/warehouse',
  dostavaOdmah: '/dostava-odmah',
  manufacturer: '/manufacturer',
  manufacturerDetail: '/manufacturer-detail',
  campaign: '/campaign',
  cart: '/cart',
  wishlist: '/wishlist',
  search: '/search/',
  searchOur: '/search-our/',
  product: '/product',
  about: '/information/about',
  b2b: '/information/b2b',
  conditions: '/information/terms',
  cookies: '/information/cookies',
  postage: '/information/postage',
  deliveryCost: '/step1',
  hiring: '/hiring',
  privacyPolicy: '/information/privacy-policy',
  contact: '/contact',
  sizesGrid: '/information/sizes-grid',
  account: '/account',
  orderHistory: '/account/orders',
  orderDetail: '/account/orders/order',
  checkOrder: '/check-order',
  cancelOrder: '/cancel-order',
  return: '/return',
  returnAdd: '/account/orders/return/add',
  returnSuccess: '/account/orders/return/success',
  returnDetail: '/account/orders/return',
  deliveryAddress: '/step2',
  deliveryPayment: '/payment',
  signUp: '/register',
  registration: '/register',
  forgottenPassword: '/lost-password',
  accountInfo: '/account/edit',
  accountAddAddress: '/account/add',
  accountChangePassword: '/account/password',
  accountNewsletter: '/account/newsletter',
  notFound: '/404',
  internalServerError: '/error500',
  unauthorized: '/error401',
  giftCard: '/gift-card',
  giftCardCheckout: '/gift-card/checkout',
  giftCardCheckoutSuccess: '/gift-card/checkoutSuccess',
  shop: '/information/shop',
  adventCalendar: '/advent-calendar',
  impressum: '/information/impressum',
  thankYou: '/thank-you',
  checkoutSuccess: '/checkout-success',
  checkoutWait: '/checkout-wait',
  micropage: '/micropage',
  email: '/email',
  top100: '/topproducts',
  gifts: '/gifts',
  giftsProducts: '/gifts/products',
  frogies: '/landing-page/Frogies',
  valentineCollection: 'campaign/laska-laska-laska',
  valentineCampaign: 'campaign/love',
  summerWithChildren: '/campaign/leto-s-detmilp',
  christmas: '/christmas',
  summer: '/summer',
  blog: '/blog',
  checkoutStandby: '/checkout-standby'
}

// be aware that it won't match pages like /[...slug]
export const FocusRoutes = {
  basic: [Routes.deliveryCost, Routes.deliveryAddress],
  full: [
    Routes.notFound,
    Routes.internalServerError,
    Routes.unauthorized,
    '/_error',
    Routes.email
  ],
  shutDownCountry: [
    Routes.return,
    Routes.account,
    Routes.returnAdd,
    Routes.returnSuccess,
    Routes.returnDetail,
    Routes.orderHistory,
    Routes.accountInfo,
    Routes.accountChangePassword,
    Routes.accountNewsletter,
    Routes.orderDetail,
    Routes.login,
    Routes.loginReferer,
    Routes.forgottenPassword
  ]
}

//except home route "/"
export const blockedRoutesShutDown = Object.values(Routes).filter((rout) => {
  return rout !== Routes.home && !FocusRoutes.shutDownCountry.includes(rout)
})
