import React, { useContext, useState } from 'react'
import { PremiumManufacturer } from '@/network/graphql.g'

const PremiumManufacturersNavigationContext =
  React.createContext<PremiumManufacturer[]>(null)

const PremiumManufacturersNavigationContextDispatch =
  React.createContext<MainNavigationContextDispatchType>(null)

type MainNavigationContextDispatchType = {
  setPremiumManufacturersNavigation: (
    premiumManufacturers: PremiumManufacturer[]
  ) => void
}

export const usePremiumManufacturersNavigation = (): PremiumManufacturer[] =>
  useContext(PremiumManufacturersNavigationContext)

type PremiumManufacturersNavigationProviderProps = {
  premiumManufacturers: PremiumManufacturer[]
}

const PremiumManufacturersNavigationProvider: React.FC<PremiumManufacturersNavigationProviderProps> =
  ({ premiumManufacturers, children }) => {
    const [premiumManufacturersValue, setPremiumManufacturersValue] =
      useState<PremiumManufacturer[]>(premiumManufacturers)

    const setPremiumManufacturersNavigation = (
      premiumManufacturers: PremiumManufacturer[]
    ): void => {
      setPremiumManufacturersValue(premiumManufacturers)
    }

    return (
      <PremiumManufacturersNavigationContextDispatch.Provider
        value={{ setPremiumManufacturersNavigation }}
      >
        <PremiumManufacturersNavigationContext.Provider
          value={premiumManufacturersValue}
        >
          {children}
        </PremiumManufacturersNavigationContext.Provider>
      </PremiumManufacturersNavigationContextDispatch.Provider>
    )
  }

export default PremiumManufacturersNavigationProvider
