import { FC } from 'react'

const HappysocksDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="happysocks-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M151.25,65.19V2h-37.21V55.72c-1.84,1.46-3.58,3.14-5.12,5.09l-26.18,32.97c-9.34,11.76-8.79,27.89,1.23,35.85,10.02,7.96,25.86,4.85,35.2-6.91l26.18-32.97c5.95-7.49,7.87-16.75,5.9-24.55Z" />
        <path d="M131.12,73.88c3.03-7.1,11.83-10.25,20.36-7.65-.07-.35-.14-.7-.23-1.04V2h-37.21V55.72c-1.84,1.46-3.58,3.14-5.12,5.09l-26.18,32.97c-9.34,11.76-8.79,27.89,1.23,35.85,10.02,7.96,25.86,4.85,35.2-6.91l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84Z" />
        <path d="M151.48,66.23c-.07-.35-.14-.7-.23-1.04V2h-37.21V55.72c-1.84,1.46-3.58,3.14-5.12,5.09l-26.18,32.97c-1.17,1.48-2.19,3.02-3.05,4.62,.45-.02,.89-.03,1.35-.03,17.06,0,31.19,12.56,33.64,28.93,1.61-1.34,3.13-2.86,4.49-4.58l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84,3.03-7.1,11.83-10.25,20.36-7.65Z" />
        <line x1="123.54" y1="2" x2="123.54" y2="15.69" />
        <line x1="133.02" y1="2" x2="133.02" y2="15.69" />
        <line x1="142.5" y1="2" x2="142.5" y2="15.69" />
        <path d="M77.17,65.19V2H39.97V55.72c-1.84,1.46-3.58,3.14-5.12,5.09L8.66,93.77C-.68,105.53-.13,121.67,9.89,129.62c10.02,7.96,25.86,4.85,35.2-6.91l26.18-32.97c5.95-7.49,7.87-16.75,5.9-24.55Z" />
        <path d="M57.04,73.88c3.03-7.1,11.83-10.25,20.36-7.65-.07-.35-.14-.7-.23-1.04V2H39.97V55.72c-1.84,1.46-3.58,3.14-5.12,5.09L8.66,93.77C-.68,105.53-.13,121.67,9.89,129.62c10.02,7.96,25.86,4.85,35.2-6.91l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84Z" />
        <path d="M77.4,66.23c-.07-.35-.14-.7-.23-1.04V2H39.97V55.72c-1.84,1.46-3.58,3.14-5.12,5.09L8.66,93.77c-1.17,1.48-2.19,3.02-3.05,4.62,.45-.02,.89-.03,1.35-.03,17.06,0,31.19,12.56,33.64,28.93,1.61-1.34,3.13-2.86,4.49-4.58l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84,3.03-7.1,11.83-10.25,20.36-7.65Z" />
        <line x1="49.46" y1="2" x2="49.46" y2="15.69" />
        <line x1="58.94" y1="2" x2="58.94" y2="15.69" />
        <line x1="68.42" y1="2" x2="68.42" y2="15.69" />
      </g>
    </symbol>
  </svg>
)

export default HappysocksDefinition
