import { FC, memo } from 'react'
import { Flex, Box, Grid } from '@/components/atoms/Grid'
import type { NavigationItem, PremiumManufacturer } from '@/network/graphql.g'
import Link from '@/components/atoms/link'
import { Routes } from 'core/routes'
import styled from 'styled-components'
import MenuItem from '@/components/atoms/menuTextItem'
import {
  SectionSeparator,
  ToolbarSeparator
} from '@/components/atoms/separator'
import Cart from '@/components/molecules/cart'
import { validFor } from '@/theme/validFor'
import MenuBadge from '@/components/atoms/menuBadge'
import { ContentWrapper } from '@/components/organisms/container'
import { ContentPaddings } from './pageContentWrapper'
import { HomeIconDefault } from '../atoms/icons/HomeIconDefault'
import DesktopDropdownMenu from '@/components/organisms/desktopDropdownMenu'
import { useMainNavigationDispatch } from '@/providers/mainNavigationMenuProvider'
import { useScrollCondition } from '../../hooks/useScroll'

type DesktopCategoryMenuWrapperProps = {
  navigationItems: NavigationItem[]
  otherMenuItems: NavigationItem[]
  premiumManufacturers: PremiumManufacturer[]
}

const DesktopCategoryMenuWrapper: FC<DesktopCategoryMenuWrapperProps> = ({
  premiumManufacturers,
  ...rest
}) => {
  const Offset = 139
  const isSticky = useScrollCondition((scroll) => scroll > Offset)

  return (
    <Wrapper>
      <ContentWrapper
        px={{
          wide: ContentPaddings.wide,
          desktop: ContentPaddings.desktop
        }}
      >
        <ToolbarSeparator />
        <DesktopCategoryMenu
          showCart={isSticky}
          premiumManufacturers={premiumManufacturers}
          {...rest}
        />
      </ContentWrapper>
    </Wrapper>
  )
}

type DesktopCategoryMenuProps = {
  navigationItems: NavigationItem[]
  otherMenuItems: NavigationItem[]
  showCart: boolean
  premiumManufacturers: PremiumManufacturer[]
}

const DesktopCategoryMenu: FC<DesktopCategoryMenuProps> = memo(
  ({ navigationItems, otherMenuItems, showCart, premiumManufacturers }) => {
    const { slugPath } = useMainNavigationDispatch()

    return (
      <Box data-cy="headerDesktopNavigation">
        <Flex alignItems="center" justifyContent="stretch" width="100%">
          <Grid
            gridTemplateColumns={{
              wide: '6fr 8fr 3fr',
              desktop: '14fr 21fr 8fr'
            }}
            width="100%"
          >
            <Flex data-cy="headerDesktopCategoryMenu">
              <Link href={Routes.home}>
                <HomeIconDefault
                  cursorOnHover={true}
                  data-cy="headerIconHome"
                  p="0 15px 4px 15px"
                />
              </Link>
              {navigationItems &&
                navigationItems
                  .filter(({ isMainCategory }) => isMainCategory)
                  .map(
                    ({
                      name,
                      children,
                      url,
                      homeBanners,
                      isHighlight,
                      desktopBadge,
                      categoryId
                    }) => {
                      return (
                        <StyledBox
                          key={'desktopddropdown' + url}
                          data-cy="headerDesktopCategoryItem"
                        >
                          <StyledLink href={url}>
                            {desktopBadge && (
                              <StyledMenuBadge
                                imageUrl={desktopBadge}
                                height={30}
                              />
                            )}
                            <StyledCapitalized
                              highlight={isHighlight}
                              m={0}
                              withBadge={desktopBadge}
                              isActive={slugPath.find(
                                (path) => path.id === categoryId
                              )}
                            >
                              {name}
                            </StyledCapitalized>
                          </StyledLink>
                          {children?.length > 0 && (
                            <StyledDesktopDropdownMenu
                              width="100%"
                              subCategories={children}
                              banners={homeBanners}
                              name={name}
                              premiumManufacturers={premiumManufacturers.filter(
                                (item) => item.category === categoryId
                              )}
                            />
                          )}
                        </StyledBox>
                      )
                    }
                  )}
            </Flex>
            <Flex alignItems="center">
              {otherMenuItems &&
                otherMenuItems.map(
                  ({ name, url, isHighlight, desktopBadge }) => (
                    <StyledBox key={url} data-cy={url}>
                      <StyledLink href={url}>
                        {desktopBadge && (
                          <StyledMenuBadge
                            imageUrl={desktopBadge}
                            height={30}
                          />
                        )}
                        <StyledCapitalized
                          m={0}
                          highlight={isHighlight}
                          withBadge={desktopBadge}
                        >
                          {name}
                        </StyledCapitalized>
                      </StyledLink>
                    </StyledBox>
                  )
                )}
            </Flex>
            <Flex justifyContent="flex-end" alignItems="center">
              {showCart && (
                <Cart
                  ml={10}
                  className="sticky"
                  isShowingOnDefaultToolbar={false}
                />
              )}
            </Flex>
          </Grid>
        </Flex>
        <StyledSectionSeparator section={true} />
      </Box>
    )
  },
  (prev, next) =>
    prev.showCart === next.showCart &&
    getChildrenCount(prev.navigationItems) ===
      getChildrenCount(next.navigationItems) &&
    getChildrenCount(prev.otherMenuItems) ===
      getChildrenCount(next.otherMenuItems)
)

const getChildrenCount = (items: NavigationItem[]) =>
  items?.reduce((prev, curr) => prev + (curr.children?.length ?? 0), 0) ?? 0

export default DesktopCategoryMenuWrapper

const Wrapper = styled(Box)`
  position: relative;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 10;
`

const StyledLink = styled(Link)`
  height: 100%;
  display: flex;
  margin-right: 0;
  align-items: center;
`

const StyledDesktopDropdownMenu = styled(DesktopDropdownMenu)`
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`

const StyledCapitalized = styled(MenuItem)`
  margin: ${(props) => (props.isActive ? '19px 13px 17px' : '19px 13px')};
  text-transform: uppercase;
  align-self: center;
  ${(props) =>
    props.withBadge
      ? `
        && {
          margin-left: 10px;
        }
      `
      : ''}
  ${validFor.wide`
    & {
      margin-left: 25px;
      margin-right: 25px;
    }
  `}
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    width: 16px;
    height: 2px;
    background-color: ${(props) =>
      props.highlight
        ? props.theme.colors.accent
        : props.theme.colors.onBackground};
  }
`

const StyledBox = styled(Box)`
  height: 100%;
  display: flex;
  &:hover {
    ${StyledDesktopDropdownMenu} {
      max-height: 1000px;
      border-bottom: 2px solid ${(props) => props.theme.colors.onBackground};
    }
    background-color: ${(props) => props.theme.colors.onBackground};
    ${StyledCapitalized} {
      color: ${(props) => props.theme.colors.background};
    }
    border-top-left-radius: ${(props) => props.theme.borderRadius}px;
    border-top-right-radius: ${(props) => props.theme.borderRadius}px;
  }
`

const StyledMenuBadge = styled(MenuBadge)`
  margin-left: 13px;
  ${validFor.wide`
    margin-left: 25px !important;
  `}
`

const StyledSectionSeparator = styled(SectionSeparator)`
  ${(props) => props.theme.separatorWidth}
  margin: 0 auto;
`
