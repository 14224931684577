const DateUtils = {
  now: (): Date => new Date(),
  addYears: (date: Date, years: number): Date =>
    new Date(
      date.getFullYear() + years,
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    ),
  addDays: (date: Date, days: number): Date => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  },
  addSeconds: (date: Date, seconds: number): Date => {
    const result = new Date(date)
    result.setSeconds(result.getSeconds() + seconds)
    return result
  },
  toSeconds: (date: Date): number => Math.floor(date.getTime() / 1000),
  fromSeconds: (seconds: number): Date => new Date(seconds * 1000),
  fromISO: (date: string): Date => new Date(date)
}

export default DateUtils
