import { MhubExperiments } from '../experimentProvider'
import { getFcid } from './fcidManager'
import { parseJSON } from '@/core/utils'
import md5 from '@/core/crypto/md5'

export enum LoginType {
  Direct = 'direct',
  Facebook = 'facebook',
  Google = 'google',
  Newsletter = 'newsletter',
  CheckouSuccess = 'checkoutSuccess'
}

type MHubUserData = {
  experiments: MhubExperiments
  fcid: string
  email: string
  id: string
  rs: string
  ts: string
  fbLoginId?: string
  phone?: string
  timestamp?: number
}

export type MHubUser = {
  user: MHubUserData
}

export type MHubCustomer = {
  email?: string
  rs?: string
  ts?: string
  fbLogin?: string
  telephone?: string
}

type MhubLoginEvent = {
  event: string
  action: {
    login: {
      type: LoginType
    }
  }
  user: MHubUserData
}

type MhubRegisterEvent = {
  event: string
  action: {
    create: {
      type: LoginType
    }
  }
  user: MHubUserData
}

const localStorageUserPropertyName = 'mhub_user_entity'

const createMhubUser = (
  customer: MHubCustomer,
  experiments: MhubExperiments
): MHubUser => {
  const email = customer?.email?.toLowerCase()?.trim() ?? null
  const id = email
    ? md5(`${email}${process.env.MHUB_AUTH_KEY_VALUE_SALT}`)
    : null

  return {
    user: {
      ...{
        experiments,
        fcid: getFcid(),
        rs: 'new',
        ts: 'visitor',
        timestamp: Date.now()
      },
      email,
      id,
      phone: customer?.telephone ?? null,
      fbLoginId: customer?.fbLogin ?? null,
      ...(customer?.rs ? { rs: customer?.rs } : {}),
      ...(customer?.ts ? { ts: customer?.ts } : {})
    }
  }
}

export const getMhubUser = (
  customer: MHubCustomer,
  experiments: MhubExperiments
): MHubUser => {
  const userEntity = createMhubUser(customer, experiments)
  try {
    let actualMhubUserEntity = parseJSON(
      localStorage.getItem(localStorageUserPropertyName)
    ) as MHubUser

    if (
      actualMhubUserEntity === null ||
      actualMhubUserEntity === undefined ||
      actualMhubUserEntity.constructor !== {}.constructor
    ) {
      actualMhubUserEntity = userEntity
      localStorage.setItem(
        localStorageUserPropertyName,
        JSON.stringify(userEntity)
      )
    }

    /**
     * we wanna change whole userEntity only in case email is present because that means user logged himself in,
     * made an order or registered
     * we dont wanna changed user data after user logged out so everything except fcid number
     * must stay intact (note: segment cant changed after logout only when user registered himself or made an order)
     */
    if (userEntity && actualMhubUserEntity && userEntity.user.email !== null) {
      localStorage.setItem(
        localStorageUserPropertyName,
        JSON.stringify(userEntity)
      )
      actualMhubUserEntity = userEntity
    } else {
      /**
       * from logged out user entity only take fcid numbers(can change through special request)
       * and current running tests from cookie dont change anything else
       */
      if (userEntity && actualMhubUserEntity) {
        let change = false

        if (
          userEntity.user.fcid !== null &&
          userEntity.user.fcid !== actualMhubUserEntity.user.fcid
        ) {
          actualMhubUserEntity.user.fcid = userEntity.user.fcid
          change = true
        }

        actualMhubUserEntity.user.experiments = userEntity.user.experiments

        if (change) {
          localStorage.setItem(
            localStorageUserPropertyName,
            JSON.stringify(actualMhubUserEntity)
          )
        }
      }
    }

    return actualMhubUserEntity
    // eslint-disable-next-line no-empty
  } catch {}
  return userEntity
}

export const getLoginEvent = (
  type: LoginType,
  user: MHubUser
): MhubLoginEvent => ({
  ...{
    event: 'action.login.user',
    action: {
      login: {
        type
      }
    }
  },
  ...user
})

export const getCustomerFromMailing = (
  type: LoginType,
  user: MHubUser
): MhubLoginEvent => ({
  ...{
    event: 'action.mailing.user',
    action: {
      login: {
        type
      }
    }
  },
  ...user
})

export const getRegisterEvent = (
  type: LoginType,
  user: MHubUser
): MhubRegisterEvent => ({
  ...{
    event: 'action.create.user',
    action: {
      create: {
        type
      }
    }
  },
  ...user
})
