import {
  getSdk as getBaseSdk,
  Sdk,
  SdkFunctionWrapper
} from '@/network/graphql.g'
import {
  notifySuccess as notifySuccessToast,
  notifyError as notifyErrorToast
} from '@/core/toast'
import useTranslation from 'next-translate/useTranslation'
import {
  baseSdkWrapper,
  getMainResponse,
  makeGraphQLClient,
  request as graphQlRequest,
  RequestProps,
  RequestResponse
} from '@/utils/graphQl'
import { useHost } from '@/providers/storeProvider'
import { useLoadingProvider } from '@/providers/loadingProvider'
import * as Dom from 'graphql-request/dist/types.dom'
import { useUser } from '@/providers/userProvider'
import { useCookie } from 'next-cookie'

export type Request = <T, V>(
  props: RequestNotifyProps<T, V> | RequestNotifyWithSdkProps<T, V>,
  disableLoading?: boolean,
  delayedLoading?: boolean,
  abortSignal?: AbortSignal,
  options?: Dom.RequestInit
) => Promise<RequestResponse<T>>

export type UseGraphQlType = {
  request: Request
  requestNotifyError: <T, V>(
    props: RequestNotifyErrorProps<T, V> | RequestNotifyErrorWithSdkProps<T, V>,
    disableLoading?: boolean,
    delayedLoading?: boolean,
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ) => Promise<RequestResponse<T>>
  requestNotifySuccessError: <T, V>(
    props:
      | RequestNotifySuccessErrorProps<T, V>
      | RequestNotifySuccessErrorWithSdkProps<T, V>,
    disableLoading?: boolean,
    delayedLoading?: boolean,
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ) => Promise<RequestResponse<T>>
}

type RequestNotifyBaseProps<T, V> = RequestNotifyProps<T, V> &
  NotifyErrorProps<T> &
  NotifySuccessProps<T>

type RequestNotifyBaseWithSdkProps<T, V> = (
  sdk: Sdk
) => RequestNotifyBaseProps<T, V>

type RequestNotifyProps<T, V> = RequestProps<T, V>

type RequestNotifyWithSdkProps<T, V> = (sdk: Sdk) => RequestNotifyProps<T, V>

type RequestNotifyErrorProps<T, V> = RequestNotifyProps<T, V> &
  NotifyErrorProps<T>

type RequestNotifyErrorWithSdkProps<T, V> = (
  sdk: Sdk
) => RequestNotifyErrorProps<T, V>

type RequestNotifySuccessErrorProps<T, V> = RequestNotifyProps<T, V> &
  NotifyErrorProps<T> &
  NotifySuccessProps<T>

type RequestNotifySuccessErrorWithSdkProps<T, V> = (
  sdk: Sdk
) => RequestNotifySuccessErrorProps<T, V>

type NotifyErrorProps<T> = {
  errorText?: (response?: T) => string
}

type NotifySuccessProps<T> = {
  successText?: (response?: T) => string
}

const useGraphQl = (): UseGraphQlType => {
  const host = useHost()
  const { t } = useTranslation('common')
  const { setLoading } = useLoadingProvider()
  const cookie = useCookie()
  const { getSession } = useUser()

  const getSdk = async (
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ): Promise<Sdk> => {
    const session = await getSession()
    return getBaseSdk(
      makeGraphQLClient(session, host, cookie, abortSignal, options),
      mhubClientSdkWrapper
    )
  }

  const mhubClientSdkWrapper: SdkFunctionWrapper = async <T,>(
    action: () => Promise<T>,
    operationName: string
  ): Promise<T> => {
    return baseSdkWrapper(action, operationName)
  }

  async function requestNotifyBase<T, V>(
    props: RequestNotifyBaseProps<T, V> | RequestNotifyBaseWithSdkProps<T, V>,
    notifySuccess: boolean,
    notifyError: boolean,
    disableLoading: boolean,
    delayedLoading: boolean,
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ): Promise<RequestResponse<T>> {
    let requestProps: RequestNotifyBaseProps<T, V>
    !disableLoading && setLoading(true, delayedLoading)
    if (typeof props === 'function') {
      try {
        !disableLoading && setLoading(true, delayedLoading)
        const sdk = await getSdk(abortSignal, options)
        requestProps = (props as RequestNotifyBaseWithSdkProps<T, V>)(sdk)
      } catch {
        return { success: false, abort: false, response: null }
      } finally {
        !disableLoading && setLoading(false)
      }
    } else {
      requestProps = props
    }

    const {
      method,
      variables,
      requestHeaders,
      managedCookiesForRequest,
      managedCookiesForResponse,
      successPredicate,
      mainResponse: mainResponseFunc,
      successText,
      errorText
    } = requestProps
    const result = await graphQlRequest({
      method,
      variables,
      requestHeaders,
      managedCookiesForRequest,
      managedCookiesForResponse,
      successPredicate,
      mainResponse: mainResponseFunc
    })

    !disableLoading && setLoading(false)

    const mainResponse = getMainResponse(result.response, mainResponseFunc)
    const responseMessage =
      mainResponse &&
      typeof mainResponse === 'object' &&
      'responseMessage' in mainResponse
        ? mainResponse['responseMessage']
        : null

    if (
      result.success &&
      notifySuccess &&
      ((successText && successText(result.response)) || responseMessage)
    ) {
      const text = successText(result.response) ?? responseMessage
      if (text) {
        notifySuccessToast(text)
      }
    }

    if (!result.success && !result.abort && notifyError) {
      const text =
        (errorText ? errorText(result.response) : null) ??
        responseMessage ??
        result.error?.response?.errors[0]?.message ??
        t('Common.generalError')
      if (text) {
        notifyErrorToast(text)
      }
    }

    return result
  }

  async function requestNotifySuccessError<T, V>(
    props:
      | RequestNotifySuccessErrorProps<T, V>
      | RequestNotifySuccessErrorWithSdkProps<T, V>,
    disableLoading = false,
    delayedLoading = true,
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ): Promise<RequestResponse<T>> {
    return requestNotifyBase(
      props,
      true,
      true,
      disableLoading,
      delayedLoading,
      abortSignal,
      options
    )
  }

  async function requestNotifyError<T, V>(
    props: RequestNotifyErrorProps<T, V> | RequestNotifyErrorWithSdkProps<T, V>,
    disableLoading = false,
    delayedLoading = true,
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ): Promise<RequestResponse<T>> {
    return requestNotifyBase(
      props,
      false,
      true,
      disableLoading,
      delayedLoading,
      abortSignal,
      options
    )
  }

  async function request<T, V>(
    props: RequestNotifyProps<T, V> | RequestNotifyWithSdkProps<T, V>,
    disableLoading = false,
    delayedLoading = true,
    abortSignal?: AbortSignal,
    options?: Dom.RequestInit
  ): Promise<RequestResponse<T>> {
    return requestNotifyBase(
      props,
      false,
      false,
      disableLoading,
      delayedLoading,
      abortSignal,
      options
    )
  }

  return {
    requestNotifySuccessError,
    requestNotifyError,
    request
  }
}

export default useGraphQl
