import { FC, useContext } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import styled, { useTheme } from 'styled-components'
import { Body2, Small3, Tiny2 } from '@/components/atoms/typography'
import useTranslation from 'next-translate/useTranslation'
import CartDropDownProductItem from '@/components/molecules/cart/new/cartDropDownProductItem'
import CartSummaryServiceLabelCartDropDown from '@/components/molecules/cart/new/cartSummaryServiceLabelCartDropDown'
import { makePriceString, PriceSignType } from '@/core/toStringHelper'
import { Routes } from '@/core/routes'
import { RollingSadFace } from '@/components/atoms/animatedFaces'
import { transparentize } from 'polished'
import Link from '@/components/atoms/link'
import ContentPlaceholder from '@/components/molecules/contentPlaceholder'
import { CurrencyContext } from '@/providers/currencyProvider'
import Trans from 'next-translate/Trans'
import { Order } from '@/network/graphql.g'
import ShippingInfo from '@/components/molecules/ShippingInfo'
import { useAsset } from '@/providers/assetsProvider'
import { SadDogSmileFace } from '../atoms/dogsSmileFace'

type CartDropdownProps = {
  order: Order
  count: number
} & Rest

const CartDropdown: FC<CartDropdownProps> = ({ order, count, ...rest }) => {
  const defaultCurrency = useContext(CurrencyContext)
  const { t } = useTranslation('common')
  const theme = useTheme()
  const { isCountryForDogSmileFace } = useAsset()

  return (
    <StyledDropDown data-cy="cartDropDown" {...rest}>
      <StyledContent data-cy="countProducts">
        <StyledTitle data-cy={count}>
          {count > 0 ? (
            <Trans
              i18nKey="common:CartModal.title"
              components={[<span />]}
              values={{ count }}
            />
          ) : (
            t('CartModal.titleEmpty')
          )}
        </StyledTitle>
        <ContentPlaceholder
          m={10}
          IllustrationElement={
            isCountryForDogSmileFace ? (
              <SadDogSmileFace small />
            ) : (
              <RollingSadFace />
            )
          }
          title={t('Cart.Empty.title')}
          text={t('Cart.Empty.text')}
          display={count === 0 ? 'block' : 'none'}
        />
        <StyledProductsBox display={count === 0 ? 'none' : 'flex'}>
          {order?.products?.map((product, offset) => (
            <CartDropDownProductItem
              key={offset}
              product={product}
              showSeparator={offset + 1 < (order?.products?.length ?? 0)}
              data-cy="cartDropDownProductItem"
            />
          ))}
        </StyledProductsBox>
        <StyledSummaryWrapper
          flex="0 0 auto"
          display={count === 0 ? 'none' : 'flex'}
          data-cy="summaryWrapper"
        >
          <CartSummaryServiceLabelCartDropDown
            highlightValue={true}
            highlightTitle={true}
            textColor={theme.colors.accentSecondary}
            title={order?.cartPrices?.todaySavingsTitle}
            value={makePriceString({
              allowZero: true,
              price: Math.abs(order?.cartPrices?.todaySavings ?? 0),
              currency: order?.cartPrices?.currency ?? defaultCurrency,
              signType: PriceSignType.minus
            })}
            data-cy="spare"
          />
          <CartSummaryServiceLabelCartDropDown
            highlightValue={true}
            textColor={theme.colors.accent}
            title={order?.cartPrices?.priceWithVatTitle}
            value={makePriceString({
              allowZero: true,
              price: order?.cartPrices?.priceWithVat ?? 0,
              currency: order?.cartPrices?.currency ?? defaultCurrency
            })}
            data-cy="totalPrice"
          />

          <Link href={Routes.cart}>
            <StyledOrderText mb={0} data-cy="orderButton">
              {t('CartModal.confirmOrder')}
            </StyledOrderText>
          </Link>
          <Tiny2
            m={0}
            mt={14}
            textAlign="center"
            display="inline-block"
            data-cy="freeDelivery"
          >
            <ShippingInfo
              discountDelivery={order?.cartPrices?.discountDelivery}
              currency={order?.cartPrices?.currency ?? defaultCurrency}
              highlightElement={<StyledHighlighted />}
            />
          </Tiny2>
        </StyledSummaryWrapper>
      </StyledContent>
    </StyledDropDown>
  )
}

export default CartDropdown

const StyledDropDown = styled(Box)`
  background-color: transparent;
  position: absolute;
  right: 0;
  width: 360px;
  z-index: 100;
`

const StyledContent = styled(Flex)`
  flex-direction: column;
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.onBackground};
  border-radius: ${(props) => props.theme.borderRadius}px;
`

const StyledTitle = styled(Body2)`
  display: inline-block;
  padding: 14px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.onBackground};
  margin: 0;
  background-color: ${(props) =>
    transparentize(0.97, props.theme.colors.onBackground)};
`

const StyledSummaryWrapper = styled(Flex)`
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.onBackground};
`

const StyledProductsBox = styled(Flex)`
  max-height: 300px;
  flex-direction: column;
  overflow-y: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: auto;
`

const StyledOrderText = styled(Small3)`
  width: 100%;
  padding: 14px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.accentSecondary};
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  color: ${(props) => props.theme.colors.background};
  &:hover {
    background-color: ${(props) => props.theme.colors.successTertiary};
  }
`

const StyledHighlighted = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.accentSecondary};
`
