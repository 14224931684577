import { FC } from 'react'
import { SvgProps } from './svgProps'

const NextArrowIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    data-cy="nextArrow"
    xmlns="http://www.w3.org/2000/svg"
    width={width || 9}
    height={height || 17}
    viewBox="0 0 9 17"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={color || '#000'} strokeWidth="2">
        <path
          d="M72.04 171.278L65.04 178.278 72.04 185.278"
          transform="translate(-64 -170) matrix(-1 0 0 1 137.08 0)"
        />
      </g>
    </g>
  </svg>
)

export default NextArrowIcon
