import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useEffect } from 'react'
import type { LoadingBarRef } from 'react-top-loading-bar'
import { usePrevious } from './usePrevious'

type LoadingProps = {
  initialLoading?: boolean
  initialLoadingOffset?: number
}

const useLoading = ({
  initialLoading = false,
  initialLoadingOffset = 1000
}: LoadingProps = {}): {
  loading: boolean
  setLoading: (loading: boolean, delayed?: boolean) => void
  setLoadingOffset: Dispatch<SetStateAction<number>>
} => {
  const [loadingOffset, setLoadingOffset] =
    useState<number>(initialLoadingOffset)
  const [loadingCount, setLoadingCount] = useState<number>(0)
  const prevLoadingCount = usePrevious(loadingCount)
  const [delayedloading, setDelayedLoading] = useState<boolean>(false)
  const timeoutHandler = useRef<ReturnType<typeof setTimeout>>()

  const clearLaodingTimeout = () => {
    clearTimeout(timeoutHandler.current)
  }

  const handleStart = (offset?: number) => {
    clearLaodingTimeout()
    timeoutHandler.current = setTimeout(() => {
      setDelayedLoading(true)
    }, offset ?? loadingOffset)
  }
  const handleComplete = () => {
    clearLaodingTimeout()
    setDelayedLoading(false)
  }

  const setLoading = (loading: boolean, delayed = true): void => {
    if (loading) {
      if (!delayed) {
        immediatelly.current = true
      }
      setLoadingCount((count) => ++count)
    } else {
      setLoadingCount((count) => (count === 0 ? count : --count))
    }
  }

  useEffect(() => {
    setLoading(initialLoading)
    return clearLaodingTimeout()
  }, [])

  const immediatelly = useRef<boolean>(false)

  useEffect(() => {
    if ((prevLoadingCount ?? 0) === 0 && loadingCount >= 1) {
      handleStart(immediatelly.current ? 0 : null)
    } else if ((prevLoadingCount ?? 0) >= 1 && loadingCount === 0) {
      immediatelly.current = false
      handleComplete()
    }
  }, [loadingCount])

  return { loading: delayedloading, setLoading, setLoadingOffset }
}

type useRouterLoadingProps = {
  loadingBarRef?: React.MutableRefObject<LoadingBarRef>
  loadingOffset?: number
}

export const useRouterLoading = ({
  loadingBarRef,
  loadingOffset = 1000
}: useRouterLoadingProps): boolean => {
  const router = useRouter()
  const { loading, setLoading } = useLoading({
    initialLoadingOffset: loadingOffset
  })
  const url = useRef<string>(null)

  useEffect(() => {
    const handleStart = (_url, { shallow }) => {
      if (!shallow && url.current !== _url) {
        url.current = _url
        setLoading(true)
        if (loadingBarRef?.current) {
          loadingBarRef.current.continuousStart(undefined, 500)
        }
      }
    }

    const handleComplete = (_url, { shallow }) => {
      if (!shallow && url.current === _url) {
        url.current = null
        setLoading(false)
        if (loadingBarRef?.current) {
          loadingBarRef.current.complete()
        }
      }
    }

    const handleError = (_err, url, options) => {
      handleComplete(url, options)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleError)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleError)
    }
  }, [router])

  return loading
}

export default useLoading
