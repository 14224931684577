import { Config } from '@/core/config'
import { useRef } from 'react'
import { debounce } from 'throttle-debounce'

type MHubBanner = {
  id?: string
  name?: string
  creative?: string
  position?: string
}

type MHubBannerAction = {
  event?: string
  action: MHubBannerImpress | MHubBannerShow
}

export type MHubBannerProps = {
  id?: string
  title?: string
  position?: string
  page?: string
  description?: string
  imgSrc?: string
  primaryUrl?: string
  sortOrder?: string
  destinationUrl1?: string
  imageUrl?: string
  routerPath?: string
  category?: string
}

type MHubBannerImpress = {
  impress: { banners: MHubBanner[] | null }
}
type MHubBannerShow = {
  show: { banners: MHubBanner[] | null }
}

export const useMhubTrackBanner = (
  pushToMhub: (data: unknown) => void
): {
  addImpressBanner: (banner: MHubBannerProps) => void
  getShowBanners: (banner: MHubBannerProps) => void
} => {
  const trackedBanners = useRef<MHubBanner[]>([])

  const getMhubTrackedBanner = (banner: MHubBannerProps): MHubBanner => {
    const {
      id,
      title,
      position,
      page,
      description,
      imgSrc,
      primaryUrl,
      sortOrder,
      destinationUrl1,
      imageUrl,
      routerPath: brand,
      category
    } = banner
    const setOptionBanner = (option: string | undefined): string =>
      option ? `${option.toString().toUpperCase()}-` : ''
    const trackBanner = {
      id,
      name: title || primaryUrl || destinationUrl1,
      creative: Config.cdnImageBaseUrl + '/' + (imgSrc || imageUrl),
      position:
        setOptionBanner(brand ? `BRAND_${brand}` : '') +
        setOptionBanner(page ? `PAGE_${page}` : '') +
        setOptionBanner(position ? `POSITION_${position}` : '') +
        setOptionBanner(description ? `DESCRIPTION_${description}` : '') +
        setOptionBanner(sortOrder ? `ORDER_${sortOrder}` : '') +
        setOptionBanner(category ? `CATEGORY_${category}` : '')
    }
    if (trackBanner.position.slice(-1) === '-') {
      trackBanner.position = trackBanner.position.slice(0, -1)
    }
    return trackBanner
  }

  const addImpressBanner = (banner: MHubBannerProps): void => {
    trackedBanners.current = [
      ...trackedBanners.current,
      getMhubTrackedBanner(banner)
    ]
    pushTrackedBannerDebounced()
  }

  const pushTrackedBannerDebounced = debounce(1000, () => {
    pushTrackedBaners(trackedBanners.current)
    trackedBanners.current = []
  })

  const pushTrackedBaners = (banners: MHubBanner[]): void => {
    pushToMhub(getMhubEmptyBanners())
    pushToMhub(getMhubBanners(banners))
  }

  const getMhubEmptyBanners = () => getMhubBanners(null)

  const getMhubBanners = (banners: MHubBanner[]): MHubBannerAction => ({
    ...(banners?.length > 0 ? { event: 'action.impress.banners' } : {}),
    ...{
      action: {
        impress: {
          banners: banners?.length > 0 ? banners : null
        }
      }
    }
  })

  const getShowBanners = (banner: MHubBanner): MHubBannerAction => ({
    event: 'action.show.banners',
    action: {
      show: {
        banners: [getMhubTrackedBanner(banner)]
      }
    }
  })

  return {
    addImpressBanner,
    getShowBanners
  }
}
