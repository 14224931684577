import React, { useContext, useState } from 'react'
import { NavigationItem } from '@/network/graphql.g'
import { SlugPathItem } from '@/types/slugPath'

const MainNavigationContext = React.createContext<NavigationItem>(null)
const MainNavigationContextDispatch =
  React.createContext<MainNavigationContextDispatchType>(null)

type MainNavigationContextDispatchType = {
  setMainNavigation: (value: NavigationItem) => void
  slugPath: SlugPathItem[]
  updateSlugPath: (newSlugPath: SlugPathItem[]) => void
}

export const useMainNavigation = (): NavigationItem =>
  useContext(MainNavigationContext)

export const useMainNavigationDispatch =
  (): MainNavigationContextDispatchType =>
    useContext(MainNavigationContextDispatch)

type MainNavigationProviderProps = {
  value: NavigationItem
}

const MainNavigationProvider: React.FC<MainNavigationProviderProps> = ({
  value,
  children
}) => {
  const [mainNavigationInternal, setMainNavigationInternal] =
    useState<NavigationItem>(value)
  const [slugPath, setSlugPath] = useState<SlugPathItem[]>([])

  const updateSlugPath = (newSlugPath: SlugPathItem[]) => {
    setSlugPath(newSlugPath)
  }

  const setMainNavigation = (value: NavigationItem): void => {
    setMainNavigationInternal(value)
  }

  return (
    <MainNavigationContextDispatch.Provider
      value={{ setMainNavigation, slugPath, updateSlugPath }}
    >
      <MainNavigationContext.Provider value={mainNavigationInternal}>
        {children}
      </MainNavigationContext.Provider>
    </MainNavigationContextDispatch.Provider>
  )
}

export default MainNavigationProvider
