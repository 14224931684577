type MhubExperimentEvent = {
  event?: string
  action: {
    impress: {
      experiment: [
        {
          id: string
          name: string
          variantId: number
          variant: string
        }
      ]
    }
  }
}

export type ExperimentMhubItem = {
  id: string
  name: string
  variantId: number
  variant: string
}

export const getExperimentEvent = (
  experiment: ExperimentMhubItem
): MhubExperimentEvent => {
  return {
    event: 'action.impress.experiment',
    action: {
      impress: {
        experiment: [
          {
            id: experiment?.id,
            name: experiment?.name,
            variantId: experiment?.variantId,
            variant: experiment?.variant
          }
        ]
      }
    }
  }
}

export const getMhubEmptyExperimentEvent = (): MhubExperimentEvent => {
  return {
    action: {
      impress: {
        experiment: null
      }
    }
  }
}
