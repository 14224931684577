import { FC } from 'react'
import styled, { css, useTheme } from 'styled-components'
import {
  Capitalized,
  Capitalized2,
  Small,
  Small3,
  Tiny,
  Tiny2
} from '@/components/atoms/typography'
import {
  display,
  minWidth,
  width,
  space,
  margin,
  padding,
  flexGrow,
  color
} from 'styled-system'
import { transparentize } from 'polished'
import { validFor } from '@/theme/validFor'
import { MenuCloseIconDefault } from './icons/MenuCloseIconDefault'
import { NextArrowIconDefault } from './icons/NextArrowIconDefault'
import { Rating } from 'react-simple-star-rating'

type ButtonProps = {
  isDisabled?: boolean
  title: string
  onClick: (e: Event) => void
} & Rest

const Button: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  children,
  ...rest
}) => (
  <StyledButton
    onClick={makeOnClickAction(isDisabled, onClick)}
    isDisabled={isDisabled}
    {...rest}
  >
    <StyledSmall my={14} mx={38}>
      {title || children}
    </StyledSmall>
  </StyledButton>
)

export const BorderedButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => (
  <StyledBorderedButton
    onClick={makeOnClickAction(isDisabled, onClick)}
    isDisabled={isDisabled}
    {...rest}
  >
    <Small my={1} mx={3}>
      {title}
    </Small>
  </StyledBorderedButton>
)

export const SmallBorderedButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => (
  <StyledBorderedButton
    onClick={makeOnClickAction(isDisabled, onClick)}
    isDisabled={isDisabled}
    {...rest}
  >
    <Tiny2 my={1} mx={3}>
      {title}
    </Tiny2>
  </StyledBorderedButton>
)

export const ConfirmButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => (
  <StyledConfirmButton
    onClick={makeOnClickAction(isDisabled, onClick)}
    isDisabled={isDisabled}
    {...rest}
  >
    <Capitalized my={15} mx={3}>
      {title}
    </Capitalized>
  </StyledConfirmButton>
)

export const SmallBlackButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => (
  <StyledSmallBlackButton
    onClick={makeOnClickAction(isDisabled, onClick)}
    isDisabled={isDisabled}
    {...rest}
  >
    <Tiny2 m={0}>{title}</Tiny2>
  </StyledSmallBlackButton>
)

export const TightBlackButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => (
  <StyledSmallBlackButton
    onClick={makeOnClickAction(isDisabled, onClick)}
    isDisabled={isDisabled}
    {...rest}
  >
    <Tiny my="8px">{title}</Tiny>
  </StyledSmallBlackButton>
)

export const BigBlackButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <StyledBigButton
      backgroundColor={theme.colors.onBackground}
      onClick={makeOnClickAction(isDisabled, onClick)}
      isDisabled={isDisabled}
      {...rest}
    >
      <Capitalized2 m={0}>{title}</Capitalized2>
    </StyledBigButton>
  )
}

export const BigAccentButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <StyledBigButton
      backgroundColor={theme.colors.accent}
      onClick={onClick}
      isDisabled={isDisabled}
      {...rest}
    >
      <Capitalized2 m={0}>{title}</Capitalized2>
    </StyledBigButton>
  )
}

export const BigGrayButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <StyledBigButton
      backgroundColor={transparentize(0.97, theme.colors.onBackground)}
      color={theme.colors.onBackground}
      onClick={onClick}
      isDisabled={isDisabled}
      {...rest}
    >
      <Capitalized2 m={0}>{title}</Capitalized2>
    </StyledBigButton>
  )
}

export const RateButton: FC<ButtonProps> = ({
  title,
  onClick,
  isDisabled,
  arrowWidth,
  arrowHeight,
  starSize,
  rating,
  nextArrow,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <StyledRateButton
      onClick={onClick}
      isDisabled={isDisabled}
      rating={rating}
      nextArrow={nextArrow}
      {...rest}
    >
      {rating && (
        <Rating
          SVGstrokeColor={theme.colors.background}
          SVGstorkeWidth={1}
          emptyColor={theme.colors.toolbar}
          allowFraction={true}
          size={starSize}
          readonly={true}
          {...rest}
        />
      )}
      <Small3 m={0}>{title}</Small3>
      {nextArrow && (
        <NextArrowIconDefault
          color={theme.colors.background}
          width={arrowWidth}
          height={arrowHeight}
        />
      )}
    </StyledRateButton>
  )
}

export const CloseIconButton: FC<
  { onClick: (e) => void; showBackground?: boolean } & Rest
> = ({ onClick, showBackground = true, ...rest }) =>
  showBackground === true ? (
    <StyledIconButton onClick={onClick}>
      <MenuCloseIconDefault opacity={0.5} width={13} height={13} {...rest} />
    </StyledIconButton>
  ) : (
    <StyledIconButton onClick={onClick} backgroundColor="transparent">
      <MenuCloseIconDefault opacity={0.5} width={13} height={13} {...rest} />
    </StyledIconButton>
  )

const makeOnClickAction =
  (isDisabled: boolean, onClick: (e: Event) => void): ((e: Event) => void) =>
  (e) => {
    e.preventDefault()
    !isDisabled && onClick(e)
  }

const ButtonStyleProps = css`
  ${space};
  ${width};
  ${display};
  ${minWidth};
  ${margin};
  ${padding};
  ${flexGrow};
  ${color};
`

const StyledSmall = styled(Small)`
  font-size: 12px;
`

const makeBackgroundHoverColor = (
  color: string,
  isDisabled: boolean,
  amount = 0.2
): css => css`
  ${validFor.desktop`
  &:hover {
    ${!isDisabled ? `background-color: ${transparentize(amount, color)}` : ''}; 
  }
`}
`

const ButtonBase = css`
  background: none;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  &:hover {
    ${(props) => !props.isDisabled && 'cursor: pointer;'};
  }
  ${ButtonStyleProps};
`

const ButtonBaseWithDefaultHoverColor = css`
  ${ButtonBase};
  ${(props) =>
    makeBackgroundHoverColor(
      props.theme.colors.onBackground,
      props.isDisabled,
      0.97
    )};
`

const StyledIconButton = styled.button`
  padding: 5px;
  border: 0;
  border-radius: 50% !important;
  ${ButtonBaseWithDefaultHoverColor}
`

const StyledBorderedButton = styled.button`
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  border: solid 1px #979797;
  ${ButtonBaseWithDefaultHoverColor}
`

const StyledButton = styled.button`
  ${ButtonBaseWithDefaultHoverColor};
  padding: 0;
  border: 0;
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  ${ButtonStyleProps};
`

export const BlackButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.onBackground};
  color: ${(props) => props.theme.colors.background};
  ${(props) =>
    makeBackgroundHoverColor(
      props.theme.colors.onBackground,
      props.isDisabled
    )};
`

export const AccentButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.background};
  ${(props) =>
    makeBackgroundHoverColor(props.theme.colors.accent, props.isDisabled)};
`

const StyledConfirmButton = styled.button`
  ${ButtonBase};
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  border: 0;
  background-color: ${(props) => props.theme.colors.accentSecondary};
  color: ${(props) => props.theme.colors.background};
  ${(props) =>
    makeBackgroundHoverColor(
      props.theme.colors.accentSecondary,
      props.isDisabled
    )};
  ${ButtonStyleProps};
`

const StyledSmallBlackButton = styled.button`
  ${ButtonBase};
  border: 0;
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  background-color: ${(props) => props.theme.colors.onBackground};
  color: ${(props) => props.theme.colors.background};
  text-align: center;
  padding: 0 9px;
  ${(props) =>
    makeBackgroundHoverColor(
      props.theme.colors.onBackground,
      props.isDisabled
    )};
  ${ButtonStyleProps};
`

const StyledBigButton = styled.button`
  ${ButtonBase};
  text-transform: uppercase;
  border: 0;
  color: ${(props) => props.color || props.theme.colors.background};
  padding: 15px 12px;
  ${(props) =>
    makeBackgroundHoverColor(
      props.backgroundColor || props.theme.colors.background,
      props.isDisabled
    )};
  ${ButtonStyleProps};
`

const StyledRateButton = styled.button`
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 180px;
  height: 36px;
  color: ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.accent};
  border: none;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
