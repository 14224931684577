import { FC, useState } from 'react'
import Image from 'next/image'
import { Config, ImageSizeKey } from '@/core/config'
import { parseUrl } from 'next/dist/shared/lib/router/utils/parse-url'
import { formatUrl } from 'next/dist/shared/lib/router/utils/format-url'
import { applyImagePolicy, ImageQuality } from '@/utils/imageExtension'
import { SentryError } from '@/core/sentryError'

type ResponsiveImageProps = {
  width?: number | string
  height?: number | string
  imageUrl: string
  imageSizeKey?: ImageSizeKey
  layout?: 'fixed' | 'intrinsic' | 'responsive' | 'fill'
  useCdn?: boolean
  optimize?: boolean
  quality?: ImageQuality
} & Rest
const ResponsiveImage: FC<ResponsiveImageProps> = ({
  imageUrl,
  width,
  height,
  layout = 'responsive',
  imageSizeKey,
  useCdn = true,
  quality,
  ...rest
}) => {
  const [errorImage, setErrorImage] = useState(false)

  const handleErrorImage = () => {
    setErrorImage(true)

    new SentryError('Image is undefined', {
      imageUrl: imageUrl
    })
  }

  if (!imageUrl) {
    return null
  }
  if (imageSizeKey && !imageUrl.includes('impolicy')) {
    const parsedUrl = parseUrl(imageUrl)
    const imageSize = Config.imageSizes[imageSizeKey]

    parsedUrl.query[
      Config.imagePolicyQueryParameter
    ] = `${imageSize.width}x${imageSize.height}`
    imageUrl = formatUrl(parsedUrl)
  }

  const props = {
    src: errorImage
      ? 'https://i.factcool.com/no_image.png'
      : applyImagePolicy(imageUrl, { useCdn, quality }),

    width: layout !== 'fill' ? width : null,
    height: layout !== 'fill' ? height : null,
    layout: layout,
    objectFit: 'contain' as
      | 'contain'
      | 'fill'
      | 'cover'
      | 'none'
      | 'scale-down',

    unoptimized: true
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <Image
      {...props}
      {...rest}
      data-cy="productImages"
      unoptimized
      onError={handleErrorImage}
    />
  )
}

export default ResponsiveImage
