import { FC } from 'react'

const UnderwearWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="udnerwear-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="11.5" y="2" width="130.86" height="6.93" />
        <path d="M2,32.01s41.59,9.03,56.09,32.29c14.5,23.26-2.46,42.41-2.46,42.41" />
        <polygon points="142.5 8.93 77 8.93 11.5 8.93 2 32.01 55.63 106.7 77 106.7 98.37 106.7 152 32.01 142.5 8.93" />
        <path d="M152,32.01s-41.59,9.03-56.09,32.29c-14.5,23.26,2.46,42.41,2.46,42.41" />
        <line x1="56.62" y1="62.1" x2="97.21" y2="62.1" />
      </g>
    </symbol>
  </svg>
)

export default UnderwearWomenDefinition
