import { FC } from 'react'

const CapsFrogiesDefintion: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="caps-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M60.9,35.9c-1.89-3.16-2.98-6.86-2.98-10.82,0-11.64,9.44-21.08,21.08-21.08s21.08,9.44,21.08,21.08c0,4.02-1.13,7.79-3.08,10.98" />
        <path d="M16.86,99.3c0-36.3,27.82-65.72,62.14-65.72s62.14,29.42,62.14,65.72" />
        <rect x="4" y="99.53" width="150" height="36.77" rx="9" ry="9" />
        <line x1="14.4" y1="99.84" x2="14.4" y2="136.29" />
        <line x1="30.55" y1="99.84" x2="30.55" y2="136.29" />
        <line x1="46.7" y1="99.84" x2="46.7" y2="136.29" />
        <line x1="62.85" y1="99.84" x2="62.85" y2="136.29" />
        <line x1="79" y1="99.84" x2="79" y2="136.29" />
        <line x1="95.15" y1="99.84" x2="95.15" y2="136.29" />
        <line x1="127.45" y1="99.84" x2="127.45" y2="136.29" />
        <line x1="143.6" y1="99.84" x2="143.6" y2="136.29" />
        <line x1="111.3" y1="99.84" x2="111.3" y2="136.29" />
      </g>
    </symbol>
  </svg>
)

export default CapsFrogiesDefintion
