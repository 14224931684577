import { FC } from 'react'

const DressesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="dresses-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="119.8" y1="90.39" x2="131.18" y2="86.12" />
        <path d="M87.21,2l11.43,3.54c2.21,.69,4.04,2.26,5.06,4.34l30,61.79c1.42,3.02,1.17,6.57-.67,9.36l-1.9,2.87-12.75,4.79-3.32-.91c-3.23-.89-5.76-3.38-6.69-6.59l-13.63-45.49" />
        <line x1="16.81" y1="90.39" x2="5.42" y2="86.12" />
        <path d="M41.88,35.69l-13.63,45.49c-.93,3.21-3.46,5.71-6.69,6.59l-3.32,.91-12.75-4.79-1.9-2.87c-1.84-2.79-2.1-6.33-.67-9.36L32.91,9.88c1.01-2.08,2.84-3.65,5.06-4.34l11.43-3.54s2.82,34.09,37.82,70.66" />
        <path d="M89.11,74.99s2.15-25.35,5.62-39.3" />
        <path d="M42.09,35.64c3.96,15.8,5.33,39.35,5.33,39.35" />
        <path d="M89.2,74.73H47.65l-26.1,70.39c0,2.97,25.28,6.88,46.88,6.88s46.88-3.91,46.88-6.88l-26.1-70.39Z" />
        <path d="M87.21,2s-4.01,22.55-18.79,45.1" />
        <line x1="49.39" y1="2" x2="87.21" y2="2" />
      </g>
    </symbol>
  </svg>
)

export default DressesDefinition
