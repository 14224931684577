
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@/core/wdyr'
import { ThemeProvider } from 'styled-components'
import App, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app'
import { addBreakpointsToTheme } from 'theme'
import Container from '@/components/organisms/container'
import DocumentHead from '@/components/atoms/documentHead'
import RootProvider from '@/providers/rootProvider'
import React, { useEffect, useRef } from 'react'
import '@/theme/informationStyles.css'
import '@/theme/fonts.css'
import StoreProvider, {
  StoreContext,
  useWecomaCookie
} from '@/providers/storeProvider'
import Spinner, { SpinnerSize } from '@/components/molecules/spinner'
import styled from 'styled-components'
import { useRouterLoading } from 'hooks/useLoading'
import 'react-toastify/dist/ReactToastify.css'
import { LoadingContext } from '@/providers/loadingProvider'
import { Routes } from '@/core/routes'
import { getSdk, requestThrow401 } from '@/utils/graphQl'
import { Customer, MarketCode, SeoTags, Store } from '@/network/graphql.g'
import { MhubApiDataType } from '@/network/models/mhub'
import { isLoggedInBySession } from '@/providers/userProvider'
import { isDevelopment, logApi } from '@/core/utils'
import { getNoAuthSSRSession } from '@/core/auth/getNoAuthSSRSession'
import LoadingBar from 'react-top-loading-bar'
import type { LoadingBarRef } from 'react-top-loading-bar'
import { FetchError } from '@/core/auth/fetchError'
import ForwardedError from '@/components/atoms/forwardedError'
import useSnapshotInvalidation from 'hooks/useSnapshotInvalidation'
import { HideChatbotStyle } from '@/theme/chatbotStyle'
import dynamic from 'next/dynamic'
import SpeedCurve from '@/core/speedCurve/speedCurve'
import { useAsyncCss } from 'hooks/useAsyncCss'
import { GoogleOAuthProvider } from '@react-oauth/google'
import './globalStyle.css'
import GlobalStyleCSS from '@/theme/globalSyle'
import { isAmioChatbotSupported } from '@/core/amioChatbotConfig'

const DynamicToast = dynamic(() => import('@/components/organisms/toast'), {
  ssr: false
})
const DynamicCookieConsent = dynamic(
  () => import('@/components/molecules/cookieConsent'),
  {
    ssr: false
  }
)
const DynamicScrollToTop = dynamic(
  () => import('@/components/molecules/scrollToTop'),
  {
    ssr: false
  }
)

type MyAppProps = {
  error?: string
  currentPathName: string
  store: Store
  apiData: MhubApiDataType
  seoTags: SeoTags
} & AppProps
function MyApp(props: MyAppProps): JSX.Element {
  if (props.error) {
    return <ForwardedError errorText={props.error} />
  }

  useSnapshotInvalidation()

  const { Component, currentPathName } = props
  const pageProps = props?.pageProps as BaseAppProps
  const ref = useRef<LoadingBarRef>(null)

  const {
    session,
    host,
    referer,
    facebookRegistration,
    ip,
    serverTime,
    runningUserExperiments,
    runningExperiments,
    mainNavigation,
    premiumManufacturers,
    order,
    disableDaktela
  } = pageProps

  useRouterLoading({ loadingBarRef: ref })

  const store = pageProps.store ?? props.store
  const apiData = pageProps.apiData ?? props.apiData
  const seoTags = pageProps.seoTags ?? props.seoTags
  const supportsAmioChatbot: boolean = isAmioChatbotSupported(store)

  const activeCustomer: Customer = isLoggedInBySession(session)
    ? ({
        id: '-1',
        firstname: session.firstname,
        lastname: session.lastname
      } as Customer)
    : null

  useEffect(() => {
    const logs = apiData?.logs ?? []
    logs.forEach((log) => {
      // eslint-disable-next-line no-console
      logApi && console.log(`api log ${log}`)
    })
  }, [apiData?.logs])

  useAsyncCss('luigi-autocomplete')

  if (!Intl.PluralRules) {
    import('intl-pluralrules')
  }

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <StoreProvider store={store} host={host}>
        <StoreContext.Consumer>
          {({ theme, currency, isFactcool }) => {
            return (
              <>
                {isFactcool && store.market.code === MarketCode.Sk && (
                  <SpeedCurve luxId={process.env.SPEEDCURVE_ID} />
                )}
                <DocumentHead seoTags={seoTags} host={host} />
                <ThemeProvider theme={addBreakpointsToTheme(theme)}>
                  <HideChatbotStyle
                    disableDaktela={supportsAmioChatbot || disableDaktela}
                    disableAmio={disableDaktela}
                  />
                  <GlobalStyleCSS
                    accent={theme.colors.accent}
                    onBackground={theme.colors.onBackground}
                  />

                  <RootProvider
                    navigationItem={mainNavigation}
                    premiumManufacturers={premiumManufacturers}
                    currentPathName={currentPathName}
                    currency={currency}
                    user={activeCustomer}
                    store={store}
                    ip={ip}
                    serverTime={serverTime}
                    order={order}
                    referer={referer}
                    session={session}
                    runningUserExperiments={runningUserExperiments}
                    runningExperiments={runningExperiments}
                    seoTags={seoTags}
                  >
                    <Container facebookRegistration={facebookRegistration}>
                      <LoadingBar
                        color={theme.colors.accent}
                        ref={ref}
                        shadow={true}
                        height={4}
                        waitingTime={500}
                      />
                      <LoadingContext.Consumer>
                        {({ loading }) => loading && <StyledSpinner />}
                      </LoadingContext.Consumer>
                      {!currentPathName?.startsWith(Routes.giftsProducts) && (
                        <DynamicScrollToTop
                          showAmioChatbot={
                            supportsAmioChatbot && !disableDaktela
                          }
                          showUnder={350}
                        />
                      )}
                      {!useWecomaCookie(store) && <DynamicCookieConsent />}
                      <DynamicToast />
                      <Component {...props.pageProps} />
                    </Container>
                  </RootProvider>
                </ThemeProvider>
              </>
            )
          }}
        </StoreContext.Consumer>
      </StoreProvider>
    </GoogleOAuthProvider>
  )
}

MyApp.getInitialProps = async (ctx: AppContext) => {
  const pageProps = await App.getInitialProps(ctx)

  const currentPathName = ctx?.ctx?.pathname
  let store: Store = null
  let apiData: MhubApiDataType = null
  let seoTags: SeoTags = null
  // load store/mhub in case of error - error pages don't have getServerSideProps
  if (
    [
      Routes.notFound,
      Routes.internalServerError,
      Routes.unauthorized,
      '/_error'
    ].includes(currentPathName)
  ) {
    const session = await getNoAuthSSRSession(ctx)
    const sdkApiData = getSdk(session)
    const { success, response, error } = await requestThrow401({
      method: sdkApiData.sdk.getErrorData,
      successPredicate: (response) => !!response?.store
    })
    if (!success) {
      return {
        error:
          (error instanceof FetchError
            ? error.getErrorText()
            : error?.toString()) ?? '500 Internal Server Error'
      }
    }
    store = response?.store ?? null
    apiData = sdkApiData.apiData ?? null
    seoTags = response?.seoTags ?? null
  }
  return {
    ...pageProps,
    currentPathName,
    store,
    apiData,
    seoTags
  }
}

const __Page_Next_Translate__ = MyApp

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  // eslint-disable-next-line no-console
  isDevelopment && console.log(`${metric.name}: ${metric.value}`)
}

const StyledSpinner = styled(Spinner)`
  position: fixed;
  margin-left: -${SpinnerSize / 2}px;
  margin-top: -${SpinnerSize / 2}px;
  top: 50%;
  left: 50%;
  z-index: 1000;
`


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l.replace(/-[a-z]{2}XxX[a-z]{2}$/, "")}/${n}.json`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  