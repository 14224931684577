import React, { FC, useContext } from 'react'
import { Sdk } from '@/network/graphql.g'
import { notifyError } from '@/core/toast'
import useTranslation from 'next-translate/useTranslation'
import {
  signInByCredentials,
  signInByProvider,
  signOut
} from '@/core/auth/authenticator'
import { useRouter } from 'next/router'
import { useHost } from './storeProvider'
import { Routes } from '@/core/routes'
import { getLocalWishlistIds } from './wishlistDispatchProvider'
import useGraphQl from 'hooks/useGraphQl'
import { useLoadingProvider } from './loadingProvider'
import { FetchError } from '@/core/auth/fetchError'
import { parseJSON } from '@/core/utils'
import { LoginType } from './mhub/userManager'
import { clearSessionId, getSessionId } from '@/utils/sessionService'
import { Session } from '@/core/auth/session'
import { getAuthorizationHeader } from '@/core/fetchData'

type AuthContextType = {
  loginByCredentials: (email: string, password: string) => void
  loginByFacebook: (accessToken: string, callbackUrl?: string) => void
  loginByGoogle: (accessToken: string, callbackUrl?: string) => void
  logout: () => void
}
const AuthContext = React.createContext<AuthContextType>(null)

export const useAuth = (): AuthContextType => useContext(AuthContext)

const AuthProvider: FC<Rest> = ({ children }) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const host = useHost()
  const { request } = useGraphQl()
  const { setLoading } = useLoadingProvider()

  const getCallbackUrl = (type: LoginType, url?: string): string => {
    const callbackUrl = `${host}${url ?? router.query.referer ?? ''}`
    return `${callbackUrl}${
      callbackUrl.includes('?') ? '&' : '?'
    }loginSuccess=${type}`
  }

  const mergeWishlist = async () => {
    const data = getLocalWishlistIds()
    if (data.length > 0) {
      await request((sdk: Sdk) => ({
        method: sdk.batchAddToWishlist,
        variables: {
          productsIds: data
        }
      }))
    }
  }

  const resetSessionId = async (session: Session) => {
    const oldSessionId = getSessionId()
    clearSessionId()
    await request((sdk: Sdk) => ({
      method: sdk.changeSessionId,
      variables: {
        oldSessionId,
        newSessionId: getSessionId()
      },
      requestHeaders: {
        Authorization: getAuthorizationHeader(session?.accessToken)
      }
    }))
  }

  const loginByCredentials = async (email: string, password: string) => {
    setLoading(true, false)
    try {
      const session = await signInByCredentials(email, password)
      await resetSessionId(session)
      await mergeWishlist()
      window.location.replace(getCallbackUrl(LoginType.Direct))
    } catch {
      notifyError(t('Login.loginFailed'))
    } finally {
      setLoading(false)
    }
  }

  const loginByFacebook = async (accessToken: string, callbackUrl?: string) => {
    setLoading(true, false)
    try {
      const session = await signInByProvider(accessToken, 'facebook')
      await resetSessionId(session)
      await mergeWishlist()
      window.location.replace(getCallbackUrl(LoginType.Facebook, callbackUrl))
    } catch (e) {
      let errorMessage = t('Login.loginFailed')
      if (e instanceof FetchError) {
        const error = parseJSON(parseJSON(e?.getErrorText())?.['error'])
        if (error?.['error'] === 'duplicate_email') {
          errorMessage = t('Login.loginFailedDuplicateEmail')
        }
      }
      notifyError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const loginByGoogle = async (accessToken: string, callbackUrl?: string) => {
    setLoading(true, false)
    try {
      const session = await signInByProvider(accessToken, 'google')
      await resetSessionId(session)
      await mergeWishlist()
      window.location.replace(getCallbackUrl(LoginType.Google, callbackUrl))
    } catch (e) {
      const errorMessage = t('Login.loginFailed')
      notifyError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const logout = async () => {
    await signOut()
    clearSessionId()
    window.location.replace(Routes.home)
  }

  return (
    <AuthContext.Provider
      value={{ loginByCredentials, loginByFacebook, loginByGoogle, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
