import { FC } from 'react'

const RunningshoesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="runningshoes-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.3,55.06c7.04,.85,126.76,7.32,136.35-1.9,3.7-3.56,4.47-9.35-8.44-10.51s-25.23-1.41-34.91-6.95c-7.84-4.49-32.68-21.2-41.83-27.38-2.01-1.36-4.65-1.32-6.64,.07-7.76,5.44-28.01,17.57-43.28,8.53C5.86,11.78,6.23,7.8,7.07,4.98c1.33-4.49,5.96-3.69,6.56,.96,.47,3.62,.03,9.19-2.98,17.61-5.53,15.44-6.45,21.51-6.45,21.51-.74,6.17,2.78,9.14,9.1,10.01Z" />
        <path d="M72.82,14.2l-6.44,6.44" />
        <path d="M81.99,20.33l-6.44,6.44" />
        <path d="M90.87,26.42c-5.57,5.57-6.44,6.44-6.44,6.44" />
        <path d="M14.13,55.15l-7.35-.15c-2.33,0-4.2-.02-4.62,4.72-.26,2.98-.89,8.47,5.36,10.06,23.38,5.93,96.55,.99,123.2-2.22,12.78-1.65,15.41-5.43,18.95-12.81l1.98-4.13" />
        <path d="M19.59,55.5s12.85-34.06,49.03,2.12" />
        <path d="M19.26,63.71c35.91,1.13,44.59,1.33,44.59,1.33" />
      </g>
    </symbol>
  </svg>
)

export default RunningshoesDefinition
