import { FC } from 'react'

const ShawlsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="shawls-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M144.62,97.39l-36.11-36.11,20.74-20.74c4.02-4.02,4.02-10.54,0-14.56L105.26,2l-28.02,28.02L49.22,2,25.24,25.98c-4.02,4.02-4.02,10.54,0,14.56l20.74,20.74L9.87,97.39l31.26,31.26,36.11-36.11,36.11,36.11,31.26-31.26Z" />
        <line x1="49.22" y1="2" x2="105.26" y2="2" />
        <line x1="77.24" y1="30.02" x2="108.51" y2="61.28" />
        <line x1="45.98" y1="61.28" x2="77.24" y2="92.54" />
        <line x1="9.87" y1="97.39" x2="2" y2="105.26" />
        <line x1="41.26" y1="128.44" x2="33.39" y2="136.3" />
        <line x1="17.71" y1="105.92" x2="9.85" y2="113.78" />
        <line x1="25.56" y1="114.45" x2="17.7" y2="122.31" />
        <line x1="33.41" y1="121.9" x2="25.54" y2="129.76" />
        <line x1="113.09" y1="128.36" x2="120.96" y2="136.22" />
        <line x1="144.13" y1="96.97" x2="152" y2="104.83" />
        <line x1="121.62" y1="120.51" x2="129.48" y2="128.38" />
        <line x1="130.15" y1="112.66" x2="138.01" y2="120.53" />
        <line x1="137.59" y1="104.82" x2="145.46" y2="112.68" />
      </g>
    </symbol>
  </svg>
)

export default ShawlsDefinition
