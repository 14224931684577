import { createUrl, makeCdnUrl } from './urlHelper'

export enum ImageMode {
  Akamai,
  Cloudflare,
  Local
}

export enum ImageQuality {
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'mediumHigh',
  High = 'high'
}

export const ImageSizes = {
  '100x100': {
    width: 100,
    height: 100
  },
  '100x150': {
    width: 100,
    height: 150
  },
  '330x495': {
    width: 330,
    height: 495
  },
  '410x615': {
    width: 410,
    height: 615
  },
  '800x800': {
    width: 800,
    height: 800
  },
  '800x1200': {
    width: 800,
    height: 1200
  },
  '1400x1400': {
    width: 1400,
    height: 1400
  }
}

type Path = {
  path: string
}
type Cdn = {
  useCdn?: boolean
}
type Size = {
  width?: number
  height?: number
  size?: {
    width: number
    height: number
  }
}
type Quality = {
  quality?: ImageQuality
}
type Mode = {
  mode?: ImageMode
}
type Enhanced = {
  enhanced?: boolean
}

abstract class ImageExtension {
  abstract resize(props: Path & Size): string
  abstract applyQuality(props: Path & Quality): string

  private isProcessed = (path): boolean => {
    // cloudflare
    if (path.match(/cdn-cgi\/image/)) {
      return true
    }
    // local
    if (path.match(/cache2\/[0-9]+x[0-9]+/)) {
      return true
    }
    // akamai
    // TODO implement is processed check
    return false
  }

  public getPath = ({
    useCdn,
    quality,
    ...props
  }: Path & Size & Quality & Cdn) =>
    !props.path ||
    props.path.startsWith('data:') ||
    this.isProcessed(props.path)
      ? props.path
      : makeCdnUrl(
          this.applyQuality({ path: this.resize(props), quality }),
          useCdn
        )
}

class AkamaiExtension extends ImageExtension {
  resize({ path, width, height, size }: Path & Size & Mode): string {
    if (!width && !height && !size) {
      return path
    }
    const url = createUrl(path)
    if (!url) {
      return path
    }
    url.searchParams.append('impolicy', 'resize')
    if (width || size?.width) {
      url.searchParams.append(
        'width',
        width?.toString() ?? size?.width?.toString()
      )
    }
    if (height || size?.height) {
      url.searchParams.append(
        'height',
        height?.toString() ?? size?.height?.toString()
      )
    }
    return `${url.pathname}${url.search}`
  }

  applyQuality({ path, quality }: Path & Quality): string {
    if (!quality) {
      return path
    }
    const url = createUrl(path)
    if (!url) {
      return path
    }
    url.searchParams.append('quality', quality)
    return `${url.pathname}${url.search}`
  }
}

class CloudflareExtension extends ImageExtension {
  private parsePath(path: string): { options: string[]; url: URL } {
    const url = createUrl(path)
    if (!url) {
      return { options: [], url }
    }
    const groups = url.pathname.match(/^\/?cdn-cgi\/image\/([a-z=0-9,]+)(\/.+)/)
    const [, options, pathname] = groups ?? []
    return {
      options: options?.split(',') ?? [],
      url: createUrl(pathname ?? path)
    }
  }

  private unique(array: string[]): string[] {
    return array.filter((value, index, self) => self.indexOf(value) === index)
  }

  private getPathname(url: URL, options: string[]): string {
    url.pathname = `cdn-cgi/image/${options.join(',')}${url.pathname}`
    return `${url.pathname}${url.search}`
  }

  resize({ path, size, width, height }: Path & Size): string {
    const { options, url } = this.parsePath(path)
    if (!url) {
      return path
    }
    options.push('format=auto')

    url.pathname = `cache2/${width ?? size?.width}x${height ?? size?.height}${
      url.pathname
    }`

    return this.getPathname(url, this.unique(options))
  }

  applyQuality({ path, quality }: Path & Quality): string {
    if (!quality) {
      return path
    }
    const { options, url } = this.parsePath(path)
    if (!url) {
      return path
    }
    options.push('format=auto')

    let value: number = null
    switch (quality) {
      case ImageQuality.Low:
        value = 60
        break
      case ImageQuality.Medium:
        value = 70
        break
      case ImageQuality.MediumHigh:
        value = 80
        break
      case ImageQuality.High:
        value = 90
        break
    }

    if (value) {
      options.push(`quality=${value}`)
    }

    return this.getPathname(url, this.unique(options))
  }
}

class LocalExtension extends ImageExtension {
  resize({ path, width, height, size }: Path & Size): string {
    if ((!width || !height) && !size) {
      return path
    }
    const url = createUrl(path)
    if (!url) {
      return path
    }
    url.pathname = `cache2/${width ?? size?.width}x${height ?? size?.height}${
      url.pathname
    }`
    return `${url.pathname}${url.search}`
  }

  applyQuality({ path }: Path & Quality): string {
    return path
  }
}

export const applyImagePolicy = (
  path: string,
  {
    mode = ImageMode.Local,
    useCdn = true,
    quality = null,
    enhanced = false,
    ...props
  }: Size & Quality & Mode & Cdn & Enhanced = {}
): string => {
  switch (mode) {
    case ImageMode.Akamai:
      return new AkamaiExtension().getPath({
        ...props,
        ...{ path, useCdn, quality, enhanced }
      })
    case ImageMode.Local:
      return new LocalExtension().getPath({
        ...props,
        ...{ path, useCdn, quality, enhanced }
      })
    case ImageMode.Cloudflare:
      return new CloudflareExtension().getPath({
        ...props,
        ...{ path, useCdn, quality, enhanced }
      })
    default:
      return path
  }
}
//*1400x1400, 800x1200, 800x800, 100x150, 100x100 images *//
// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
export const getImageMode = (subdomain: string): ImageMode => ImageMode.Local
