import { FC } from 'react'
import Image from 'next/image'
import { Asset, useAsset } from '@/providers/assetsProvider'

export const SadDogSmileFace: FC<Rest> = ({ ...rest }) => (
  <DogSmileFace type={DogSmileFaces.Sad} {...rest} />
)

export const HappyDogSmileFace: FC<Rest> = ({ ...rest }) => (
  <DogSmileFace type={DogSmileFaces.Happy} {...rest} />
)

enum DogSmileFaces {
  Happy,
  Sad
}

type DogSmileFaceProps = {
  type: DogSmileFaces
  small?: boolean
}

const DogSmileFace: FC<DogSmileFaceProps> = ({ type, small = false }) => {
  const { asset } = useAsset()
  const ratio = 384 / 329
  const height = small ? 140 : 220
  const width = height * ratio
  return (
    <>
      <Image
        src={asset(
          type === DogSmileFaces.Happy ? Asset.HappyDog : Asset.SadDog,
          false
        )}
        width={width}
        height={height}
      />
    </>
  )
}
