import { FC, createContext, useContext, useEffect, useState } from 'react'
import { useCookie } from 'next-cookie'
import { useUser } from '@/providers/userProvider'
import objectHash from '@/core/crypto/objectHash'
import { Product } from '@/network/graphql.g'

const WishlistContext = createContext<WishlistContextType>(null)

type WishlistContextType = {
  wishlist: Product[]
  setWishlist: (value: Product[]) => void
  isInWishlist: (product: Product) => boolean
  getWishlistChecksum: () => string
  createWishlistChecksum: () => string
  wishlistProductCount: number
  setWishlistProductCount: (value: number) => void
}

export const useWishlist = (): WishlistContextType =>
  useContext(WishlistContext)

const ChecksumCookieName = 'wishlist-checksum'
const ChecksumCookieOptions = {
  maxAge: 24 * 60 * 60, // 1 day
  path: '/'
}

const WishlistProvider: FC<Rest> = ({ children }) => {
  const [wishlist, setWishlist] = useState<Product[]>([])
  const [wishlistProductCount, setWishlistProductCount] = useState<number>(null)
  const { isLoggedIn } = useUser()

  useEffect(() => {
    if (wishlist != null) {
      const cookie = useCookie()
      cookie.set(
        ChecksumCookieName,
        createWishlistChecksum(),
        ChecksumCookieOptions
      )
    }
  }, [wishlist])

  useEffect(() => {
    setWishlistProductCount(wishlistProductCount)
  }, [wishlistProductCount])

  const getWishlistChecksum = () => {
    const cookie = useCookie()
    return cookie.get<string>(ChecksumCookieName)
  }

  const createWishlistChecksum = (): string => {
    return objectHash({
      wishlist: wishlist ?? null,
      isLoggedIn
    })
  }

  const isInWishlist = (product: Product): boolean =>
    !!wishlist?.find((item) => item?.id === product?.id)

  return (
    <WishlistContext.Provider
      value={{
        wishlist,
        setWishlist,
        isInWishlist,
        getWishlistChecksum,
        createWishlistChecksum,
        wishlistProductCount: wishlist?.length ?? 0,
        setWishlistProductCount
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

export default WishlistProvider
