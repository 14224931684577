import { FC } from 'react'

const SocksFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="socks-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M153.25,67.19V4h-37.21V57.72c-1.84,1.46-3.58,3.14-5.12,5.09l-26.18,32.97c-9.34,11.76-8.79,27.89,1.23,35.85,10.02,7.96,25.86,4.85,35.2-6.91l26.18-32.97c5.95-7.49,7.87-16.75,5.9-24.55Z" />
        <path d="M133.12,75.88c3.03-7.1,11.83-10.25,20.36-7.65-.07-.35-.14-.7-.23-1.04V4h-37.21V57.72c-1.84,1.46-3.58,3.14-5.12,5.09l-26.18,32.97c-9.34,11.76-8.79,27.89,1.23,35.85,10.02,7.96,25.86,4.85,35.2-6.91l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84Z" />
        <path d="M153.48,68.23c-.07-.35-.14-.7-.23-1.04V4h-37.21V57.72c-1.84,1.46-3.58,3.14-5.12,5.09l-26.18,32.97c-1.17,1.48-2.19,3.02-3.05,4.62,.45-.02,.89-.03,1.35-.03,17.06,0,31.19,12.56,33.64,28.93,1.61-1.34,3.13-2.86,4.49-4.58l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84,3.03-7.1,11.83-10.25,20.36-7.65Z" />
        <line x1="125.54" y1="4" x2="125.54" y2="17.69" />
        <line x1="135.02" y1="4" x2="135.02" y2="17.69" />
        <line x1="144.5" y1="4" x2="144.5" y2="17.69" />
        <path d="M79.17,67.19V4H41.97V57.72c-1.84,1.46-3.58,3.14-5.12,5.09L10.66,95.77c-9.34,11.76-8.79,27.89,1.23,35.85,10.02,7.96,25.86,4.85,35.2-6.91l26.18-32.97c5.95-7.49,7.87-16.75,5.9-24.55Z" />
        <path d="M59.04,75.88c3.03-7.1,11.83-10.25,20.36-7.65-.07-.35-.14-.7-.23-1.04V4H41.97V57.72c-1.84,1.46-3.58,3.14-5.12,5.09L10.66,95.77c-9.34,11.76-8.79,27.89,1.23,35.85,10.02,7.96,25.86,4.85,35.2-6.91l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84Z" />
        <path d="M79.4,68.23c-.07-.35-.14-.7-.23-1.04V4H41.97V57.72c-1.84,1.46-3.58,3.14-5.12,5.09L10.66,95.77c-1.17,1.48-2.19,3.02-3.05,4.62,.45-.02,.89-.03,1.35-.03,17.06,0,31.19,12.56,33.64,28.93,1.61-1.34,3.13-2.86,4.49-4.58l22.24-27.99c-8.92-3.99-13.52-13.24-10.29-20.84,3.03-7.1,11.83-10.25,20.36-7.65Z" />
        <line x1="51.46" y1="4" x2="51.46" y2="17.69" />
        <line x1="60.94" y1="4" x2="60.94" y2="17.69" />
        <line x1="70.42" y1="4" x2="70.42" y2="17.69" />
      </g>
    </symbol>
  </svg>
)

export default SocksFrogiesDefinition
