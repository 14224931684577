import { FC } from 'react'

const SetsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sets-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect
          x="81.87"
          y="35.63"
          width="11.01"
          height="47.52"
          transform="translate(10.15 131.49) rotate(-77.24)"
        />
        <path d="M64.01,143.54l14.47-44.8-6.25,46.67,29.12,6.59,12.85-56.74c2.04-9.01,.04-18.02-4.83-25.25l-46.37-10.5c-7.51,4.43-13.2,11.7-15.24,20.71l-12.85,56.74,29.12,6.59Z" />
        <path d="M79.43,51.91h-.01c.57-2.79,.49-5.75-.36-8.67-2.8-9.69-12.99-15.25-22.74-12.43-9.76,2.82-15.39,12.97-12.59,22.66-2.8-9.69-12.99-15.25-22.74-12.43-9.76,2.82-15.39,12.97-12.59,22.66,.86,2.99,2.43,5.58,4.47,7.66l-.03,.02c7.47,10.7,11.9,23.16,15.98,35.44l13.8-3.99" />
        <line x1="69.21" y1="31.82" x2="60.58" y2="2" />
        <line x1="10.63" y1="48.77" x2="2" y2="18.95" />
      </g>
    </symbol>
  </svg>
)

export default SetsDefinition
