import { Box, Flex } from '@/components/atoms/Grid'
import styled, { css } from 'styled-components'
import { FC } from 'react'

export type ProductCardColors = {
  background?: string
  imagePlaceholder?: string
  textPlaceholder?: string
}

export const DefaultProductCardColors: ProductCardColors = {
  background: '#fff',
  imagePlaceholder: '#f5f5f5',
  textPlaceholder: '#f5f5f5'
}

type ProductCardPlaceholderProps = {
  colors?: ProductCardColors
}

const ProductCardPlaceholder: FC<ProductCardPlaceholderProps> = ({
  colors: initialColors
}) => {
  const colors: ProductCardColors = {
    ...DefaultProductCardColors,
    ...(initialColors ?? {})
  }
  return (
    <>
      <PlaceholderContainer colors={colors}>
        <PlaceholderItem />
      </PlaceholderContainer>
      <StyledFlex
        flexDirection="column"
        alignItems={{
          mobile: 'center',
          tablet: 'flex-start',
          desktop: 'flex-start'
        }}
        colors={colors}
      >
        <PlaceholderText
          mt={{ desktop: 20, tablet: 20, mobile: 11 }}
          height={10}
          width="90%"
          colors={colors}
        />
        <PlaceholderText
          mt={13}
          mb={{ desktop: '4px', tablet: '4px', mobile: '5px' }}
          height={13}
          width="75%"
          colors={colors}
        />
      </StyledFlex>
    </>
  )
}

export default ProductCardPlaceholder

export const PlaceholderColor = css`
  background-color: #f5f5f5;
`

const PlaceholderContainer = styled(Box)`
  background-color: ${(props) => props.colors.imagePlaceholder};
  padding-top: 150%;
  position: relative;
`

const PlaceholderItem = styled(Box)`
  position: absolute;
`

export const PlaceholderText = styled(Box)`
  background-color: ${(props) => props.colors.textPlaceholder};
`

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.colors.background};
`
