import React, { useEffect } from 'react'

type GlobalStyleProps = {
  accent?: string
  onBackground?: string
}

const GlobalStyleCSS: React.FC<GlobalStyleProps> = ({
  accent,
  onBackground
}) => {
  useEffect(() => {
    document.documentElement.style.setProperty('--color-accent', accent)
    document.documentElement.style.setProperty(
      '--color-onBackground',
      onBackground
    )
  }, [accent, onBackground])

  return null
}

export default GlobalStyleCSS
