import { FC } from 'react'

type ForwardedErrorProps = {
  errorText: string
}

const ForwardedError: FC<ForwardedErrorProps> = ({ errorText }) => (
  <>
    <small>Forwarded error</small>
    <div
      dangerouslySetInnerHTML={{
        __html: errorText
      }}
    />
  </>
)

export default ForwardedError
