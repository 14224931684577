import { useMediaQuery } from 'react-responsive'
import { breakpoints } from 'theme/breakpoints'

const useDevice = (): {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
} => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop.min })
  const isTablet = useMediaQuery({
    minWidth: breakpoints.tablet.min,
    maxWidth: breakpoints.tablet.max
  })
  const isMobile = useMediaQuery({
    minWidth: breakpoints.mobile.min,
    maxWidth: breakpoints.mobile.max
  })

  return {
    isDesktop,
    isTablet,
    isMobile
  }
}

export default useDevice
