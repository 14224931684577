import { FC } from 'react'

const SwimsuitsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="swimsuits-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.92,62.88c-1.55-2.87-2.43-6.16-2.43-9.65,0-6.36,2.93-12.05,7.53-15.81,1.04-2.36,1.89-5.25,2.49-9.1,1.67-10.84,.96-17.78-1.5-24.36l15.12-1.96v30.99c10.02,1.42,17.73,9.93,17.73,20.25,0-10.33,7.74-18.86,17.79-20.26l-.06-.17V2l15.12,1.96c-2.46,6.58-3.17,13.52-1.5,24.36,.6,3.85,1.44,6.74,2.49,9.1,4.6,3.76,7.53,9.44,7.53,15.81,0,3.41-.84,6.62-2.32,9.44,0,.26-77.98,.46-77.98,.2Z" />
        <path d="M97.71,127.17c0-8.65-3.28-16.75-8.02-23.72H10.03c-4.75,6.97-8.03,15.07-8.03,23.72H22.85c11.3,0,20.46,9.16,20.46,20.46v4.36h13.09v-4.36c0-11.3,9.16-20.46,20.46-20.46h20.85Z" />
      </g>
    </symbol>
  </svg>
)

export default SwimsuitsDefinition
