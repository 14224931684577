import { FC } from 'react'

const AnkleshoesWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="ankleshoes-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M135.91,129.88c-5.56-1.2-25.79-1.6-32.13-9.22-6.34-7.63-26.58-42.95-28.96-58.21-.92-5.91-.95-31.42-.64-60.45H4.75c1.51,9.53,2.96,17.47,4.2,22.33,5.95,23.28,1.19,45.76,0,50.97-1.19,5.21-7.78,17.66-6.87,24.48,.57,4.23,1.81,7.07,4.14,9.19l22.96,9.15c4.96,1.88,10.32,4.25,15.47,7.36,12.7,7.62,22.96,23.65,34.12,25.29,12.5,1.83,24.66,1.48,33.32,0,8.67-1.48,30.55-8.84,30.55-11.64s-1.19-8.03-6.75-9.24Z" />
        <path d="M29.19,118.11c-7.24-2.75-13.63-4.46-17.31-5.89-2.36-.92-4.21-1.96-5.65-3.26h0" />
        <path d="M29.19,118.11v33.84H9.36l-3.13-42.99c1.44,1.3,3.3,2.35,5.65,3.26,3.68,1.43,10.07,3.14,17.31,5.89Z" />
      </g>
    </symbol>
  </svg>
)

export default AnkleshoesWomenDefinition
