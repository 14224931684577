import { FC } from 'react'

const TshirtsMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="tshirts-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M144.39,67.47L123.31,13.83h.03L97.75,2H48.68L27.99,11.56c-3.14,1.45-5.63,4.14-6.96,7.5L2,67.47l21.45,12.56,11.41-22.26v94.23H111.57V57.85l11.37,22.19,21.45-12.56Z" />
        <path d="M49.33,2c0,14.38,10.68,26.04,23.86,26.04s23.86-11.66,23.86-26.04" />
      </g>
    </symbol>
  </svg>
)

export default TshirtsMenDefinition
