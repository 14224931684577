const workaround = require('next-translate/lib/cjs/plugin/utils.js')

// As a workaround you can change the string of the defaultLoader, this is working fine
workaround.defaultLoader =
  '(l, n) => import(`@next-translate-root/locales/${l.replace(/-[a-z]{2}XxX[a-z]{2}$/, "")}/${n}.json`).then(m => m.default)'

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
const subdomainToLocale = (subdomain) => {
  switch (subdomain) {
    case 'sk':
    case 'es':
    case 'pl':
    case 'ee':
    case 'cs':
    case 'lt':
    case 'lv':
    case 'ro':
    case 'hr':
    case 'it':
    case 'bg':
    case 'ua':
    case 'hu':
    case 'de':
      return subdomain
    case 'gr':
      return 'el'
    case 'si':
      return 'sl'
    case 'rs':
      return 'sr'
    case 'ba':
      return 'bs'
    case 'at':
      return 'de'
    default:
      return 'en'
  }
}

const tlds = ['local', 'com']
const domains = ['factcool', 'aliatic']
const subdomains = [
  'sk',
  'cs',
  'en',
  'fi',
  'es',
  'pl',
  'ee',
  'lt',
  'lv',
  'ro',
  'hr',
  'gr',
  'si',
  'it',
  'bg',
  'rs',
  'ba',
  'ua',
  'hu',
  'be',
  'dk',
  'de',
  'fr',
  'nl',
  'at',
  'pt',
  'se'
]

const domainConfig = [
  ...subdomains.flatMap((subdomain) =>
    domains.flatMap((domain) =>
      tlds.flatMap((tld) => ({
        domain: `${subdomain}.${domain}.${tld}`,
        defaultLocale: `${subdomainToLocale(subdomain)}-${subdomain}XxX${
          domain[0]
        }${tld[0]}`
      }))
    )
  ),
  ...[
    {
      domain: 'www.bezvasport.cz',
      defaultLocale: 'cs-csXxXbe'
    },
    {
      domain: 'www.bezvasport.sk',
      defaultLocale: 'sk-skXxXbe'
    },
    {
      domain: 'www.filatic.ro',
      defaultLocale: 'ro-roXxXfi'
    },
    {
      domain: 'www.frogies.sk',
      defaultLocale: 'sk-skXxXfr'
    },
    {
      domain: 'www.frogies.cz',
      defaultLocale: 'cs-csXxXfr'
    },
    {
      domain: 'www.frogies.ro',
      defaultLocale: 'ro-roXxXfr'
    },
    {
      domain: 'www.frogies.gr',
      defaultLocale: 'el-grXxXfr'
    },
    {
      domain: 'www.frogies.bg',
      defaultLocale: 'bg-bgXxXfr'
    },
    {
      domain: 'www.frogies.rs',
      defaultLocale: 'sr-rsXxXfr'
    },
    {
      domain: 'www.frogies.ba',
      defaultLocale: 'bs-baXxXfr'
    },
    {
      domain: 'www.frogies.hu',
      defaultLocale: 'hu-huXxXfr'
    },
    {
      domain: 'www.frogies.hr',
      defaultLocale: 'hr-hrXxXfr'
    },
    {
      domain: 'www.frogies.ee',
      defaultLocale: 'ee-eeXxXfr'
    },
    {
      domain: 'www.frogies.lt',
      defaultLocale: 'lt-ltXxXfr'
    },
    {
      domain: 'www.frogies.lv',
      defaultLocale: 'lv-lvXxXfr'
    },
    {
      domain: 'www.frogies.pl',
      defaultLocale: 'pl-plXxXfr'
    },
    {
      domain: 'www.frogies.si',
      defaultLocale: 'sl-siXxXfr'
    },
    {
      domain: 'www.frogies.es',
      defaultLocale: 'es-esXxXfr'
    },
    {
      domain: 'www.frogies.it',
      defaultLocale: 'it-itXxXfr'
    },
    {
      domain: 'www.frogies.ua',
      defaultLocale: 'ua-uaXxXfr'
    },
    {
      domain: 'www.frogies.de',
      defaultLocale: 'de-deXxXfr'
    },
    {
      domain: 'www.frogies.at',
      defaultLocale: 'de-atXxXfr'
    },

    // stage
    {
      domain: 'sk.factcool.dev',
      defaultLocale: 'sk-skXxXfd'
    },
    {
      domain: 'it.factcool.dev',
      defaultLocale: 'it-itXxXfd'
    },
    {
      domain: 'fi.factcool.dev',
      defaultLocale: 'en-fiXxXfd'
    },
    {
      domain: 'hr.factcool.dev',
      defaultLocale: 'hr-hrXxXfd'
    }
  ]
]

module.exports = {
  localeDetection: false,
  locales: domainConfig.map(({ defaultLocale }) => defaultLocale),
  defaultLocale: 'sk-skXxXfc',
  domains: domainConfig,
  pages: {
    '*': ['common'],
    '/information/impressum': ['informationImpressum'],
    '/information/shop': ['informationShop'],
    '/account/orders/return/add/[orderId]': ['returnExtended'],
    '/account/orders/return/add/[orderId]/[email]': ['returnExtended']
  },
  logBuild: false
}
