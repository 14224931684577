import { FC, useContext } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import { Small3, Tiny2 } from '@/components/atoms/typography'
import { CloseIconButton } from '@/components/atoms/button'
import { makePriceString } from '@/core/toStringHelper'
import { SectionSeparator } from '@/components/atoms/separator'
import ProductImage from '@/components/molecules/productImage'
import styled from 'styled-components'
import Link from '@/components/atoms/link'
import { Routes } from '@/core/routes'
import { transparentize } from 'polished'
import { CurrencyContext } from '@/providers/currencyProvider'
import Trans from 'next-translate/Trans'
import { CartProduct } from '@/network/graphql.g'
import { useOrderDispatch } from '@/providers/order/old/orderDispatchProvider'

type CartDropDownProductItemProps = {
  product: CartProduct
  showSeparator?: boolean
} & Rest

const CartDropDownProductItem: FC<CartDropDownProductItemProps> = ({
  product,
  showSeparator = true,
  ...rest
}) => {
  const currency = useContext(CurrencyContext)
  const { removeFromCart } = useOrderDispatch()
  const {
    product: { id, mainImage, name, prices },
    size,
    count
  } = product

  return (
    <Link href={`${Routes.product}/${id}`}>
      <StyledBox px={20} pt={10} {...rest}>
        <Flex width="100%" alignItems="center">
          <ProductImage dimension={61} imageUrl={mainImage?.url} />
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            flexGrow="1"
          >
            <Small3 m="3px">{name}</Small3>
            <Tiny2 m="3px" display="inline-block">
              <Trans
                i18nKey="common:CartModal.itemCounts"
                values={{ size: size?.value, count }}
              />
            </Tiny2>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" py={10}>
          <CloseIconButton
            onClick={(e) => {
              e.preventDefault()
              removeFromCart(product.product, size)
            }}
          />
          <Small3 data-cy="price" m="3px">
            {makePriceString({
              price: prices.finalPrice,
              currency: currency
            })}
          </Small3>
        </Flex>
        {showSeparator && <SectionSeparator />}
      </StyledBox>
    </Link>
  )
}

export default CartDropDownProductItem

const StyledBox = styled(Box)`
  width: 100%;
  &:hover {
    background-color: ${(props) =>
      transparentize(0.95, props.theme.colors.onBackground)};
  }
`
