import { FC } from 'react'

const ShortsMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="shorts-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M70.89,48.55c3.76,0,6.8-4.01,6.8-8.95V17.09" />
        <polygon points="57.37 136.01 75.91 61.09 94.44 136.01 141.53 129.96 114.38 16.5 37.43 16.5 10.45 129.98 57.37 136.01" />
        <rect x="37.43" y="2" width="76.88" height="14.5" />
        <path d="M50.89,16.49c3.91,6.03,3.5,14.55-1.6,21.11-4.62,5.94-11.82,8.65-18.29,7.51" />
        <path d="M100.86,16.49c-3.91,6.03-3.5,14.55,1.6,21.11,4.62,5.94,11.82,8.65,18.29,7.51" />
        <line x1="53.5" y1="2" x2="53.5" y2="16.49" />
        <line x1="98.24" y1="2" x2="98.24" y2="16.49" />
        <polygon points="56.74 152 2 144.97 6.21 129.44 60.95 136.47 56.74 152" />
        <polygon points="95.07 152 149.81 144.97 145.61 129.44 90.86 136.47 95.07 152" />
      </g>
    </symbol>
  </svg>
)

export default ShortsMenDefinition
