import { ITheme } from './interfaces/ITheme'

type BreakpointsValueType = { min: number; max?: number }

export enum BreakpointKey {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
  desktopWide = 'desktopWide',
  wide = 'wide',
  ultraWide = 'ultraWide'
}

export const breakpoints: { [key in BreakpointKey]: BreakpointsValueType } = {
  mobile: { min: 0, max: 767 },
  tablet: { min: 768, max: 1199 },
  desktop: { min: 1200 },
  desktopWide: { min: 1500 },
  wide: { min: 1700 },
  ultraWide: { min: 2500 }
}

type BreakpointTheme = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  breakpoints: any[]
}

export const addBreakpointsToTheme = (
  theme: ITheme
): ITheme & BreakpointTheme => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const styledSystemBreakpoints: any = [
    breakpoints.tablet.min + 'px',
    breakpoints.desktop.min + 'px',
    breakpoints.wide.min + 'px',
    breakpoints.ultraWide.min + 'px'
  ]

  // Type aliases for styled-system
  styledSystemBreakpoints.tablet = styledSystemBreakpoints[0]
  styledSystemBreakpoints.desktop = styledSystemBreakpoints[1]
  styledSystemBreakpoints.wide = styledSystemBreakpoints[2]
  styledSystemBreakpoints.ultraWide = styledSystemBreakpoints[3]

  return { ...theme, breakpoints: styledSystemBreakpoints }
}

export const getMedia = (key: BreakpointKey): string => {
  switch (key) {
    case BreakpointKey.mobile:
      return `(max-width: ${breakpoints.mobile.max}px)`
    case BreakpointKey.tablet:
      return `(min-width: ${breakpoints.tablet.min}px) and (max-width: ${breakpoints.tablet.max}px)`
    default:
      return `(min-width: ${breakpoints.desktop.min}px)`
  }
}
