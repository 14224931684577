import { FC } from 'react'

const SandalsMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sandals-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M105.08,50.26c1.61,.03,3.66-.07,6-.26l-.7,8.82c-8.51,.57-22.44,1.03-28.76,1.03-8.07,0-27.84-1.32-40.7-1.57-9.21-.18-29.26-2.42-35.51-4.56-2.31-.8-3.73-3.14-3.35-5.55l1.1-6.98c.29-1.84,1.97-3.14,3.82-2.95,6.48,.65,20.82,2.05,27.73,2.43,1.35,.07,3.6,.33,6.49,.72l-3.46,7.7c2.52,.6,28.25,2.18,28.25,2.18l2.49-5.77c15.09,2.33,30.63,4.63,36.6,4.76Z" />
        <path d="M152,43.45v3.47c0,1.97-1.34,3.66-3.25,4.13-5.03,1.2-14.93,3.54-22.73,5.27l.44-8.3c12.74-1.98,25.54-4.56,25.54-4.56Z" />
        <path d="M127.15,34.98l-.69,13.04-.44,8.3c-4.55,1-8.37,1.8-10.09,2.02-1.25,.16-3.19,.33-5.54,.48l.7-8.82,1.34-16.84c.12-1.52,1.5-2.62,3.02-2.39l9.49,1.47c1.33,.21,2.28,1.39,2.21,2.73Z" />
        <path d="M81.83,14.54l-13.35,30.96-2.49,5.77s-25.73-1.58-28.25-2.18l3.46-7.7L57.87,4.31c.87-1.94,3.12-2.83,5.07-2l16.82,7.08c2,.84,2.93,3.15,2.07,5.15Z" />
        <polyline points="50.58 20.51 8.69 11.21 7.4 18.71 47.04 28.4" />
      </g>
    </symbol>
  </svg>
)

export default SandalsMenDefinition
