import { FC } from 'react'
import styled from 'styled-components'
import { space, justifyContent, opacity, zIndex } from 'styled-system'
import { SvgProps } from './svg/svgProps'

const InlineWrapper = styled.span`
  display: block;
  ${(props) =>
    props.cursorOnHover &&
    `
  &:hover {
    cursor: pointer;
  }
  `}
  ${space};
  ${opacity};
  ${zIndex};
`

const IconWrapper = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${justifyContent};
`

export type SingleIconProps = {
  width?: number | string
  height?: number | string
  size?: number | string
  color?: string
  direction?: string
  justifyContent?: string
  cursorOnHover?: boolean
  secondarycolor?: string
} & Rest

export const createIcon =
  (Component: FC<SvgProps>): ((SingleIconProps) => JSX.Element) =>
  ({
    width,
    height,
    size,
    color,
    opacity,
    justifyContent,
    secondarycolor,
    ...rest
  }) =>
    (
      <InlineWrapper {...rest}>
        <IconWrapper justifyContent={justifyContent}>
          <Component
            width={width || size}
            height={height || size}
            color={color}
            opacity={opacity}
            secondarycolor={secondarycolor}
          />
        </IconWrapper>
      </InlineWrapper>
    )
