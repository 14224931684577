import { FC } from 'react'
import { SvgProps } from './svgProps'

const HomeIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 17}
    height={height || 19}
    viewBox="0 0 17 19"
  >
    <g fill="none" fillRule="evenodd">
      <g stroke={color || '#000'} strokeWidth="1.5">
        <g>
          <path
            d="M8.5 1.472c.304 0 .609.11.848.332h0l6.5 6c.256.237.402.57.402.92h0v8.124c0 .345-.14.657-.366.884-.226.226-.539.366-.884.366h0H2c-.345 0-.658-.14-.884-.366-.226-.227-.366-.54-.366-.884h0V8.723c0-.349.146-.682.402-.918h0l6.5-6.001c.24-.221.544-.332.848-.332z"
            transform="translate(-311 -54) translate(311 54.152)"
          />
          <path
            d="M10 10.598c.345 0 .658.14.884.366.226.226.366.539.366.884h0v6.25h-5.5v-6.25c0-.345.14-.658.366-.884.226-.226.539-.366.884-.366h0z"
            transform="translate(-311 -54) translate(311 54.152)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default HomeIcon
