import { FC } from 'react'

const FootballbootsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="footballboots-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.42,20.21s.01-.04,.01-.04c1.49-4.02,4.95-7.27,4.95-7.27,0,0,5.02-5.99,10.18-3.69,5.8,2.59,8.92,4.77,16.29,5.67,6.51,.79,13.9,.32,20.75-2.91,6.85-3.23,10.99-10.86,15.54-9.89,2.63,.56,5.41,1.71,8.21,3.15,7.23,3.73,39.83,22.6,53.93,27.77,1.41,.52,15.82,3.75,16.38,4.77,.67,1.23,.28,2.3-.89,3.25-6.89,5.6-41.06,6.6-45.31,6.58-13.65-.05-59.03,.27-68.23,.44-10,.18-24.17,.56-33.27-2.61-.32-.11-.59-.31-.82-.56-.63-.69-.94-1.87-1.05-3.29-.46-5.52,.76-11.91,1.85-16.26,.37-1.81,.88-3.52,1.47-5.1Z" />
        <path d="M151.91,39.52s.04,6.72-1.13,7.67c-6.89,5.6-41.06,6.6-45.31,6.58-13.65-.05-59.03,.27-68.23,.44-10,.18-24.17,.56-33.27-2.61-.32-.11-.59-.31-.82-.56-.63-.69-1.04-5.82-1.15-7.24" />
        <line x1="103.07" y1="25.45" x2="109.72" y2="20.71" />
        <line x1="94.19" y1="20.71" x2="100.84" y2="15.97" />
        <line x1="85.63" y1="15.97" x2="92.28" y2="11.23" />
        <polyline points="11.88 54.15 11.44 60.37 19.59 61.1 20.33 54.76" />
        <polyline points="39.27 54.7 39.63 60.91 47.84 61 47.75 54.63" />
        <polyline points="102.56 54.79 103.16 61 111.37 60.89 111.04 54.53" />
        <polyline points="133.2 53.91 135.56 59.85 143.52 58.3 141.38 52.15" />
      </g>
    </symbol>
  </svg>
)

export default FootballbootsDefinition
