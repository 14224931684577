import { FC } from 'react'

const PantsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="pants-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="49.68 152 62.88 54.4 76.09 152 123.85 144.23 96.62 14.86 29.14 14.86 2 144.23 49.68 152" />
        <rect x="29.14" y="2" width="67.41" height="12.86" />
        <path d="M40.44,15.63c2.73,4.21,2.44,10.15-1.12,14.73-3.23,4.15-8.25,6.03-12.77,5.24" />
        <path d="M85.42,15.63c-2.73,4.21-2.44,10.15,1.12,14.73,3.23,4.15,8.25,6.03,12.77,5.24" />
      </g>
    </symbol>
  </svg>
)

export default PantsWomenDefinition
