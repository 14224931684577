import { FC } from 'react'

const TshirtsMenFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="tshirtsmen-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M146.39,69.47L125.31,15.83h.03l-25.59-11.83H50.68L29.99,13.56c-3.14,1.45-5.63,4.14-6.96,7.5L4,69.47l21.45,12.56,11.41-22.26v94.23H113.57V59.85l11.37,22.19,21.45-12.56Z" />
        <path d="M51.33,4c0,14.38,10.68,26.04,23.86,26.04s23.86-11.66,23.86-26.04" />
      </g>
    </symbol>
  </svg>
)

export default TshirtsMenFrogiesDefinition
