import { FC } from 'react'

const SweatersWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sweaters-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M41.79,2c-5.06,1.95-18.93,7.33-20.46,8.07-1.87,.91-3.9,4.38-4.51,7.72-.52,2.82-14.69,75.53-14.69,75.53-.76,4.15,1.89,8.48,5.88,9.63l1.05,.3c3.99,1.15,7.9-1.3,8.67-5.45l7.89-38.17c.78-4.14,1.73-8.45,2.12-9.58l.45,.18s3.43,4.44,3.04,21.25l-10,60.42,28.47,20.1V30.71s0,0,0,0c-1.22-2.27-2.28-4.54-3.06-6.66-3.25-8.97-4.96-17.11-4.86-22.05h33c.1,4.95-1.61,13.09-4.86,22.05-.77,2.12-1.83,4.39-3.06,6.66h0s0,121.29,0,121.29l28.52-20.1-10.06-60.42c-.4-16.93,3.11-21.42,3.11-21.42l.06,.02c.18-1.18,1.73,5.39,2.51,9.54l7.89,38.17c.78,4.14,4.68,6.59,8.68,5.45l1.05-.3c3.99-1.15,6.64-5.48,5.88-9.63l-14.42-74.04c-.76-4.15-2.91-8.29-4.78-9.2-1.54-.75-15.52-6.16-20.53-8.07" />
        <line x1="49.7" y1="131.9" x2="66.88" y2="131.9" />
      </g>
    </symbol>
  </svg>
)

export default SweatersWomenDefinition
