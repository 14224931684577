import { Product } from '@/network/graphql.g'
import { ImageMode } from '@/utils/imageExtension'
import { getMhubProductData, MhubProductData } from './productManager'

type MhubWishlistData = {
  products: MhubWishlistProductData[]
}

type MhubWishlistProductData = {
  list: string
  position: number
} & MhubProductData

export type MhubWishlist = {
  wishlist: MhubWishlistData
}

type MhubWishlistEvent = {
  event: string
  action: {
    wishlist?: {
      products: MhubProductData[]
    }
    wishlistRemove?: {
      products: MhubProductData[]
    }
  }
}

export const getEmptyMhubWishlist = (imageMode: ImageMode): MhubWishlist =>
  getMhubWishlist(null, imageMode)

export const getMhubWishlist = (
  wishlist: Product[],
  imageMode: ImageMode
): MhubWishlist => {
  return {
    wishlist:
      wishlist?.length > 0
        ? {
            products:
              wishlist?.map((product, index) => ({
                ...getMhubProductData(product, imageMode),
                ...{
                  list: 'wishlist.widget',
                  position: index + 1
                }
              })) ?? []
          }
        : null
  }
}

export const getMhubWishlistAddEvent = (
  product: Product,
  imageMode: ImageMode
): MhubWishlistEvent => ({
  event: 'action.wishlist.products',
  action: {
    wishlist: {
      products: [getMhubProductData(product, imageMode)]
    }
  }
})

export const getMhubWishlistRemoveEvent = (
  products: Product[],
  imageMode: ImageMode
): MhubWishlistEvent => ({
  event: 'action.wishlistRemove.products',
  action: {
    wishlistRemove: {
      products: products.map((product) =>
        getMhubProductData(product, imageMode)
      )
    }
  }
})
