import styled, { css } from 'styled-components'
import { Box } from './Grid'
import { width, height, display } from 'styled-system'

type SeparatorProps = {
  isVertical?: boolean
}

const verticalStyles = css`
  height: 100%;
  width: 1px;
`

const horizontalStyles = css`
  height: 1px;
  width: 100%;
`

const Separator = styled<SeparatorProps>(Box)`
  ${(props) => (props.isVertical === true ? verticalStyles : horizontalStyles)};
  ${width};
  ${height};
  ${display};
`

export const SectionSeparator = styled<SeparatorProps>(Separator)`
  background-color: ${(props) => props.theme.colors.sectionSeparator};
`

export const ListItemSeparator = styled<SeparatorProps>(Separator)`
  background-color: ${(props) => props.theme.colors.listItemSeparator};
`

export const OpaqueSeparator = styled<SeparatorProps>(Separator)`
  background-color: ${(props) => props.theme.colors.onBackground};
`

export const ToolbarSeparator = styled<SeparatorProps>(Separator)`
  background-color: ${(props) => props.theme.colors.toolbarSeparator};
`

export const AccentSeparator = styled<SeparatorProps>(Separator)`
  background-color: ${(props) => props.theme.colors.accent};
`
