import { FC } from 'react'

const AccessoriesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="accessories-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M66.82,83.51l-12.56-9.4m0,0c-13.49-10.02-20.57,19.04-29.08,29.94m0,0c-16.4,20.99-29.57-8.44-19.87-15.52,8.83-6.44,18.08-13.83,25.2-26.13,12.65-21.85,22.84-35.35,38.26-55.09,4.68-6,13.42-7.07,19.41-2.38l23.84,18.62m-26.97-7.37l20.5,16.01m-27.09-7.57l20.5,16.01m-27.09-7.57l20.5,16.01m-27.09-7.57l20.5,16.01m-27.09-7.57l20.5,16.01m-27.09-7.57l20.5,16.01" />
      </g>
    </symbol>
  </svg>
)

export default AccessoriesDefinition
