import { isGraphQlRequest } from '@/core/fetchData'

export class FetchError extends Error {
  private url: string
  private isGraphQl: boolean
  private isAuthorized = false
  private graphQl?: {
    operationName: string
    query: string
    variables: string
  }
  private headers: HeadersInit
  private events: { message: string; value: unknown }[]
  private status: number
  private isAbort = false
  private errorText: string
  private authorizationHeader: ''

  constructor(input: RequestInfo, init: RequestInit) {
    super()
    this.name = 'FetchError'
    this.url = input?.toString()
    this.isGraphQl = isGraphQlRequest(input)
    this.message = this.isGraphQl
      ? 'GraphQL error has occured'
      : `An error has occured while fetching ${this.url}`
    this.events = []
    this.headers = init?.headers ?? null

    if (this.isGraphQl) {
      try {
        const { query, operationName, variables } =
          JSON.parse(init?.body?.toString() ?? null) ?? {}
        this.graphQl = { query, operationName, variables }
        // eslint-disable-next-line no-empty
      } catch {}
    }

    if (
      this.headers?.['Authorization'] &&
      this.headers?.['Authorization'] !== ''
    ) {
      this.isAuthorized = true
      this.authorizationHeader = this.headers?.['Authorization']
      this.addEvent('AccessToken', this.headers?.['Authorization'].slice(-5))
    }
  }

  addEvent(message: string, value: unknown): void {
    this.events.push({
      message,
      value
    })
  }

  setAbortError(isAbort: boolean): void {
    this.isAbort = isAbort
  }

  isAbortError(): boolean {
    return this.isAbort
  }

  setStatus(status: number): void {
    this.status = status
  }

  getStatus(): number {
    return this.status
  }

  is401(): boolean {
    return this.status === 401
  }

  signOutOn401(): boolean {
    return this.isGraphQl && this.isAuthorized && !!this.getBaseUrl()
  }

  getBaseUrl(): string {
    return this.headers?.['BaseUrl']
  }

  setErrorText(errorText: string): void {
    this.errorText = errorText
  }

  getErrorText(): string {
    return this.errorText
  }

  getAuthorizationHeader(): string {
    return this.authorizationHeader
  }

  toJSON(): unknown {
    const alt: unknown = {}

    Object.getOwnPropertyNames(this).forEach(function (key) {
      alt[key] = this[key]
    }, this)

    return alt
  }

  getOperationName(): string {
    return (this.isGraphQl ? this.graphQl?.operationName : null) ?? ''
  }
}
