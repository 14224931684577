import { FC } from 'react'

const WalletsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="wallets-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M116.26,57.76h35.74v33.17h-35.74c-6.5,0-11.78-5.28-11.78-11.78v-9.62c0-6.5,5.28-11.78,11.78-11.78Z" />
        <path d="M118.5,19.96V11.12c0-5.04-4.09-9.12-9.12-9.12H10.99C6.02,2,2,6.02,2,10.99h0c0,4.96,4.02,8.99,8.99,8.99h3.89s126.56,0,126.56,0V57.09" />
        <polyline points="2 10.99 2 19.97 2 128.71 141.44 128.71 141.44 91.19" />
        <circle cx="117.95" cy="74.34" r="5.54" />
        <line x1="16.94" y1="115.27" x2="28.16" y2="115.27" />
        <line x1="66.11" y1="115.27" x2="77.33" y2="115.27" />
        <line x1="90.69" y1="115.27" x2="101.91" y2="115.27" />
        <line x1="41.52" y1="115.27" x2="52.75" y2="115.27" />
        <line x1="115.27" y1="115.27" x2="126.5" y2="115.27" />
        <line x1="16.94" y1="33.41" x2="28.16" y2="33.41" />
        <line x1="66.11" y1="33.41" x2="77.33" y2="33.41" />
        <line x1="90.69" y1="33.41" x2="101.91" y2="33.41" />
        <line x1="41.52" y1="33.41" x2="52.75" y2="33.41" />
        <line x1="115.27" y1="33.41" x2="126.5" y2="33.41" />
      </g>
    </symbol>
  </svg>
)

export default WalletsDefinition
