import { FC } from 'react'

const SunglassesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sunglasses-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M87.93,69.28c0-6.25-4.89-11.32-10.93-11.32s-10.93,5.07-10.93,11.32" />
        <path d="M31.39,13.71c0-6.47-5.74-11.71-12.82-11.71S5.76,7.24,5.76,13.71V56.27" />
        <path d="M122.61,13.71c0-6.47,5.74-11.71,12.82-11.71s12.82,5.24,12.82,11.71V56.31" />
        <path d="M66.33,64.34c0,13.28-22.01,30.8-35.68,30.8-18.98,0-28.65-13.47-28.65-26.75s10.97-22.7,29.95-22.7,34.37,5.37,34.37,18.66Z" />
        <path d="M87.67,64.34c0,13.28,22.01,30.8,35.68,30.8,18.98,0,28.65-13.47,28.65-26.75s-10.97-22.7-29.95-22.7-34.37,5.37-34.37,18.66Z" />
        <line x1="43.65" y1="44.38" x2="110.35" y2="44.38" />
        <line x1="39.25" y1="63.92" x2="16.7" y2="75.07" />
        <line x1="140" y1="63.92" x2="117.45" y2="75.07" />
      </g>
    </symbol>
  </svg>
)

export default SunglassesDefinition
