import { FC } from 'react'
import { Flex } from '@/components/atoms/Grid'
import { Small2, Small3 } from '@/components/atoms/typography'
import styled, { useTheme } from 'styled-components'

type CartSummaryServiceLabelProps = {
  title: string
  value: string
  dualPriceValue?: string
  highlightTitle?: boolean
  highlightValue?: boolean
  textColor?: string
} & Rest

const CartSummaryServiceLabelCartDropDown: FC<CartSummaryServiceLabelProps> = ({
  title,
  value,
  dualPriceValue,
  highlightTitle,
  highlightValue,
  textColor,
  ...rest
}) => {
  const theme = useTheme()
  const titleColor = textColor || theme.colors.onBackground
  const valueColor = highlightValue
    ? textColor || theme.colors.accent
    : theme.colors.onBackground
  return (
    <Flex alignItems="top" justifyContent="space-between" {...rest}>
      {highlightTitle ? (
        <Small3 color={titleColor} maxWidth="75%" m={0} py="5px">
          {title}
        </Small3>
      ) : (
        <Small2 maxWidth="75%" opacity="50%" m={0} py="5px">
          {title}
        </Small2>
      )}
      <Small3 color={valueColor} m={0} py="5px">
        <StyledDiv>{value}</StyledDiv>
        {dualPriceValue && <StyledDiv dualPrice>{dualPriceValue}</StyledDiv>}
      </Small3>
    </Flex>
  )
}

export default CartSummaryServiceLabelCartDropDown

const StyledDiv = styled.span`
  text-align: right;
  ${({ dualPrice }) => dualPrice && 'font-size: 12px;'}
  display: block;
`
