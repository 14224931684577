import { Delivery, Product, ProductColor } from '@/network/graphql.g'
import { useRef } from 'react'
import { debounce } from 'throttle-debounce'
import { getMhubProductData, MhubProductData } from './productManager'
import { ID } from 'graphql-ws'
import { getImageMode } from '@/utils/imageExtension'
import { useDomain } from '../storeProvider'

type MhubProductColor = {
  productBrand: string
  mainCategoryId: ID
} & ProductColor

type MhubProductSize = {
  variantId: string
  variant: string
  isStock: boolean
  available: number
  deliveryTime: Delivery
} & Product

export type MhubImpressionProduct = Product | MhubProductColor | MhubProductSize

type MhubImpressionProductData = {
  position?: number
  list?: string
  variantId?: string
  variant?: string
  isStock?: boolean
  available?: number
  deliveryTime?: {
    from: number
    to: number
  }
} & MhubProductData

type MhubImpression = {
  event?: string
  action: {
    impress: {
      products: MhubImpressionProductData[]
    }
  }
}

type MhubImpressionData = {
  id: number
  list?: string
  category: string
  categoryId: number
}

type MhubShowProductEvent = {
  event: string
  action: {
    show: {
      products: MhubImpressionProductData[]
    }
  }
}

export const useMhubImpression = (
  pushToMhub: (data: unknown) => void
): {
  addImpression: (
    product: MhubImpressionProduct,
    position: number,
    list: string
  ) => void
  getImpressionData: (
    product: MhubImpressionProduct,
    list: string
  ) => MhubImpressionData
  getShowProductEvent: (
    product: MhubImpressionProduct,
    list: string
  ) => MhubShowProductEvent
} => {
  const impressions = useRef<MhubImpressionProductData[]>([])

  const toProduct = (product: MhubImpressionProduct | null): Product | null => {
    if (product === null) {
      return null
    }
    if ('mainCategoryId' in product) {
      // mainCategoryId is property of MhubProductColor
      return {
        id: product?.id,
        productBrand: product?.productBrand,
        mainCategory: { id: product?.mainCategoryId },
        MHubProperties: product?.MHubProperties
      }
    } else if ('variantId' in product) {
      // variantId is property of MhubProductSize
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        variant: _variant,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        variantId: _variantId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isStock: _isStock,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        available: _available,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        deliveryTime: _deliveryTime,
        ...rest
      } = product
      return rest
    } else {
      return product
    }
  }

  const { subdomainName } = useDomain()

  const getMhubImpressionProductData = (
    product: MhubImpressionProduct,
    position: number,
    list: string
  ): MhubImpressionProductData => ({
    ...getMhubProductData(toProduct(product), getImageMode(subdomainName)),
    ...(position ? { position } : {}),
    ...(list ? { list } : {}),
    ...(product && 'variantId' in product
      ? { variantId: product?.variantId }
      : {}),
    ...(product && 'variant' in product ? { variant: product?.variant } : {}),
    ...(product && 'isStock' in product ? { isStock: product?.isStock } : {}),
    ...(product && 'available' in product
      ? { available: product?.available ?? 0 }
      : {}),
    ...(product && 'deliveryTime' in product
      ? {
          deliveryTime: {
            from: product?.deliveryTime?.lowest,
            to: product?.deliveryTime?.highest
          }
        }
      : {})
  })

  const addImpression = (
    product: MhubImpressionProduct,
    position: number,
    list: string
  ): void => {
    impressions.current = [
      ...impressions.current,
      getMhubImpressionProductData(product, position, list)
    ]
    pushImpressionsDebounced()
  }

  const pushImpressionsDebounced = debounce(1000, () => {
    pushImpressions(impressions.current)
    impressions.current = []
  })

  const getMhubEmptyImpression = (): MhubImpression => getMhubImpression(null)

  const getMhubImpression = (
    products: MhubImpressionProductData[]
  ): MhubImpression => ({
    ...(products?.length > 0 ? { event: 'action.impress.products' } : {}),
    ...{
      action: {
        impress: {
          products: products?.length > 0 ? products : null
        }
      }
    }
  })

  const pushImpressions = (products: MhubImpressionProductData[]) => {
    pushToMhub(getMhubEmptyImpression())
    pushToMhub(getMhubImpression(products))
  }

  const getImpressionData = (
    product: MhubImpressionProduct,
    list: string
  ): MhubImpressionData => {
    const item = toProduct(product)
    return {
      ...{
        id: item?.id ? +item?.id : null,
        category: item?.MHubProperties?.category,
        categoryId: item?.mainCategory?.id ? +item?.mainCategory?.id : null
      },
      ...(list ? { list } : {})
    }
  }

  const getShowProductEvent = (
    product: MhubImpressionProduct,
    list: string
  ): MhubShowProductEvent => ({
    event: 'action.show.products',
    action: {
      show: {
        products: [getMhubImpressionProductData(product, null, list)]
      }
    }
  })

  return {
    addImpression,
    getImpressionData,
    getShowProductEvent
  }
}
