import {
  FC,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef
} from 'react'
import DateUtils from '@/core/date'

const MessageContext = createContext<Message[]>(null)
const MessageContextDispatch = createContext<MessageContextDispatchType>(null)

export const useMessages = (): Message[] => useContext(MessageContext)
export const useMessageDispatch = (): MessageContextDispatchType =>
  useContext(MessageContextDispatch)

type MessageContextDispatchType = {
  addMessage: (type: MessageType) => void
  removeMessage: (type: MessageType) => void
}

export enum MessageType {
  AddedToCart
}

enum Action {
  Add,
  Remove
}

type Message = {
  type: MessageType
  action: Action
  expiration?: Date
}

const messageReducer = (state: Message[], message: Message): Message[] => {
  switch (message.action) {
    case Action.Add:
      if (state.map(({ type }) => type).includes(message.type)) {
        return state
      } else {
        return [...state, message]
      }
    case Action.Remove:
      return state.filter(({ type }) => type !== message.type)
  }
}

const MessageProvider: FC = ({ children }) => {
  const [state, dispatcher] = useReducer(messageReducer, [])
  const intervalHandler = useRef<ReturnType<typeof setInterval>>(null)

  const clearExpirationInterval = () => {
    clearInterval(intervalHandler.current)
    intervalHandler.current = null
  }

  useEffect(() => {
    const withExpiration = state?.filter(({ expiration }) => expiration) ?? []
    if (withExpiration.length > 0 && intervalHandler.current === null) {
      intervalHandler.current = setInterval(() => {
        withExpiration
          .filter((item) => item.expiration < DateUtils.now())
          ?.forEach((item) => {
            removeMessage(item.type)
          })
      }, 1000)
    } else if (
      withExpiration.length === 0 &&
      intervalHandler.current !== null
    ) {
      clearExpirationInterval()
    }

    return () => {
      clearExpirationInterval()
    }
  }, [state])

  const addMessage = (type: MessageType) =>
    dispatcher({
      type,
      action: Action.Add,
      expiration: DateUtils.addSeconds(DateUtils.now(), 5)
    })

  const removeMessage = (type: MessageType) =>
    dispatcher({
      type,
      action: Action.Remove
    })

  return (
    <MessageContextDispatch.Provider
      value={{
        addMessage,
        removeMessage
      }}
    >
      <MessageContext.Provider value={state}>
        {children}
      </MessageContext.Provider>
    </MessageContextDispatch.Provider>
  )
}

export default MessageProvider
