import { FilterInput } from '@/network/graphql.g'

export function toggleExpandedIndex<T>(index: T, indices: T[]): T[] {
  const foundIndex = indices.indexOf(index)
  if (foundIndex > -1) {
    const indicesCopy = Object.assign([], indices)
    indicesCopy.splice(foundIndex, 1)
    return indicesCopy
  }
  return [...indices, index]
}

export const isSSR = typeof window === 'undefined'

export const isDevelopment = process.env.NODE_ENV === 'development'

export const logApi = isDevelopment || process.env.LOG_API === '1'

export const parseJSON = (jsonString: string): unknown => {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === 'object') {
      return o
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return null
}

export const getCachableHeader = (
  filterInput: FilterInput[],
  isLoggedIn: boolean
): { 'x-cachable'?: '1' } =>
  (filterInput ?? []).length > 0 || isLoggedIn
    ? {}
    : {
        'x-cachable': '1'
      }
