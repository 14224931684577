export enum FilterQueryItem {
  SIZE = 'size_table_item[]',
  STOCK = 'inStock',
  IN_SALE = 'inSale',
  COLOR = 'colour[]',
  MATERIAL = 'material[]',
  MANUFACTURER = 'manufacturer[]',
  GENDER = 'gender[]',
  CATEGORY = 'category[]',
  PRICE_MAX = 'price[max]',
  PRICE_MIN = 'price[min]',
  SORT = 'sort',
  NEWS = 'news',
  TAG = 'tag_{tagId}[]',
  CAMPAIGN_TYPE = 'campaign_type[]',
  CAMPAIGN_DESIGNATION = 'campaign_designation[]',
  SEARCH = 'search',
  PATTERN = 'pattern[]'
}
