import {
  width,
  space,
  height,
  fontSize,
  color,
  flex,
  flexGrow,
  flexShrink,
  flexBasis,
  justifySelf,
  alignSelf,
  order,
  textAlign,
  maxWidth,
  minWidth,
  zIndex,
  position,
  display,
  borderColor,
  minHeight,
  maxHeight,
  border,
  gridColumn
} from 'styled-system'
import { css } from 'styled-components'

import styled from 'styled-components'

type BoxProps = {
  cursorOnHover: boolean
}

const hoverCss = css`
  &:hover {
    cursor: pointer;
  }
`

const BaseBox = css`
  ${(props) => (props.cursorOnHover ? hoverCss : null)}
  ${space};
  ${width};
  ${height};
  ${fontSize};
  ${color};
  ${flex};
  ${flexGrow};
  ${flexShrink};
  ${flexBasis};
  ${justifySelf};
  ${alignSelf};
  ${order};
  ${alignSelf};
  ${textAlign};
  ${maxWidth};
  ${minWidth};
  ${zIndex};
  ${position};
  ${display};
  ${borderColor};
  ${minHeight};
  ${maxHeight};
  ${border};
  ${gridColumn};
`

const Box = styled.div<BoxProps>`
  ${BaseBox}
`

export const BoxSpan = styled.span<BoxProps>`
  display: block;
  ${BaseBox}
`

export default Box
