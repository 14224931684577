export type RatingEventObject = {
  orderId: string
  productId: string
  orderProductId: string
  rate: number
  description: string
  showName: boolean
  orderProductTypeRates: {
    type: string
    rate: number
  }[]
  hash: string
}

type MhubPostProductRatingEvent = {
  event: string
  action: {
    custom: {
      context1: string
      context2: string
      context3: string
      context4: number
      context5: string | null
      context6: string | null
      context7: string | null
      context8: string
    }
  }
}

const mapContextValue = (
  value: number,
  mapping: Record<number, string>
): string | null => {
  return mapping[value] || null
}

const mapContextSize = (value: number): string | null =>
  mapContextValue(value, { 1: 'small', 2: 'fit', 3: 'large' })
const mapContextColor = (value: number): string | null =>
  mapContextValue(value, { 1: 'bright', 2: 'fit', 3: 'dark' })
const mapContextMaterial = (value: number): string | null =>
  mapContextValue(value, { 1: 'bad', 3: 'good' })

export const getPostProductRatingEvent = (
  value: RatingEventObject
): MhubPostProductRatingEvent => {
  return {
    event: 'action.custom.event',
    action: {
      custom: {
        context1: 'post_product_rating',
        context2: value.orderId,
        context3: value.productId,
        context4: value.rate,
        context5: mapContextSize(value.orderProductTypeRates[0].rate),
        context6: mapContextColor(value.orderProductTypeRates[1].rate),
        context7: mapContextMaterial(value.orderProductTypeRates[2].rate),
        context8: value.description ? 'extended' : 'basic'
      }
    }
  }
}
