import { FC } from 'react'
import { Box } from '@/components/atoms/Grid'
import { H3, Small2 } from '@/components/atoms/typography'

type EmptyPlaceholderProps = {
  IllustrationElement: JSX.Element
  title: string
  text: string
  textSecondary?: string
} & Rest

const ContentPlaceholder: FC<EmptyPlaceholderProps> = ({
  IllustrationElement,
  title,
  text,
  textSecondary,
  ...rest
}) => (
  <Box textAlign="center" {...rest}>
    {IllustrationElement}
    <H3>{title}</H3>
    <Small2>{text}</Small2>
    <Small2 mt={47} mb={0}>
      {textSecondary}
    </Small2>
  </Box>
)

export default ContentPlaceholder
