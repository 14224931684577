import { FC } from 'react'

const SweartshirtsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sweartshirts-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M139.41,126.58l-17.49-89.47c-.92-5.01-3.53-10.01-5.8-11.12-2.27-1.1-21.41-10.56-23.93-10.56H50.33c-2.52,0-21.66,9.46-23.93,10.56-2.27,1.1-4.73,5.3-5.47,9.33-.63,3.41-17.82,91.26-17.82,91.26-.92,5.01,2.29,10.25,7.13,11.64l1.28,.37c4.84,1.39,9.58-1.57,10.52-6.58l9.57-46.13c.94-5.01,2.1-10.21,2.57-11.57,.47-1.36,.86,6.79,.86,11.89v65.8H107.48V86.2c0-5.1,.17-10.43,.39-11.85,.21-1.42,2.1,6.52,3.05,11.53l9.57,46.13c.94,5.01,5.68,7.97,10.52,6.58l1.28-.37c4.84-1.39,8.05-6.62,7.13-11.64Z" />
        <polyline points="16.49 138.51 13.92 146.21 2 142.13 4.7 134.03" />
        <polyline points="125.96 138.51 128.53 146.21 140.45 142.13 137.75 134.03" />
        <path d="M92.28,15.44c0,11.76-9.41,21.29-21.01,21.29s-21.01-9.53-21.01-21.29" />
        <path d="M103.99,19.37c-9.55,9.51-14.67,17.37-32.77,17.37s-24.45-9.07-32.77-17.37c0-9.59,14.67-17.37,32.77-17.37s32.77,7.77,32.77,17.37Z" />
      </g>
    </symbol>
  </svg>
)

export default SweartshirtsWomenDefinition
