import { FC } from 'react'

const JeansWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="jeans-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M54.92,34.47c2.62,0,4.75-2.79,4.75-6.24V12.53" />
        <polygon points="31.56 12.12 2 143.43 31.49 152 58.42 43.22 85.36 152 114.84 143.43 85.28 12.12 31.56 12.12" />
        <rect x="31.56" y="2" width="53.67" height="10.12" />
        <path d="M40.95,12.11c2.73,4.21,2.44,10.15-1.12,14.73-3.23,4.15-8.25,6.03-12.77,5.24" />
        <path d="M75.84,12.11c-2.73,4.21-2.44,10.15,1.12,14.73,3.23,4.15,8.25,6.03,12.77,5.24" />
        <line x1="42.78" y1="2" x2="42.78" y2="12.11" />
        <line x1="74.01" y1="2" x2="74.01" y2="12.11" />
      </g>
    </symbol>
  </svg>
)

export default JeansWomenDefinition
