import { FC } from 'react'

const SkirtsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="skirts-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M115.7,17.87H36.81L2,138.89c0,5.66,33.25,13.11,74.26,13.11s74.26-7.45,74.26-13.11L115.7,17.87Z" />
        <rect x="36.82" y="2" width="78.87" height="15.87" />
        <line x1="50.7" y1="37.35" x2="56.25" y2="17.87" />
        <line x1="101.81" y1="37.35" x2="96.27" y2="17.87" />
      </g>
    </symbol>
  </svg>
)

export default SkirtsDefinition
