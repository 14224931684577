import { FC } from 'react'

const ShirtsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="shirts-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M104.4,7.28l18.78,8.33c4.04,1.79,6.93,5.29,7.75,9.41l21.06,104.74-20.29,5.31-15.81-76.56c-3.22,29.5-3.6,57.29,0,82.7H38.1c3.83-25.2,3.45-53,0-82.7l-15.81,76.56-20.29-5.31L23.06,25.02c.83-4.12,3.71-7.61,7.75-9.41L49.6,7.28" />
        <polygon points="52.23 2 77.01 26.05 60.54 35.09 49.6 7.28 52.23 2" />
        <polygon points="101.8 2 77.01 26.05 93.49 35.09 104.43 7.28 101.8 2" />
        <line x1="52.23" y1="2" x2="101.8" y2="2" />
        <line x1="77.01" y1="26.05" x2="77.01" y2="141.2" />
      </g>
    </symbol>
  </svg>
)

export default ShirtsWomenDefinition
