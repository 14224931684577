import { FC } from 'react'

const JacketsFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="jackets-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <line x1="52.09" y1="4" x2="101.9" y2="4" />
        <path d="M111.45,140.66c3.46-3.07,5.65-7.57,5.65-12.56v-21.78" />
        <path d="M39.44,106.31v21.79c0,4.95,2.15,9.41,5.55,12.47" />
        <path d="M117.1,109.13v-19.51c0-5.47,.19-11.19,.41-12.72,.23-1.53,2.25,6.99,3.27,12.37l10.26,49.49c1.01,5.37,6.09,8.55,11.28,7.06l1.37-.39c5.19-1.49,8.63-7.11,7.65-12.48l-18.75-95.99c-.99-5.38-3.78-10.74-6.22-11.93-2-.97-16.19-7.98-22.7-10.46" />
        <path d="M52.71,14.62c-6.58,2.53-20.56,9.44-22.54,10.4-2.43,1.18-5.07,5.68-5.86,10.01-.67,3.66-19.1,97.91-19.1,97.91-.99,5.38,2.45,10.99,7.65,12.48l1.37,.39c5.19,1.49,10.27-1.69,11.28-7.06l10.26-49.49c1.01-5.37,2.25-10.96,2.76-12.41,.51-1.46,.92,7.29,.92,12.76v23.37" />
        <polyline points="19.53 145.74 16.78 154 4 149.62 6.9 140.93" />
        <polyline points="136.91 145.74 139.67 154 152.44 149.62 149.55 140.93" />
        <path d="M55.58,16.1c-8.1-3.74-7.99-6.39-6.75-9.14,1.24-2.76,3.13-4.58,11.23-.83,8.1,3.74,19.34,11.64,18.11,14.39-1.24,2.76-14.49-.67-22.59-4.41Z" />
        <path d="M100.7,16.1c8.1-3.74,7.99-6.39,6.75-9.14-1.24-2.76-3.13-4.58-11.23-.83-8.1,3.74-19.34,11.64-18.11,14.39,1.24,2.76,14.49-.67,22.59-4.41Z" />
        <line x1="78.17" y1="20.51" x2="78.17" y2="140.57" />
        <rect x="44.66" y="140.93" width="67.02" height="9.94" />
      </g>
    </symbol>
  </svg>
)

export default JacketsFrogiesDefinition
