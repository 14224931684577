import { FC } from 'react'
import { makePriceString } from '@/core/toStringHelper'
import Trans from 'next-translate/Trans'
import { Currency, DiscountDelivery } from '@/network/graphql.g'

type ShippingInfoProps = {
  discountDelivery: DiscountDelivery
  currency: Currency
  highlightElement?: JSX.Element
}

const ShippingInfo: FC<ShippingInfoProps> = ({
  discountDelivery,
  currency,
  highlightElement = <></>
}) => {
  if (!discountDelivery) {
    return null
  }

  if (
    discountDelivery.amountToDiscountDelivery === 0 &&
    discountDelivery.discountDeliveryCost === 0
  ) {
    return (
      <Trans
        i18nKey="common:Cart.DeliveryPrice.free"
        components={[highlightElement]}
      />
    )
  } else if (
    discountDelivery.amountToDiscountDelivery === 0 &&
    discountDelivery.discountDeliveryCost > 0
  ) {
    return (
      <Trans
        i18nKey="common:Cart.DeliveryPrice.lower"
        components={[highlightElement]}
        values={{
          price: makePriceString({
            price: discountDelivery.discountDeliveryCost,
            currency
          })
        }}
      />
    )
  } else {
    if (
      discountDelivery.amountToDiscountDelivery > 0 &&
      discountDelivery.discountDeliveryCost === 0
    ) {
      return (
        <Trans
          i18nKey="common:Cart.DeliveryPrice.remains"
          components={[highlightElement]}
          values={{
            price: makePriceString({
              price: discountDelivery.amountToDiscountDelivery,
              currency
            })
          }}
        />
      )
    } else {
      return (
        <Trans
          i18nKey="common:Cart.DeliveryPrice.remainsToLower"
          components={[highlightElement, highlightElement]}
          values={{
            price: makePriceString({
              price: discountDelivery.amountToDiscountDelivery,
              currency
            }),
            deliveryPrice: makePriceString({
              price: discountDelivery.discountDeliveryCost,
              currency
            })
          }}
        />
      )
    }
  }
}

export default ShippingInfo
