import DateUtils from '@/core/date'
import { Cookies } from '@/core/graphQlCookieManager'
import { Cookie, useCookie } from 'next-cookie'

const cookieIdSeparator = '|'
const mhubFcidsVersion = '02'
const base64chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

const generateFcid = () => {
  const acqTime = Math.round(Date.now() / 1000)
  return `${newId()}${cookieIdSeparator}${mhubFcidsVersion}${cookieIdSeparator}${acqTime}`
}

const newId = () => {
  let result = ''
  let random = Math.random() * 1073741824
  while (random > 0) {
    result += base64chars[Math.floor(random % 64)]
    random = Math.floor(random / 64)
  }
  return result
}

export const getFcid = (): string => useCookie()?.get<string>(Cookies.mHubFcid)

export const initializeFcid = (cookie: Cookie): Cookie => {
  cookie.set(
    Cookies.mHubFcid,
    cookie.has(Cookies.mHubFcid)
      ? cookie.get(Cookies.mHubFcid)
      : generateFcid(),
    {
      path: '/',
      expires: DateUtils.addYears(DateUtils.now(), 10)
    }
  )
  return cookie
}
