import { FC } from 'react'

const PyjamasWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="pyjamas-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.34,44.57c2.59-.88,6.04-3.84,9.22-8.24,3.18-4.39,5.33-13.55,5.96-17.14" />
        <path d="M30.53,19.19c-.09,3.59,1.48,9.51,4.47,16.02,2.22,4.82,7.07,10.63,10.71,14.55" />
        <path d="M60.89,19.19c.09,3.59-1.48,9.51-4.47,16.02-2.22,4.82-7.07,10.63-10.7,14.55" />
        <path d="M76.07,44.57c-6.03,13.76-.47,38.45,0,53.32H15.34c.47-14.87,6.03-39.55,0-53.32" />
        <path d="M76.07,44.57c-2.59-.88-6.04-3.84-9.22-8.24-3.18-4.39-5.33-13.55-5.96-17.14" />
        <line x1="30.52" y1="19.19" x2="30.52" y2="2" />
        <line x1="60.89" y1="19.19" x2="60.89" y2="2" />
        <polygon points="40.74 152 45.66 132.52 50.59 152 89.41 145.19 76.19 97.88 15.14 97.88 2 145.19 40.74 152" />
      </g>
    </symbol>
  </svg>
)

export default PyjamasWomenDefinition
