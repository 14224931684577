import { Routes } from '@/core/routes'
import { NextRouter } from 'next/router'
import { stringify, ParsedUrlQuery } from 'querystring'

export const makeLoginRouterWithReferrer = (pathName: string): string =>
  makeRouterWithReferrer(Routes.login, pathName)

export const makeRegisterRouterWithReferrer = (pathName: string): string =>
  makeRouterWithReferrer(Routes.signUp, pathName)

const makeRouterWithReferrer = (route: string, pathName: string): string =>
  `${route}/?referer=${pathName}`

export const getShallowParams = (
  router: NextRouter
): { url: string; as: string } => {
  const hashSplit = router.asPath.split('#')
  const hash = hashSplit.length > 1 ? `#${hashSplit[hashSplit.length - 1]}` : ''
  const path = router.asPath.split('?')?.[0]
  const getQuery = (query: ParsedUrlQuery) => {
    const queryString = stringify(query)
    return queryString ? `?${queryString}` : ''
  }
  const url = `${router.route}${getQuery(router.query)}${hash}`
  const regex = /\[{1,2}\.{0,3}([^[\].]+)\]{1,2}/gi
  const params = [...router.route.matchAll(regex)].map((item) => item[1])
  params.forEach((item) => delete router.query[item])
  const as = `${path}${getQuery(router.query)}${hash}`
  return {
    url,
    as
  }
}
