import { FC } from 'react'

const BaseballcapsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="baseballcaps-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M151.89,64.22c.07-1.14,.11-2.3,.11-3.46,0-32.45-26.31-58.76-58.76-58.76S34.48,28.31,34.48,60.76c0,1.16,.04,2.31,.11,3.46h117.3Z" />
        <path d="M93.24,2c-4.64,0-9.15,.55-13.48,1.57,25.95,6.09,45.28,29.38,45.28,57.19,0,1.16-.04,2.31-.11,3.46h26.96c.07-1.14,.11-2.3,.11-3.46,0-32.45-26.31-58.76-58.76-58.76Z" />
        <path d="M34.59,64.22c-7.65,1.05-33.34,6.45-32.57,16.74,.99,13.24,25.21,13.54,34.48,12.8,27.66-2.22,62.57-17.64,87.03-29.54" />
      </g>
    </symbol>
  </svg>
)

export default BaseballcapsDefinition
