import React, { useContext } from 'react'
import { FocusRoutes } from '@/core/routes'

const CurrentPageProvider = React.createContext<string>('')

type FocusedRouteFunction = () => FocusMode

export enum FocusMode {
  None,
  Basic,
  Full
}

type CurrentPageFocus = {
  getFocusType: FocusedRouteFunction
}

const isInFocus = (currentPage) => () => {
  if (FocusRoutes.full.includes(currentPage)) {
    return FocusMode.Full
  } else if (FocusRoutes.basic.includes(currentPage)) {
    return FocusMode.Basic
  } else {
    return FocusMode.None
  }
}

export const useCurrentPage = (): CurrentPageFocus => {
  const ctx = useContext(CurrentPageProvider)
  return {
    getFocusType: isInFocus(ctx)
  }
}

export { CurrentPageProvider }
