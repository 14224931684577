import { StoreId } from '@/network/graphql.g'
import { AliaticTheme } from '@/theme/AliaticTheme'
import { BezvasportTheme } from '@/theme/BezvasportTheme'
import { FactcoolTheme } from '@/theme/FactcoolTheme'
import { FrogiesTheme } from '@/theme/FrogiesTheme'
import { ITheme } from '@/theme/interfaces/ITheme'

export enum DomainNames {
  Factcool = 'factcool',
  Bezvasport = 'bezvasport',
  Aliatic = 'aliatic',
  Filatic = 'filatic',
  Frogies = 'frogies'
}
export type StorePropertiesType = {
  storeId: StoreId
  theme: ITheme
  domainName: string
  defaultSubdomainName: string
}

export type Domain = {
  domain: string
  subdomain: string
}

export const stores: Record<DomainNames, StorePropertiesType> = {
  [DomainNames.Factcool]: {
    storeId: StoreId.Factcool,
    theme: FactcoolTheme,
    domainName: DomainNames.Factcool,
    defaultSubdomainName: 'sk'
  },
  [DomainNames.Bezvasport]: {
    storeId: StoreId.Bezvasport,
    theme: BezvasportTheme,
    domainName: DomainNames.Bezvasport,
    defaultSubdomainName: 'cs'
  },
  [DomainNames.Aliatic]: {
    storeId: StoreId.Aliatic,
    theme: AliaticTheme,
    domainName: DomainNames.Aliatic,
    defaultSubdomainName: 'en'
  },
  [DomainNames.Filatic]: {
    storeId: StoreId.Bezvasport,
    theme: BezvasportTheme,
    domainName: DomainNames.Bezvasport,
    defaultSubdomainName: 'ro'
  },
  [DomainNames.Frogies]: {
    storeId: StoreId.Frogies,
    theme: FrogiesTheme,
    domainName: DomainNames.Frogies,
    defaultSubdomainName: 'en'
  }
}

export const defaultStore = stores.factcool
