import { FC } from 'react'

const JumpSuitsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="jump-suits-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="81.35 2 81.35 15.21 62.93 18.34 60.32 2 81.35 2" />
        <polygon points="2 2 2 15.21 20.42 18.34 23.04 2 2 2" />
        <path d="M68.9,63.39l11.38,84.61-13.83,4.01-24.77-82.1-24.77,82.1-14.91-4.32L14.51,63.21c.41-1.34,1.04-2.56,1.85-3.63,4.79-6.33,5.85-14.73,3.44-22.29l-2.71-8.51c2.66-4.64,4.94-17.42,5.96-26.78H60.32c1.02,9.36,3.29,22.14,5.96,26.78l-2.73,8.43c-2.47,7.63-1.38,16.13,3.48,22.51,.82,1.08,1.46,2.31,1.88,3.66Z" />
        <line x1="20.66" y1="49.49" x2="60.64" y2="49.49" />
      </g>
    </symbol>
  </svg>
)

export default JumpSuitsDefinition
