import { Box } from '@/components/atoms/Grid'
import Link from '@/components/atoms/link'
import { PremiumManufacturer } from '@/network/graphql.g'
import { makeCdnUrl } from '@/utils/urlHelper'
import styled from 'styled-components'
import Image from 'next/image'
import { FC } from 'react'

interface PremiumManufacturerImgUrl extends PremiumManufacturer {
  linkImage?: string
}

type PremiumBrandsProps = {
  premiumManufacturers: PremiumManufacturer[]
} & Rest

const PremiumBrands: FC<Rest & PremiumBrandsProps> = ({
  premiumManufacturers,
  ...rest
}) => {
  const PremiumManufacturerImgUrl: PremiumManufacturerImgUrl[] =
    premiumManufacturers
      .sort((a, b) => a.sort - b.sort)
      .map((manufacturer) => ({
        ...manufacturer,
        linkImage: makeCdnUrl(manufacturer.logo)
      }))

  return (
    <Box {...rest}>
      {PremiumManufacturerImgUrl.map((premiumManufacturer) => (
        <Box key={premiumManufacturer.link}>
          {premiumManufacturer.logo?.length > 0 ? (
            <Link href={premiumManufacturer.link}>
              <StyledDiv>
                <Image
                  alt={premiumManufacturer.name}
                  src={premiumManufacturer.linkImage}
                  className={'img-premium'}
                  layout="fill"
                />
              </StyledDiv>
            </Link>
          ) : (
            <Box mb="6px">
              <Link href={premiumManufacturer.link}>
                {premiumManufacturer.name}
              </Link>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}

export default PremiumBrands

const StyledDiv = styled.div`
  width: 85px;
  position: relative;
  padding-bottom: 10px;

  > span {
    position: unset !important;
  }

  .img-premium {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`
