import Head from 'next/head'
import { FC, useContext } from 'react'
import { SeoTags } from '@/network/graphql.g'
import { useRouter } from 'next/router'
import { mediaStyles } from '@/components/atoms/media'
import { Asset, useAsset } from '@/providers/assetsProvider'
import { applyImagePolicy } from '@/utils/imageExtension'
import { StoreContext } from '@/providers/storeProvider'

type DocumentHeadProps = {
  host: string
  seoTags?: SeoTags
}

const DocumentHead: FC<DocumentHeadProps> = ({ host, seoTags }) => {
  const router = useRouter()
  const { asset } = useAsset()
  const { isFrogies, isAliatic, isFactcool, isBezvasport } =
    useContext(StoreContext)

  return (
    <Head>
      <link href={`${host}${asset(Asset.Favicon, true)}`} rel="shortcut icon" />
      {isFactcool && (
        <>
          <link
            rel="alternate"
            hrefLang="be-BY"
            href={`https://be.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="bs-BA"
            href={`https://ba.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="cs-CZ"
            href={`https://cs.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="de-DE"
            href={`https://de.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="et-EE"
            href={`https://ee.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="hr-HR"
            href={`https://hr.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="lv-LV"
            href={`https://lv.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="lt-LT"
            href={`https://lt.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="hu-HU"
            href={`https://hu.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="nl-NL"
            href={`https://nl.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="de-AT"
            href={`https://at.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="pl-PL"
            href={`https://pl.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="ro-RO"
            href={`https://ro.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sl-SI"
            href={`https://si.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sk-SK"
            href={`https://sk.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="el-GR"
            href={`https://gr.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="bg-BG"
            href={`https://bg.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sr-RS"
            href={`https://rs.factcool.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="uk-UA"
            href={`https://ua.factcool.com${router.asPath}`}
          />
        </>
      )}
      {isFrogies && (
        <>
          <link
            rel="alternate"
            hrefLang="bs-BA"
            href={`https://frogies.ba${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="cs-CZ"
            href={`https://frogies.cz${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="de-DE"
            href={`https://frogies.de${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="et-EE"
            href={`https://frogies.ee${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="hr-HR"
            href={`https://frogies.hr${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="lv-LV"
            href={`https://frogies.lv${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="lt-LT"
            href={`https://frogies.lt${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="hu-HU"
            href={`https://frogies.hu${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="de-AT"
            href={`https://frogies.at${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="pl-PL"
            href={`https://frogies.pl${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="ro-RO"
            href={`https://frogies.ro${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sl-SI"
            href={`https://frogies.si${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sk-SK"
            href={`https://frogies.sk${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="el-GR"
            href={`https://frogies.gr${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="bg-BG"
            href={`https://frogies.bg${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sr-RS"
            href={`https://frogies.rs${router.asPath}`}
          />
        </>
      )}
      {isAliatic && (
        <>
          <link
            rel="alternate"
            hrefLang="be-BY"
            href={`https://be.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="bs-BA"
            href={`https://ba.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="cs-CZ"
            href={`https://cs.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="de-DE"
            href={`https://de.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="et-EE"
            href={`https://ee.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="hr-HR"
            href={`https://hr.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="lv-LV"
            href={`https://lv.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="lt-LT"
            href={`https://lt.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="hu-HU"
            href={`https://hu.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="nl-NL"
            href={`https://nl.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="de-AT"
            href={`https://at.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="pl-PL"
            href={`https://pl.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="ro-RO"
            href={`https://ro.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sl-SI"
            href={`https://si.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sk-SK"
            href={`https://sk.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="el-GR"
            href={`https://gr.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="bg-BG"
            href={`https://bg.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sr-RS"
            href={`https://rs.aliatic.com${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="uk-UA"
            href={`https://ua.aliatic.com${router.asPath}`}
          />
        </>
      )}
      {isBezvasport && (
        <>
          <link
            rel="alternate"
            hrefLang="cs-CZ"
            href={`https://bezvasport.cz${router.asPath}`}
          />
          <link
            rel="alternate"
            hrefLang="sk-SK"
            href={`https://bezvasport.sk${router.asPath}`}
          />
        </>
      )}

      {seoTags?.title ? (
        <>
          <meta name="dc.title" content={seoTags?.title} key="dc.title" />
          <meta property="og:title" content={seoTags?.title} key="og:title" />
          <title>{seoTags?.title}</title>
        </>
      ) : null}
      {seoTags?.description && (
        <>
          <meta
            name="description"
            content={seoTags?.description}
            key="description"
          />
          <meta
            property="og:description"
            content={seoTags?.description}
            key="og:description"
          />
        </>
      )}
      <meta
        property="og:image"
        content={applyImagePolicy(seoTags?.image || 'static/1400.png')}
        key="og:image"
      />
      <meta
        property="og:url"
        content={`${host}${router.asPath}`}
        key="og:url"
      />
      {seoTags?.type ? (
        <meta property="og:type" content={seoTags?.type} key="og:type" />
      ) : null}
      {seoTags?.keywords ? (
        <meta name="keywords" content={seoTags?.keywords} key="keywords" />
      ) : null}
      {seoTags?.storeName ? (
        <meta
          property="og:site_name"
          content={seoTags?.storeName}
          key="og:site_name"
        />
      ) : null}
      <link rel="dns-prefetch" href="//live.luigisbox.com" />
      <style type="text/css">{mediaStyles}</style>
    </Head>
  )
}

export default DocumentHead
