export class SentryError extends Error {
  public data: Rest

  constructor(message: string, data: Rest) {
    super(message)
    this.data = data
  }

  toJSON(): unknown {
    const alt: unknown = {}

    Object.getOwnPropertyNames(this).forEach(function (key) {
      alt[key] = this[key]
    }, this)

    return alt
  }
}
