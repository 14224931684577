import { FC } from 'react'

const BalerinasDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="balerinas-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M39.86,36.85v3.9H7.58v-5.21c5.08,.26,17.06,.85,32.29,1.31Z" />
        <path d="M5.74,2s54.72,12.18,76.35,15.12c21.64,2.94,39.11-2.94,39.11-2.94l27.47,10.31c2,.75,3.32,2.67,3.32,4.81v.08c0,2.06-1.58,3.4-3.48,4.17-11.29,4.6-25.07,6.69-41.04,7.2-22.55,.73-48.16-3.32-67.61-3.9-15.23-.46-27.2-1.05-32.29-1.31-1.64-.08-3.05-1.18-3.55-2.75-.86-2.7-2.02-8.13-2.02-11.2C2,7.52,4.91,2,4.91,2" />
      </g>
    </symbol>
  </svg>
)

export default BalerinasDefinition
