import { FC } from 'react'

const CheerssocksFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="cheerssocks-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M94.94,62.03V4H60.77V53.32c-1.69,1.34-3.29,2.89-4.7,4.67l-24.04,30.27c-8.58,10.8-8.07,25.61,1.13,32.92,9.2,7.31,23.74,4.45,32.32-6.35l24.04-30.27c5.46-6.88,7.22-15.38,5.42-22.54Z" />
        <path d="M76.45,70c2.78-6.52,10.86-9.42,18.7-7.02-.07-.32-.13-.64-.21-.95V4H60.77V53.32c-1.69,1.34-3.29,2.89-4.7,4.67l-24.04,30.27c-8.58,10.8-8.07,25.61,1.13,32.92,9.2,7.31,23.74,4.45,32.32-6.35l20.42-25.7c-8.19-3.66-12.42-12.15-9.44-19.13Z" />
        <path d="M95.15,62.98c-.07-.32-.13-.64-.21-.95V4H60.77V53.32c-1.69,1.34-3.29,2.89-4.7,4.67l-24.04,30.27c-1.08,1.36-2.01,2.78-2.8,4.24,.41-.02,.82-.03,1.24-.03,15.66,0,28.63,11.53,30.89,26.57,1.48-1.23,2.87-2.62,4.13-4.2l20.42-25.7c-8.19-3.66-12.42-12.15-9.44-19.13,2.78-6.52,10.86-9.42,18.7-7.02Z" />
        <line x1="60.77" y1="12.66" x2="94.94" y2="12.66" />
        <polygon points="28.36 125.11 17.5 50.45 99.77 50.45 89.03 125.11 28.36 125.11" />
        <line x1="99.77" y1="50.45" x2="113.21" y2="19.84" />
        <polygon points="17.5 50.45 37.81 19.84 79.46 19.84 99.77 50.45 17.5 50.45" />
        <line x1="4" y1="19.84" x2="17.5" y2="50.45" />
        <path d="M153.31,62.03V4h-34.16V53.32c-1.69,1.34-3.29,2.89-4.7,4.67l-24.04,30.27c-8.58,10.8-8.07,25.61,1.13,32.92,9.2,7.31,23.74,4.45,32.32-6.35l24.04-30.27c5.46-6.88,7.22-15.38,5.42-22.54Z" />
        <path d="M134.83,70c2.78-6.52,10.86-9.42,18.7-7.02-.07-.32-.13-.64-.21-.95V4h-34.16V53.32c-1.69,1.34-3.29,2.89-4.7,4.67l-24.04,30.27c-8.58,10.8-8.07,25.61,1.13,32.92,9.2,7.31,23.74,4.45,32.32-6.35l20.42-25.7c-8.19-3.66-12.42-12.15-9.44-19.13Z" />
        <path d="M153.52,62.98c-.07-.32-.13-.64-.21-.95V4h-34.16V53.32c-1.69,1.34-3.29,2.89-4.7,4.67l-24.04,30.27c-1.08,1.36-2.01,2.78-2.8,4.24,.41-.02,.82-.03,1.24-.03,15.66,0,28.63,11.53,30.89,26.57,1.48-1.23,2.87-2.62,4.13-4.2l20.42-25.7c-8.19-3.66-12.42-12.15-9.44-19.13,2.78-6.52,10.86-9.42,18.7-7.02Z" />
        <line x1="119.15" y1="12.66" x2="153.31" y2="12.66" />
      </g>
    </symbol>
  </svg>
)

export default CheerssocksFrogiesDefinition
