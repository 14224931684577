import { FC } from 'react'

const GiftboxFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="giftbox-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <rect x="17.6" y="66.08" width="122.81" height="50.78" />
        <rect x="4" y="25.55" width="150" height="40.53" />
        <rect x="67.99" y="25.55" width="22.03" height="91.3" />
        <path d="M116,47.11c11.8,0,11.8-43.07,0-43.07-13.25-.72-23.85,9.08-33.07,24.33,8.41,13.47,23.76,18.74,33.07,18.74Z" />
        <path d="M42,47.11c-11.8,0-11.8-43.07,0-43.07,13.25-.72,23.85,9.08,33.07,24.33-8.41,13.47-23.76,18.74-33.07,18.74Z" />
        <line x1="49.8" y1="28.37" x2="108.2" y2="28.37" />
        <circle cx="79" cy="28.37" r="9.08" />
      </g>
    </symbol>
  </svg>
)

export default GiftboxFrogiesDefinition
