import { FC } from 'react'

const SwimwearDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="swimwear-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="35.14" y="2" width="83.73" height="16.07" />
        <path d="M77,64.27s.12,20.15-11.15,37.23L2,88.73c0-29.41,13.12-55.35,33.08-70.66H118.92c19.96,15.31,33.08,41.24,33.08,70.66l-63.85,12.77c-11.26-17.08-11.15-37.23-11.15-37.23" />
        <line x1="70.02" y1="94.06" x2="2.86" y2="80.69" />
        <line x1="84.43" y1="94.06" x2="151.59" y2="80.69" />
        <path d="M74.64,10.04c0,1.33-1.06,2.41-2.38,2.41s-2.38-1.08-2.38-2.41,1.06-2.41,2.38-2.41,2.38,1.08,2.38,2.41Z" />
        <path d="M84.11,10.04c0,1.33-1.06,2.41-2.38,2.41s-2.38-1.08-2.38-2.41,1.06-2.41,2.38-2.41,2.38,1.08,2.38,2.41Z" />
        <path d="M72.26,10.04s-4.18-.34-2.38,12.48c1.8,12.82-2.55,18.68-2.55,18.68" />
        <path d="M81.74,10.04s4.67,9.18,2.38,17.61c-2.29,8.43,3.5,11.36,3.5,11.36" />
      </g>
    </symbol>
  </svg>
)

export default SwimwearDefinition
