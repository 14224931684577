import { FC, useEffect } from 'react'
import { CartService, CartServiceType, Order } from '@/network/graphql.g'
import { createContext, useContext, useState } from 'react'
import { useCookie } from 'next-cookie'
import { GetServerSidePropsContext } from 'next'
import { removeCookieWithoutCookie } from '@/core/graphQlCookieManager'
import { dispatchMessages } from './orderDispatchProvider'
import useTranslation from 'next-translate/useTranslation'
import objectHash from '@/core/crypto/objectHash'

type OrderContextType = {
  order: Order
  setOrder: (order: Order) => void
  count: number
  giftBox: CartService
  getOrderChecksum: () => string
  createOrderChecksum: () => string
}

type OrderProviderProps = {
  initialData: Order
}

const OrderContext = createContext<OrderContextType>(null)

export const useOrder = (): OrderContextType => useContext(OrderContext)

const ChecksumCookieName = 'order-checksum'
const ChecksumCookieOptions = {
  maxAge: 24 * 60 * 60, // 1 day
  path: '/'
}

const OrderProvider: FC<OrderProviderProps> = ({
  initialData: initialOrder,
  children
}) => {
  const { t } = useTranslation('common')

  const [internalOrder, setInternalOrder] = useState<Order>(() => {
    if (typeof window !== 'undefined') {
      const storedOrder = localStorage.getItem('internalOrder')
      return storedOrder ? JSON.parse(storedOrder) : initialOrder
    }
    return initialOrder
  })

  useEffect(() => {
    if (internalOrder) {
      localStorage.setItem('internalOrder', JSON.stringify(internalOrder))
    }
  }, [internalOrder])

  useEffect(() => {
    if (initialOrder) {
      setInternalOrder(initialOrder)
      dispatchMessages(initialOrder, t)
    }
  }, [initialOrder, t])

  useEffect(() => {
    if (internalOrder) {
      const cookie = useCookie()
      cookie.set(
        ChecksumCookieName,
        createOrderChecksum(),
        ChecksumCookieOptions
      )
    }
  }, [internalOrder])

  const getOrderChecksum = () => {
    const cookie = useCookie()
    return cookie.get<string>(ChecksumCookieName)
  }

  const createOrderChecksum = (): string => {
    return objectHash(internalOrder ?? null)
  }

  return (
    <OrderContext.Provider
      value={{
        order: internalOrder,
        setOrder: setInternalOrder,
        giftBox: internalOrder?.cartServices?.find(
          (item) => item.id === CartServiceType.GiftBox
        ),
        count:
          internalOrder?.products?.reduce((acc, curr) => acc + curr.count, 0) ??
          0,
        getOrderChecksum,
        createOrderChecksum
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export default OrderProvider

export const removeOrderChecksumCookie = (
  context: GetServerSidePropsContext
): void => {
  removeCookieWithoutCookie(ChecksumCookieName, context)
}
