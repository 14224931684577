import { FC } from 'react'

const EspadrillesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="espadrilles-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M83.06,39.97L91.72,6.49c.61-2.36,3.18-3.63,5.43-2.68l52.85,22.33c1.58,.67,2.38,2.44,1.84,4.06l-.02,.05c-.36,1.07-1.24,1.89-2.34,2.15-5.4,1.3-19.66,5.02-27.87,6.59-8.21,1.56-31.58,.99-38.55,.99S10.49,36.62,6.1,35.84c-4.39-.77-4.39-8.27-3.87-13.43C2.75,17.24,6.1,2,6.1,2L87.78,21.71" />
        <path d="M3.05,33.18l-.7,7.06c-.19,1.97,1.26,3.71,3.24,3.86,15.22,1.13,71.26,3.81,77.48,3.81,6.97,0,30.34,.57,38.55-.99,7.11-1.35,20-4.95,26.82-6.74,1.61-.42,2.78-1.81,2.92-3.47l.61-7.08" />
      </g>
    </symbol>
  </svg>
)

export default EspadrillesDefinition
