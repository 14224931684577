import { FC, useRef } from 'react'
import Link from '@/components/atoms/link'
import { Box } from '@/components/atoms/Grid'
import ResponsiveImage from '@/components/atoms/responsiveImage'
import styled from 'styled-components'
import { validFor } from '@/theme/validFor'
import { PictureWithPlaceholder } from '../atoms/picture'
import { BreakpointKey } from '@/theme/breakpoints'
import { ImageQuality } from '@/utils/imageExtension'
import { useTrackBanners } from 'hooks/useTrackBanners'

export type ImageSize = {
  width: number
  height: number
}

export type BannerProps = {
  destinationUrl1?: string
  destinationUrl2?: string
  imageUrl: string
  content?: JSX.Element
  size?: ImageSize
  ratio?: number
} & Rest

type ImageProps = {
  imageUrl: string
  size?: ImageSize
}

type TrackBannersProps = {
  id?: string
  position?: string
  title?: string
  sortOrder?: string
  imageUrl?: string
  destinationUrl1?: string
  page?: string
  description?: string
  routerPath?: string
  category?: string
}

const Image: FC<ImageProps> = ({ imageUrl, size }) => {
  const props = size
    ? {
        imageUrl,
        layout: 'responsive',
        size,
        width: size.width,
        height: size.height,
        objectFit: 'cover'
      }
    : {
        imageUrl,
        layout: 'fill'
      }
  const ref = useRef<HTMLDivElement>()
  return (
    <StyledBox width="100%" ref={ref}>
      <StyledResponsiveImage
        onLoadingComplete={() => {
          if (ref.current) {
            ref.current.style.height = 'unset'
            ref.current.style.opacity = '1'
          }
        }}
        quality={ImageQuality.MediumHigh}
        {...props}
      />
    </StyledBox>
  )
}

export const BannerWithPlaceholder: FC<
  BannerProps & {
    preload: boolean
    preloadDefaultMedia: BreakpointKey
    lazy: boolean
    desktop: string
    tablet: string
    mobile: string
  }
> = (props) => {
  const {
    imageUrl,
    destinationUrl1,
    destinationUrl2,
    ratio,
    content,
    preload,
    preloadDefaultMedia,
    lazy,
    desktop,
    tablet,
    mobile,
    id,
    sortOrder,
    position,
    title,
    page,
    description,
    routerPath,
    category,
    ...rest
  } = props

  const bannerTrack: TrackBannersProps = {
    id,
    position,
    title,
    sortOrder,
    imageUrl,
    destinationUrl1,
    page,
    description,
    routerPath,
    category
  }
  const { observe, pushShowBanners } = useTrackBanners(bannerTrack)
  const setTrackBanner = () => {
    if (destinationUrl1 || destinationUrl2) {
      pushShowBanners(bannerTrack)
    }
  }
  return (
    <Box position="relative" onClick={setTrackBanner} ref={observe} {...rest}>
      <StyledPictureWithPlaceholder
        src={imageUrl}
        desktop={desktop}
        tablet={tablet}
        mobile={mobile}
        quality={ImageQuality.MediumHigh}
        ratio={ratio}
        preload={preload}
        preloadDefaultMedia={preloadDefaultMedia}
        lazy={lazy}
      />
      {content && (
        <ContentWrapper position="absolute">{content}</ContentWrapper>
      )}
      {destinationUrl1 && <LeftLink href={destinationUrl1} />}
      {destinationUrl2 && <RightLink href={destinationUrl2} />}
    </Box>
  )
}

const Banner: FC<BannerProps> = ({
  imageUrl,
  destinationUrl1,
  destinationUrl2,
  content,
  size,
  id,
  sortOrder,
  position,
  title,
  page,
  description,
  routerPath,
  category,
  ...rest
}) => {
  const bannerTrack: TrackBannersProps = {
    id,
    position,
    title,
    sortOrder,
    imageUrl,
    destinationUrl1,
    page,
    description,
    routerPath,
    category
  }
  const { observe, pushShowBanners } = useTrackBanners(bannerTrack)
  const setTrackBanner = () => {
    if (destinationUrl1 || destinationUrl2) {
      pushShowBanners(bannerTrack)
    }
  }
  return (
    <Box position="relative" ref={observe} {...rest} onClick={setTrackBanner}>
      <ImageWrapper>
        <Image imageUrl={imageUrl} size={size} />
        {destinationUrl1 && <LeftLink href={destinationUrl1} />}
        {destinationUrl2 && <RightLink href={destinationUrl2} />}
      </ImageWrapper>
      {content}
    </Box>
  )
}

export default Banner

const ContentWrapper = styled(Box)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ImageWrapper = styled(Box)`
  position: relative;
`

const LeftLink = styled(Link)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`

const RightLink = styled(Link)`
  position: absolute;
  width: 50%;
  right: 0;
  bottom: 0;
  top: 0;
`

const StyledBox = styled(Box)`
  height: 0px;
  opacity: 0;
  > * {
    position: unset !important;
  }
  ${validFor.desktop`
    height: unset;
    opacity: 1;
  `}
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  border-radius: ${(props) => props.theme.borderRadius}px;
  ${(props) =>
    props.size
      ? ''
      : `
    position: relative !important; 
    height: unset !important;
  `};
`
const StyledPictureWithPlaceholder = styled(PictureWithPlaceholder)`
  border-radius: ${(props) => props.theme.borderRadius}px;
`
