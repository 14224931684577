import styled, { keyframes } from 'styled-components'
import { FC } from 'react'
import { Box } from '@/components/atoms/Grid'

export const SpinnerSize = 50

const Spinner: FC<Rest> = ({ ...rest }) => (
  <SpinnerWrapper {...rest}>
    <SpinnerText />
  </SpinnerWrapper>
)

export default Spinner

const rotatingAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const SpinnerWrapper = styled(Box)`
  display: inline-block;
  position: relative;
  width: ${SpinnerSize}px;
  height: ${SpinnerSize}px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #000;
  border-left-color: #000;
  border-right-color: #000;
  -webkit-animation: ${rotatingAnimation} 2s linear infinite;
  animation: ${rotatingAnimation} 2s linear infinite;
`

const SpinnerText = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #000;
    border-left-color: #000;
    border-right-color: #000;
    -webkit-animation: ${rotatingAnimation} 3s linear infinite;
    animation: ${rotatingAnimation} 3s linear infinite;
  }
  &:after {
    content: '';
    position: absolute;
    top: 11px;
    left: 11px;
    right: 11px;
    bottom: 11px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #000;
    border-left-color: #000;
    border-right-color: #000;
    -webkit-animation: ${rotatingAnimation} 1.5s linear infinite;
    animation: ${rotatingAnimation} 1.5s linear infinite;
  }
`
