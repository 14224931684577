import {
  gridColumn,
  gridRow,
  gridRowGap,
  gridColumnGap,
  gridTemplateColumns,
  gridTemplateRows,
  justifyItems,
  gridAutoRows,
  maxWidth,
  display
} from 'styled-system'
import Box from '@/components/atoms/Grid/Box'
import styled from 'styled-components'

type GridProps = {
  gridColumnStart?: number
  gridColumnEnd?: number
}

const Grid = styled<GridProps>(Box)`
  display: grid;
  ${gridRow};
  ${gridRowGap};
  ${gridColumn};
  ${gridColumnGap};
  ${gridTemplateColumns};
  ${gridTemplateRows};
  ${justifyItems};
  ${gridAutoRows}
  ${maxWidth}
  ${display};
  ${(props) =>
    props.gridColumnStart && `grid-column-start: ${props.gridColumnStart}`};
  ${(props) =>
    props.gridColumnEnd && `grid-column-end: ${props.gridColumnEnd}`};
`

export default Grid
