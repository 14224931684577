import { FC } from 'react'

const MoccasinsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="moccasins-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M151.92,47.87s-47.92,8.67-58.87,8.9c-8.63,.18-33.78-4.87-46.55-7.69-3.46-.77-6.01-1.37-7.07-1.66-5.02-1.37-33.66-3.19-33.66-3.19-.04-.03-.07-.05-.11-.09-1.26-1.32-3.42-10.33-2.28-16.8,1.17-6.64,5.93-19.85,5.93-19.85,13.92,10.04,28.75,7.76,36.28,5.93,7.53-1.83,15.52-11.41,15.52-11.41,0,0,22.59,14.15,31.26,20.54,8.67,6.39,21.91,13.24,36.28,13.92,14.38,.68,18.48,1.6,21.22,4.34,2.74,2.74,2.05,7.07,2.05,7.07Z" />
        <path d="M46.5,55.35c12.77,2.83,37.93,7.88,46.55,7.7,9.29-.19,45.18-6.46,55.86-8.36,1.75-.31,3.02-1.83,3.02-3.61v-3.21" />
        <path d="M46.5,49.07v12.36H2v-13.69c0-2,1.62-3.61,3.61-3.61h.04s.07,.06,.11,.09c0,0,28.64,1.83,33.66,3.19,1.07,.29,3.61,.89,7.07,1.66Z" />
        <line x1="72.08" y1="7.5" x2="68.98" y2="12.2" />
        <line x1="79.99" y1="12.71" x2="76.89" y2="17.41" />
        <line x1="87.89" y1="17.92" x2="84.8" y2="22.62" />
      </g>
    </symbol>
  </svg>
)

export default MoccasinsDefinition
