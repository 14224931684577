import trackerData from '@/core/luigiTracker'
import { Store } from '@/network/graphql.g'
import React, { useContext } from 'react'

export const useLuigiTracker = (): LuigiTrackerContextType => {
  return useContext(LuigiTrackerContext)
}

const LuigiTrackerContext = React.createContext<LuigiTrackerContextType>(null)

type LuigiTrackerContextType = {
  luigiTrackerId: string
  luigiTrackerCountryId: string
  isLuigiSearchSupported: boolean
}

type LuigiTrackerProviderProps = {
  store: Store
}

const LuigiTrackerProvider: React.FC<LuigiTrackerProviderProps> = ({
  store,
  children
}) => (
  <LuigiTrackerContext.Provider
    value={{
      luigiTrackerId: getLuigiTrackerId(store),
      luigiTrackerCountryId: getLuigiTrackerCountryId(store),
      isLuigiSearchSupported: isLuigiSearchSupported(store)
    }}
  >
    {children}
  </LuigiTrackerContext.Provider>
)

export { LuigiTrackerProvider }

const getLuigiTrackerId = (store: Store): string => {
  const luigiCountryId = getLuigiTrackerCountryId(store)
  return luigiCountryId ? trackerData.baseId + luigiCountryId : null
}

export const getLuigiTrackerCountryId = (store: Store): string => {
  return trackerData.storeId?.[store?.id]?.[store?.market?.country] ?? null
}

export const isLuigiSearchSupported = (store: Store): boolean =>
  getLuigiTrackerId(store) !== null
