import React, { useContext } from 'react'
import {
  AliaticFacebookMarkets,
  ExtRoutes,
  ExtRoutesBaseUrl,
  FactcoolFacebookMarkets,
  FrogiesFacebookMarkets,
  FrogiesInstagramMarkets,
  getInstagramCodeFactcool,
  getInstagramCodeFrogies
} from '@/core/extRoutes'
import { StoreContext } from '@/providers/storeProvider'
import { MarketCode } from '@/network/graphql.g'

const ExternalRoutesContext = React.createContext<ExtRoutes>(null)

export const useExternalRoute = (): ExtRoutes => {
  return useContext(ExternalRoutesContext)
}

const ExternalRoutesProvider: React.FC = ({ children }) => {
  const {
    countryIsoCode,
    domainName,
    isBezvasport,
    isFactcool,
    isFilatic,
    isAliatic,
    subdomainName,
    isFrogies
  } = useContext(StoreContext)

  const getFacebookUrl = (): string => {
    const facebookUrl = `${ExtRoutesBaseUrl.facebook}/${domainName}${
      isBezvasport ? '.' : ''
    }${countryIsoCode?.toLowerCase()}`

    if (isBezvasport) {
      return facebookUrl
    } else if (isFrogies) {
      if (FrogiesFacebookMarkets.includes(subdomainName as MarketCode)) {
        return facebookUrl
      }
    } else if (isAliatic) {
      if (AliaticFacebookMarkets.includes(subdomainName as MarketCode)) {
        return facebookUrl
      }
    } else {
      if (FactcoolFacebookMarkets.includes(subdomainName as MarketCode)) {
        return facebookUrl
      }
    }
    return ''
  }

  const getInstagramUrl = () => {
    const instagramUrl = `${ExtRoutesBaseUrl.instagram}/${domainName}`
    if (isFilatic) {
      return `${instagramUrl}.${subdomainName}`
    } else if (isBezvasport) {
      return instagramUrl
    } else if (isAliatic) {
      return `${instagramUrl}_com`
    } else if (isFrogies) {
      if (FrogiesInstagramMarkets.includes(subdomainName as MarketCode)) {
        return `${instagramUrl}${getInstagramCodeFrogies(subdomainName)}`
      }
    } else {
      return `${instagramUrl}${getInstagramCodeFactcool(subdomainName)}`
    }
  }

  const getPinterestUrl = () =>
    isFactcool ? `${ExtRoutesBaseUrl.pinterest}/${domainName}com` : ''

  const getTwitterUrl = () =>
    isFactcool ? `${ExtRoutesBaseUrl.twitter}/${domainName}_com` : ''

  const getLinkedInUrl = () =>
    isFactcool ? `${ExtRoutesBaseUrl.linkedIn}/${domainName}` : ''

  return (
    <ExternalRoutesContext.Provider
      value={{
        facebook: getFacebookUrl(),
        linkedIn: getLinkedInUrl(),
        twitter: getTwitterUrl(),
        pinterest: getPinterestUrl(),
        instagram: getInstagramUrl()
      }}
    >
      {children}
    </ExternalRoutesContext.Provider>
  )
}

export default ExternalRoutesProvider
