import * as jose from 'jose'
import { isDevelopment } from '../utils'
import { Session } from './session'

// added 30s for development and 1h for production to eliminate client/server time difference
export const isAccessTokenExpired = (expiration: number): boolean =>
  (expiration ?? 0) < Date.now() + (isDevelopment ? 30000 : 3600000)

export const handleResponse = (
  response: Response
): Promise<{ response: Response; data: unknown }> =>
  new Promise((resolve, reject) => {
    response
      .json()
      .then((data) => {
        if (response.status === 200) {
          resolve({ response, data })
        } else {
          reject({ response, data })
        }
      })
      .catch((e) => {
        reject({ response, data: { error: e } })
      })
  })

export const handleAuthResponse = (response: Response): Promise<Session> =>
  new Promise((resolve, reject) =>
    handleResponse(response)
      .then(async ({ data }) => {
        const session = await createSession(
          data['access_token'],
          data['refresh_token'],
          data['expires_in']
        )
        resolve(session)
      })
      .catch((e) => {
        reject(e)
      })
  )

export const createEmptySession = (): Session => ({
  isLoggedIn: false
})

const createSession = async (
  accessToken: string,
  refreshToken: string,
  expiresIn: number
): Promise<Session> => {
  if (!accessToken || !refreshToken) {
    return createEmptySession()
  }
  let firstname: string = null
  let lastname: string = null
  let facebookRegistration = false
  try {
    const data = await jose.decodeJwt(accessToken)
    firstname = (data?.firstName ?? '') as string
    lastname = (data?.lastName ?? '') as string
    facebookRegistration = (data?.newFacebookUser ?? false) as boolean
    // eslint-disable-next-line no-empty
  } catch {}

  return {
    isLoggedIn: true,
    accessToken,
    refreshToken,
    facebookRegistration,
    firstname,
    lastname,
    expiration: Date.now() + expiresIn * 1000
  }
}
