import { FC } from 'react'

const UnderwearMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="underwear-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="10.77" y="2" width="132.49" height="14.38" />
        <path d="M51.39,16.38s-6.99,90.87,21.23,95.78" />
        <path d="M102.61,16.38s6.99,90.87-21.23,95.78" />
        <path d="M152,130.76l-8.77-114.37H10.77L2,130.76H72.61v-22.63c0-2.42,1.96-4.39,4.39-4.39h0c2.42,0,4.39,1.96,4.39,4.39v22.63h70.61Z" />
      </g>
    </symbol>
  </svg>
)

export default UnderwearMenDefinition
