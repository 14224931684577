import { FC } from 'react'

const BrasFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="bras-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M11.46,67.35V8.69c0-1.26,.77-2.4,1.94-2.88l4.36-1.78c.28-.11,.58,.09,.58,.39V53.41" />
        <path d="M18.34,53.41s-9.81,19.61-9.82,19.63c-7.88,15.76-4.83,34.41,4.19,48.84,6.24,9.98,17.73,16.58,29.62,15.75,12.3-.85,23.9-11.34,30.48-21.21,6.88-10.32,6.16-10.14,6.16-10.14,0,0-4.53-9.58-22.63-25.52-18.11-15.94-25.35-11.6-38-27.35Z" />
        <polyline points="8.47 113.73 8.47 134.34 26.45 134.34" />
        <path d="M146.54,67.35V8.69c0-1.26-.77-2.4-1.94-2.88l-4.36-1.78c-.28-.11-.58,.09-.58,.39V53.41" />
        <path d="M139.66,53.41s9.81,19.61,9.82,19.63c7.88,15.76,4.83,34.41-4.19,48.84-6.24,9.98-17.73,16.58-29.62,15.75-12.3-.85-23.9-11.34-30.48-21.21-6.88-10.32-6.16-10.14-6.16-10.14,0,0,4.53-9.58,22.63-25.52,18.11-15.94,25.35-11.6,38-27.35Z" />
        <polyline points="149.53 113.73 149.53 134.34 131.55 134.34" />
        <line x1="64.94" y1="125.91" x2="93.06" y2="125.91" />
      </g>
    </symbol>
  </svg>
)

export default BrasFrogiesDefinition
