import { fetchData } from '@/core/fetchData'
import { Cookie } from 'next-cookie'
import { removeCookie } from '../graphQlCookieManager'
import { handleResponse } from './helpers'
import { Session, sessionOptions } from './session'

export const signInByCredentials = (
  username: string,
  password: string
): Promise<Session> => signIn(JSON.stringify({ username, password }))

export const signInByProvider = (
  accessToken: string,
  grant_type: 'google' | 'facebook'
): Promise<Session> => {
  const body = JSON.stringify({
    accessToken: accessToken,
    grant_type: grant_type
  })

  return signIn(body)
}

const signIn = (body: string): Promise<Session> =>
  new Promise((resolve, reject) => {
    fetchData('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
      .then((response) => handleResponse(response))
      .then(({ data }) => {
        resolve(data as Session)
      })
      .catch((e) => {
        reject(e)
      })
  })

export const signOutDirect = (cookie: Cookie): void => {
  if (cookie) {
    removeCookie(sessionOptions.cookieName, cookie)
  }
}

export const signOut = (baseUrl = ''): Promise<void> =>
  new Promise((resolve, reject) => {
    fetchData(`${baseUrl}/api/auth/logout`)
      .then((response) => handleResponse(response))
      .then(() => {
        resolve()
      })
      .catch((e) => {
        reject(e)
      })
  })
