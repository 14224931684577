import useTranslation from 'next-translate/useTranslation'
import Link from '@/components/atoms/link'
import styled from 'styled-components'
import { Flex } from '@/components/atoms/Grid'
import { H1 } from '@/components/atoms/typography'
import { validFor } from '@/theme/validFor'
import { SpecialLandingPageButton } from '@/components/organisms/specialLandingPage/specialLandingPageSharedComponents'
import { FC } from 'react'

type SpecialLandingPageBannerContentProps = {
  url: string
  title: string
}

export const SpecialLandingPageBannerContent: FC<SpecialLandingPageBannerContentProps> =
  ({ url, title }) => {
    const { t } = useTranslation('common')
    return url ? (
      <Link href={url}>
        <SpecialLandingPageWrapper>
          {title && <SpecialLandingPageTitle>{title}</SpecialLandingPageTitle>}
          <SpecialLandingPageButton>
            {t('SpecialLandingPage.select')}
          </SpecialLandingPageButton>
        </SpecialLandingPageWrapper>
      </Link>
    ) : (
      <SpecialLandingPageWrapper>
        {title && <SpecialLandingPageTitle>{title}</SpecialLandingPageTitle>}
      </SpecialLandingPageWrapper>
    )
  }

const SpecialLandingPageWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
`

const SpecialLandingPageTitle = styled(H1)`
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  color: ${({ theme }) => theme.colors.background};
  ${validFor.mobile`
    font-size: 22px;
  `}
`
