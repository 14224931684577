import { FC } from 'react'

const DressesFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="dresses-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <line x1="121.8" y1="92.39" x2="133.18" y2="88.12" />
        <path d="M89.21,4l11.43,3.54c2.21,.69,4.04,2.26,5.06,4.34l30,61.79c1.42,3.02,1.17,6.57-.67,9.36l-1.9,2.87-12.75,4.79-3.32-.91c-3.23-.89-5.76-3.38-6.69-6.59l-13.63-45.49" />
        <line x1="18.81" y1="92.39" x2="7.42" y2="88.12" />
        <path d="M43.88,37.69l-13.63,45.49c-.93,3.21-3.46,5.71-6.69,6.59l-3.32,.91-12.75-4.79-1.9-2.87c-1.84-2.79-2.1-6.33-.67-9.36L34.91,11.88c1.01-2.08,2.84-3.65,5.06-4.34l11.43-3.54s2.82,34.09,37.82,70.66" />
        <path d="M91.11,76.99s2.15-25.35,5.62-39.3" />
        <path d="M44.09,37.64c3.96,15.8,5.33,39.35,5.33,39.35" />
        <path d="M91.2,76.73H49.65l-26.1,70.39c0,2.97,25.28,6.88,46.88,6.88s46.88-3.91,46.88-6.88l-26.1-70.39Z" />
        <path d="M89.21,4s-4.01,22.55-18.79,45.1" />
        <line x1="51.39" y1="4" x2="89.21" y2="4" />
      </g>
    </symbol>
  </svg>
)

export default DressesFrogiesDefinition
