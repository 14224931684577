import { FC } from 'react'

const HandbagsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="handbags-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="136.46 129.42 17.54 129.42 34.78 41.51 119.21 41.51 136.46 129.42" />
        <polyline points="34.78 41.51 2 57.24 17.54 129.42" />
        <polyline points="119.22 41.51 152 57.24 136.46 129.42" />
        <path d="M49.07,57.37V19.25c0-9.49,7.76-17.25,17.24-17.25h21.36c9.48,0,17.24,7.76,17.24,17.25V57.37" />
      </g>
    </symbol>
  </svg>
)

export default HandbagsDefinition
