import { createGlobalStyle } from 'styled-components'

type HideChatbotStylesProps = {
  disableDaktela: boolean
  disableAmio: boolean
}

export const HideChatbotStyle = createGlobalStyle<HideChatbotStylesProps>`
#daktela-web .dw-button,
#daktela-web .dw-button-icon,
#daktela-web .dw-button-state {
   ${({ disableDaktela }) => disableDaktela && 'display: none;'}
}

#_amio_webchat_container {
  ${({ disableAmio }) => disableAmio && 'display: none;'}
}
`
