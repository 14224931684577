import { FC } from 'react'
import { Box } from '@/components/atoms/Grid'
import ResponsiveImage from '@/components/atoms/responsiveImage'
import {
  applyImagePolicy,
  getImageMode,
  ImageSizes
} from '@/utils/imageExtension'
import { useDomain } from '@/providers/storeProvider'

type ProductImageProps = {
  imageUrl: string
  dimension: number | { desktop: number; tablet: number; mobile: number }
} & Rest

const ProductImage: FC<ProductImageProps> = ({
  dimension,
  imageUrl,
  ...rest
}) => {
  const { subdomainName } = useDomain()
  return (
    <Box
      width={dimension}
      height={dimension}
      mr={22}
      flexGrow={0}
      flexShrink={0}
      flexBasis={dimension}
      {...rest}
    >
      <ResponsiveImage
        width={91}
        height={91}
        imageUrl={applyImagePolicy(imageUrl, {
          size: ImageSizes['100x100'],
          mode: getImageMode(subdomainName)
        })}
      />
    </Box>
  )
}

export default ProductImage
