import { FC } from 'react'

const AnkleshoesMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="ankleshoes-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8.77,6.54c-1.88,.19-3.35,1.69-3.49,3.57-.5,6.61-1.62,21.21-1.84,23.97-.19,2.24-.84,12.39-1.3,19.72-.29,4.52,2.86,8.52,7.31,9.31,6.74,1.2,17.24,2.84,29.1,3.88,20.47,1.79,113.41,1.5,113.41,1.5,0,0,1.2-9.87-9.56-12.86-10.76-2.99-23.61-2.09-32.87-7.78-8.03-4.93-34.94-37.97-41.74-45.65-1.18-1.33-13.12,4.77-13.12,4.77-9.69,4.23-20.48,5.27-30.81,2.96l-15.08-3.38Z" />
        <path d="M151.96,66.87l.03,5.06c0,1.3-1.11,2.46-2.79,2.93-23.71,6.68-120.64,1.95-138.69,.72-3.19-.22-7.44-1.87-7.86-4.41-1.03-6.34-.51-17.37-.51-17.37" />
        <line x1="64.37" y1="14.82" x2="74.56" y2="13.4" />
        <line x1="72.02" y1="23.27" x2="82.69" y2="21.79" />
        <line x1="81.01" y1="31.31" x2="90.88" y2="29.94" />
        <line x1="87.49" y1="38.79" x2="98.27" y2="37.29" />
      </g>
    </symbol>
  </svg>
)

export default AnkleshoesMenDefinition
