import { FC } from 'react'

const ThermalWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="thermal-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2,7.84V141.2c0,5.96,4.83,10.8,10.8,10.8h0c5.96,0,10.8-4.83,10.8-10.8V61.13c0-3.06,2.48-5.54,5.54-5.54h0c3.06,0,5.54,2.48,5.54,5.54v80.07c0,5.96,4.83,10.8,10.8,10.8h0c5.96,0,10.8-4.83,10.8-10.8V7.84H2Z" />
        <rect x="2" y="2" width="54.28" height="5.84" />
        <line x1="29.14" y1="2" x2="29.14" y2="55.59" />
      </g>
    </symbol>
  </svg>
)

export default ThermalWomenDefinition
