import { ParsedUrlQuery } from 'querystring'
import { FilterModel } from 'types/filterModel'
import { FilterQueryItem } from 'types/filterQueryItem'
import { ActiveFiltersTypeAliasContext } from '@/providers/filterProvider/filterProviderTypes'
import { FilterInput, FilterType } from '@/network/graphql.g'

type ParseFiltersFromQueryType = {
  manufacturers?: string[]
  colors?: string[]
  patterns?: string[]
  materials?: string[]
  inStock?: boolean
  inSale?: boolean
  sizes?: string[]
  genders?: string[]
  categories?: string[]
  sort?: string
  price?: { min: number; max: number }
  news?: boolean
  tags?: { id: string; items: string[] }[]
  campaignType?: string[]
  campaignDesignation?: string[]
  search?: string
}

export const parseFilterQueryIdsFromQuery = (
  query: ParsedUrlQuery
): ParseFiltersFromQueryType => {
  const priceMinFloat = parseFloat(query[FilterQueryItem.PRICE_MIN] as string)
  const priceMaxFloat = parseFloat(query[FilterQueryItem.PRICE_MAX] as string)
  const priceMin = isNaN(priceMinFloat) ? null : priceMinFloat
  const priceMax = isNaN(priceMaxFloat) ? null : priceMaxFloat

  const tagRe = new RegExp(
    FilterQueryItem.TAG.replace('[', '\\[')
      .replace(']', '\\]')
      .replace('{tagId}', '([a-z0-9]+)'),
    'i'
  )
  const tags: { id: string; items: string[] }[] = []
  for (const key in query) {
    const match = key.match(tagRe)
    if (match) {
      const tagId = match[1]
      tags.push({
        id: tagId,
        items: convertValueToArray(query[key])
      })
    }
  }

  return {
    manufacturers: convertValueToArray(query[FilterQueryItem.MANUFACTURER]),
    colors: convertValueToArray(query[FilterQueryItem.COLOR]),
    patterns: convertValueToArray(query[FilterQueryItem.PATTERN]),
    materials: convertValueToArray(query[FilterQueryItem.MATERIAL]),
    inStock: (query[FilterQueryItem.STOCK] as string) === '1' ? true : null,
    inSale: (query[FilterQueryItem.IN_SALE] as string) === '1' ? true : null,
    news: (query[FilterQueryItem.NEWS] as string) === '1' ? true : null,
    sizes: convertValueToArray(query[FilterQueryItem.SIZE]),
    genders: convertValueToArray(query[FilterQueryItem.GENDER]),
    categories: convertValueToArray(query[FilterQueryItem.CATEGORY]),
    tags: tags,
    sort: query[FilterQueryItem.SORT] as string,
    price:
      priceMin || priceMax
        ? {
            min: priceMin,
            max: priceMax
          }
        : null,
    campaignType: convertValueToArray(query[FilterQueryItem.CAMPAIGN_TYPE]),
    campaignDesignation: convertValueToArray(
      query[FilterQueryItem.CAMPAIGN_DESIGNATION]
    ),
    search: query[FilterQueryItem.SEARCH] as string
  }
}

export const parseValidFilters = (
  queryFilters: ParseFiltersFromQueryType,
  filter: FilterModel
): ActiveFiltersTypeAliasContext => {
  const validFilters: ActiveFiltersTypeAliasContext = {}
  const manufacturers = filter.manufacturers?.flatMap(({ items }) => items)
  validFilters.manufacturers = manufacturers
    ? manufacturers
        .filter((value) => queryFilters.manufacturers?.includes(value.id))
        .map(({ title, id, englishName }) => ({
          title,
          id,
          englishName
        })) ?? []
    : null
  const colors = filter.colors?.flatMap(({ items }) => items)
  validFilters.colors = colors
    ? colors
        .filter((value) => queryFilters.colors?.includes(value.id))
        .map(({ title, id, englishName }) => ({
          title,
          id,
          englishName
        }))
    : null
  const patterns = filter.patterns?.flatMap(({ items }) => items)
  validFilters.patterns = patterns
    ? patterns
        .filter((value) => queryFilters.patterns?.includes(value.id))
        .map(({ title, id, englishName }) => ({
          title,
          id,
          englishName
        }))
    : null
  const materials = filter.materials?.flatMap(({ items }) => items)
  validFilters.materials = materials
    ? materials
        .filter((value) => queryFilters.materials?.includes(value.id))
        .map(({ title, id, englishName }) => ({
          title,
          id,
          englishName
        }))
    : null
  const sizes = filter.sizes?.flatMap(({ items }) => items)
  validFilters.sizes = sizes
    ? sizes
        .filter((value) => queryFilters.sizes?.includes(value.id))
        .map(({ value, id, englishName }) => ({
          title: value,
          id,
          englishName
        }))
    : null
  const genders = filter.genders?.flatMap(({ items }) => items)
  validFilters.genders = genders
    ? genders
        .filter((value) => queryFilters.genders?.includes(value.id))
        .map(({ title, id }) => ({
          title,
          id,
          englishName: undefined
        }))
    : null
  const categories = filter.categories?.flatMap(({ items }) => items)
  validFilters.categories = categories
    ? categories
        .filter((value) => queryFilters.categories?.includes(value.id))
        .map(({ value, id, englishName }) => ({
          title: value,
          id,
          englishName
        }))
    : null

  validFilters.tags = []
  if (filter.tags) {
    filter.tags.forEach((tag) => {
      const tags = tag.items
      const queryFiltersTags = queryFilters.tags?.find(
        ({ id: tagId }) => tag.id === tagId
      )?.items
      const validFiltersTags = tags
        ? tags
            .filter((value) => queryFiltersTags?.includes(value.id))
            .map(({ title, id, englishName }) => ({
              title,
              id,
              englishName
            }))
        : null
      validFilters.tags.push({
        id: tag.id,
        title: tag.title,
        items: validFiltersTags
      })
    })
  }
  const sortType = filter.sortTypes?.find(
    (value) => value.id === queryFilters.sort
  )
  validFilters.sort = sortType
    ? { title: sortType.title, id: sortType.id }
    : null
  validFilters.price = {
    query: queryFilters.price ? { ...queryFilters.price } : null,
    original: {
      min: filter.priceRange?.from,
      max: filter.priceRange?.to
    }
  }
  validFilters.inStock = queryFilters.inStock
  validFilters.inSale = queryFilters.inSale
  validFilters.news = queryFilters.news

  return validFilters
}

const convertValueToArray = (value?: string | string[]): string[] =>
  typeof value === 'string' ? [value] : value

export const makeFilterInputFromQuery = (
  query: ParsedUrlQuery
): FilterInput[] => {
  const activeFilters = parseFilterQueryIdsFromQuery(query)
  return makeFilterInput(activeFilters)
}

const makeFilterInput = (filters: ParseFiltersFromQueryType): FilterInput[] => {
  const basicFilters: FilterInput[] = [
    {
      filterType: FilterType.Price,
      priceRange: {
        from: filters?.price?.min ?? null,
        to: filters?.price?.max ?? null
      }
    },
    {
      filterType: FilterType.Sort,
      filterIDs: filters?.sort ? [filters?.sort] : []
    },
    {
      filterType: FilterType.Sizes,
      filterIDs: filters?.sizes
    },
    {
      filterType: FilterType.Colors,
      filterIDs: filters?.colors
    },
    {
      filterType: FilterType.Pattern,
      filterIDs: filters?.patterns
    },
    {
      filterType: FilterType.Material,
      filterIDs: filters?.materials
    },
    {
      filterType: FilterType.Manufacturer,
      filterIDs: filters?.manufacturers
    },
    {
      filterType: FilterType.Gender,
      filterIDs: filters?.genders
    },
    {
      filterType: FilterType.Category,
      filterIDs: filters?.categories
    },
    {
      filterType: FilterType.InStock,
      filterIDs: filters?.inStock ? ['1'] : []
    },
    {
      filterType: FilterType.InSale,
      filterIDs: filters?.inSale ? ['1'] : []
    },
    {
      filterType: FilterType.NewProducts,
      filterIDs: filters?.news ? ['1'] : []
    },
    {
      filterType: FilterType.Search,
      filterIDs: filters?.search ? [filters?.search] : []
    }
  ]
  const tagFilters: FilterInput[] = filters.tags?.map(({ id, items }) => ({
    filterType: FilterType.Tags,
    filterGroupName: FilterQueryItem.TAG.replace('{tagId}', id),
    filterIDs: items
  }))
  return [...basicFilters, ...tagFilters].filter(
    ({ filterIDs, priceRange }) =>
      (filterIDs?.length ?? 0) > 0 || priceRange?.from || priceRange?.to
  )
}
