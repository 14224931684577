import { FC } from 'react'

const ShirtsMenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="shirts-men-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M105.33,7.69l18.95,8.98c4.08,1.93,6.99,5.7,7.82,10.14l21.25,112.87-20.47,5.72-15.95-82.5v89.12H38.42V62.88l-15.95,82.5-20.47-5.72L23.25,26.8c.84-4.44,3.74-8.21,7.82-10.14L50.03,7.69" />
        <polygon points="52.69 2 77.7 27.91 61.07 37.66 50.03 7.69 52.69 2" />
        <polygon points="102.7 2 77.7 27.91 94.32 37.66 105.36 7.69 102.7 2" />
        <line x1="52.69" y1="2" x2="102.7" y2="2" />
        <line x1="77.7" y1="27.91" x2="77.7" y2="152" />
      </g>
    </symbol>
  </svg>
)

export default ShirtsMenDefinition
