import { FC } from 'react'

const LittleonesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="littleones-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M48.92,2c0,12.86,10.16,23.28,22.7,23.28S94.32,14.86,94.32,2" />
        <path d="M109.59,102.29l3.1,25.52s-19.94-4.92-29.3,13.88C80.56,147.37,77.9,152,77.9,152H65.34s-2.66-4.63-5.49-10.31c-9.36-18.8-29.3-13.88-29.3-13.88l3.1-25.52,1.49-50.43L12.67,58.31,2,33.08S25.62,11.85,28.61,10.55L48.29,2H95v.07l19.69,8.55c3,1.3,26.6,22.53,26.6,22.53L130.61,58.38l-22.48-6.45Z" />
        <path d="M65.34,152a51.7,51.7,0,0,1-34.79-24.19" />
        <path d="M77.9,152a51.7,51.7,0,0,0,34.79-24.19" />
        <line x1="59.85" y1="141.68" x2="83.39" y2="141.68" />
        <path d="M95,129.82V58.44A44.42,44.42,0,0,0,78.75,24.11h0" />
      </g>
    </symbol>
  </svg>
)

export default LittleonesDefinition
