import { FC } from 'react'

const SlippersDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="slippers-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M152,36.63v.08c0,2.07-1.59,3.41-3.5,4.19-11.36,4.62-25.26,4.59-41.32,5.1-22.68,.73-48.41-1.2-67.98-1.78-15.32-.46-27.36-1.06-32.48-1.32-1.65-.08-3.07-1.18-3.57-2.76-.47-1.47-.73-2.26-1.15-4.54,0,0,114.39,8.54,150,1.03Z" />
        <path d="M70.87,39.17L81.06,2s69.2,7.54,70.94,34.7" />
      </g>
    </symbol>
  </svg>
)

export default SlippersDefinition
