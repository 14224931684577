import { FC } from 'react'
import { SvgProps } from './svgProps'

const ExclamationMarkIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 14}
    height={height || 14}
    viewBox="0 0 301.691 301.691"
    data-cy="closeIcon"
  >
    <g fill={color || '#FFF'}>
      <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0 	" />
      <rect x="130.563" y="261.168" width="40.525" height="40.523" />
    </g>
  </svg>
)

export default ExclamationMarkIcon
