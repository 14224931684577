import { MHubBannerProps } from '@/providers/mhub/useMhubTrackBanner'
import { useMhub } from '@/providers/mhubProvider'
import useInView from 'react-cool-inview'

type TrackBanners = {
  observe: (element?: HTMLElement) => void
  pushShowBanners: (banner: MHubBannerProps) => void
}

export const useTrackBanners = (banner: MHubBannerProps): TrackBanners => {
  const { pushImpressBanners, pushShowBanners } = useMhub()
  const { observe } = useInView({
    unobserveOnEnter: true,
    threshold: [0.5],
    onEnter: () => {
      pushImpressBanners(banner)
    }
  })
  return {
    observe,
    pushShowBanners
  }
}
