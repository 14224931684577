import { FC } from 'react'
import DressesDefinition from '@/components/atoms/svg/iconsDefinitions/DressesDefinition'
import HeartIconDefinition from '@/components/atoms/svg/iconsDefinitions/HeartIconDefinition'
import FilledHeartIconDefinition from '@/components/atoms/svg/iconsDefinitions/FilledHeartIconDefinition'
import BlazersWomenDefinition from '@/components/atoms/svg/iconsDefinitions/BlazersWomenDefinition'
import BlousesDefinition from '@/components/atoms/svg/iconsDefinitions/BlousesDefinition'
import BodyDefinition from '@/components/atoms/svg/iconsDefinitions/BodyDefinition'
import JacketsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/JacketsWomenDefinition'
import JeansWomenDefinition from '@/components/atoms/svg/iconsDefinitions/JeansWomenDefinition'
import JumpSuitsDefinition from '@/components/atoms/svg/iconsDefinitions/JumpSuitsDefinition'
import LegginsDefinition from '@/components/atoms/svg/iconsDefinitions/LegginsDefintion'
import MaternityDefinition from '@/components/atoms/svg/iconsDefinitions/MaternityDefinition'
import PantsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/PantsWomenDefinition'
import PyjamasWomenDefinition from '@/components/atoms/svg/iconsDefinitions/PyjamasWomenDefinition'
import SetsDefinition from '@/components/atoms/svg/iconsDefinitions/SetsDefintion'
import ShirtsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/ShirtsWomenDefinition'
import ShortsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/ShortsWomenDefinition'
import SkirtsDefinition from '@/components/atoms/svg/iconsDefinitions/SkirtsDefinition'
import SweatersWomenDefinition from '@/components/atoms/svg/iconsDefinitions/SweatersWomenDefinition'
import SweartshirtsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/SweartshirtsWomenDefinition'
import SwimsuitsDefinition from '@/components/atoms/svg/iconsDefinitions/SwimsuitsDefinition'
import ThermalWomenDefinition from '@/components/atoms/svg/iconsDefinitions/ThermalWomenDefinition'
import TopsDefinition from '@/components/atoms/svg/iconsDefinitions/TopsDefinition'
import TracksuitWomenDefinition from '@/components/atoms/svg/iconsDefinitions/TracksuitWomenDefinition'
import TShirtsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/TShirtsWomenDefinition'
import UnderwearWomenDefinition from '@/components/atoms/svg/iconsDefinitions/UnderwearWomenDefinition'
import BlazersMenDefinition from '@/components/atoms/svg/iconsDefinitions/BlazersMenDefinition'
import JacketsMenDefinition from '@/components/atoms/svg/iconsDefinitions/JacketsMenDefinition'
import JeansMenDefinition from '@/components/atoms/svg/iconsDefinitions/JeansMenDefinition'
import LittleonesDefinition from '@/components/atoms/svg/iconsDefinitions/LittleonesDefinition'
import MultipacksDefinition from '@/components/atoms/svg/iconsDefinitions/MultipacksDefinition'
import PantsMenDefinition from '@/components/atoms/svg/iconsDefinitions/PantsMenDefintion'
import PyjamasMenDefinition from '@/components/atoms/svg/iconsDefinitions/PyjamasMenDefinition'
import ShirtsMenDefinition from '@/components/atoms/svg/iconsDefinitions/ShirtsMenDefinition'
import ShortsMenDefinition from '@/components/atoms/svg/iconsDefinitions/ShortsMenDefinition'
import SweatersMenDefinition from '@/components/atoms/svg/iconsDefinitions/SweatersMenDefinition'
import SweatshirtsMenDefinition from '@/components/atoms/svg/iconsDefinitions/SweatshirtsMenDefinition'
import SwimwearDefinition from '@/components/atoms/svg/iconsDefinitions/SwimwearDefinition'
import ThermalMenDefinition from '@/components/atoms/svg/iconsDefinitions/ThermalMenDefinition'
import TracksuitMenDefinition from '@/components/atoms/svg/iconsDefinitions/TracksuitMenDefinition'
import TshirtsMenDefinition from '@/components/atoms/svg/iconsDefinitions/TshirtsMenDefinition'
import UnderwearMenDefinition from '@/components/atoms/svg/iconsDefinitions/UnderwearMenDefinition'
import AnkleshoesMenDefinition from '@/components/atoms/svg/iconsDefinitions/AnkleshoesMenDefinition'
import AnkleshoesWomenDefinition from '@/components/atoms/svg/iconsDefinitions/AnkleshoesWomenDefinition'
import BalerinasDefinition from '@/components/atoms/svg/iconsDefinitions/BalerinasDefinition'
import BootsDefinition from '@/components/atoms/svg/iconsDefinitions/BootsDefinition'
import CourtshoesDefinition from '@/components/atoms/svg/iconsDefinitions/CourtshoesDefinition'
import EspadrillesDefinition from '@/components/atoms/svg/iconsDefinitions/EspadrillesDefinition'
import FootballbootsDefinition from '@/components/atoms/svg/iconsDefinitions/FootballbootsDefinition'
import HikingshoesDefinition from '@/components/atoms/svg/iconsDefinitions/HikingshoesDefinition'
import MoccasinsDefinition from '@/components/atoms/svg/iconsDefinitions/MoccasinsDefinition'
import RunningshoesDefinition from '@/components/atoms/svg/iconsDefinitions/RunningshoesDefinition'
import SandalsMenDefinition from '@/components/atoms/svg/iconsDefinitions/SandalsMenDefinition'
import SandalsWomenDefinition from '@/components/atoms/svg/iconsDefinitions/SandalsWomenDefinition'
import SlidersDefinition from '@/components/atoms/svg/iconsDefinitions/SlidersDefinition'
import SliponDefinition from '@/components/atoms/svg/iconsDefinitions/SliponDefinition'
import SlippersDefinition from '@/components/atoms/svg/iconsDefinitions/SlippersDefinition'
import SneakersDefinition from '@/components/atoms/svg/iconsDefinitions/SneakersDefinition'
import WellingtonsDefinition from '@/components/atoms/svg/iconsDefinitions/WellingtonsDefinition'
import WintershoesDefinition from '@/components/atoms/svg/iconsDefinitions/WintershoesDefinition'
import AccessoriesDefinition from '@/components/atoms/svg/iconsDefinitions/AccessoriesDefinition'
import BaseballcapsDefinition from '@/components/atoms/svg/iconsDefinitions/BaseballcapsDefinition'
import BeltsDefinition from '@/components/atoms/svg/iconsDefinitions/BeltsDefinition'
import CapsDefinition from '@/components/atoms/svg/iconsDefinitions/CapsDefinition'
import GlovesDefinition from '@/components/atoms/svg/iconsDefinitions/GlovesDefinition'
import HandbagsDefinition from '@/components/atoms/svg/iconsDefinitions/HandbagsDefinition'
import HappysocksDefinition from '@/components/atoms/svg/iconsDefinitions/HappysocksDefinition'
import HatsDefinition from '@/components/atoms/svg/iconsDefinitions/HatsDefinition'
import JewelleryDefinition from '@/components/atoms/svg/iconsDefinitions/JewelleryDefinition'
import ShawlsDefinition from '@/components/atoms/svg/iconsDefinitions/ShawlsDefinition'
import SunglassesDefinition from '@/components/atoms/svg/iconsDefinitions/SunglassesDefinition'
import TiesDefinition from '@/components/atoms/svg/iconsDefinitions/TiesDefinition'
import TowelsDefinition from '@/components/atoms/svg/iconsDefinitions/TowelsDefinition'
import WalletsDefinition from '@/components/atoms/svg/iconsDefinitions/WalletsDefinition'
import WatchesDefinition from '@/components/atoms/svg/iconsDefinitions/WatchesDefinition'
import UserIconDefinition from '@/components/atoms/svg/iconsDefinitions/UserIconDefinition'
import WishlistIconDefinition from '@/components/atoms/svg/iconsDefinitions/WishlistIconDefinition'
import ArrowDownIconDefinition from '@/components/atoms/svg/iconsDefinitions/ArrowDownIconDefinition'
import FilledHeartIconListingDefinition from '@/components/atoms/svg/iconsDefinitions/FilledHeartIconListingDefinition'

const IconsDefinitionList: FC = () => {
  return (
    <>
      <DressesDefinition />
      <HeartIconDefinition />
      <FilledHeartIconDefinition />
      <FilledHeartIconListingDefinition />
      <BlazersWomenDefinition />
      <BlousesDefinition />
      <BodyDefinition />
      <JacketsWomenDefinition />
      <JeansWomenDefinition />
      <JumpSuitsDefinition />
      <LegginsDefinition />
      <MaternityDefinition />
      <PantsWomenDefinition />
      <PyjamasWomenDefinition />
      <SetsDefinition />
      <ShirtsWomenDefinition />
      <ShortsWomenDefinition />
      <SkirtsDefinition />
      <SweatersWomenDefinition />
      <SweartshirtsWomenDefinition />
      <SwimsuitsDefinition />
      <ThermalWomenDefinition />
      <TopsDefinition />
      <TracksuitWomenDefinition />
      <TShirtsWomenDefinition />
      <UnderwearWomenDefinition />
      <BlazersMenDefinition />
      <JacketsMenDefinition />
      <JeansMenDefinition />
      <LittleonesDefinition />
      <MultipacksDefinition />
      <PantsMenDefinition />
      <PyjamasMenDefinition />
      <ShirtsMenDefinition />
      <ShortsMenDefinition />
      <SweatersMenDefinition />
      <SweatshirtsMenDefinition />
      <SwimwearDefinition />
      <ThermalMenDefinition />
      <TracksuitMenDefinition />
      <TshirtsMenDefinition />
      <UnderwearMenDefinition />
      <AnkleshoesMenDefinition />
      <AnkleshoesWomenDefinition />
      <BalerinasDefinition />
      <BootsDefinition />
      <CourtshoesDefinition />
      <EspadrillesDefinition />
      <FootballbootsDefinition />
      <HikingshoesDefinition />
      <MoccasinsDefinition />
      <RunningshoesDefinition />
      <SandalsMenDefinition />
      <SandalsWomenDefinition />
      <SlidersDefinition />
      <SliponDefinition />
      <SlippersDefinition />
      <SneakersDefinition />
      <WellingtonsDefinition />
      <WintershoesDefinition />
      <AccessoriesDefinition />
      <BaseballcapsDefinition />
      <BeltsDefinition />
      <CapsDefinition />
      <GlovesDefinition />
      <HandbagsDefinition />
      <HappysocksDefinition />
      <HatsDefinition />
      <JewelleryDefinition />
      <ShawlsDefinition />
      <SunglassesDefinition />
      <TiesDefinition />
      <TowelsDefinition />
      <WalletsDefinition />
      <WatchesDefinition />
      <UserIconDefinition />
      <WishlistIconDefinition />
      <ArrowDownIconDefinition />
    </>
  )
}

export default IconsDefinitionList
