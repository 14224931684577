import { applyImagePolicy } from '@/utils/imageExtension'
import styled from 'styled-components'
import { margin, maxWidth, maxHeight } from 'styled-system'
import { FC } from 'react'

type MenuBadgeProps = {
  imageUrl: string
} & Rest

const MenuBadge: FC<MenuBadgeProps> = ({ imageUrl, ...rest }) => (
  <StyledImg src={applyImagePolicy(imageUrl)} {...rest} />
)

export default MenuBadge

const StyledImg = styled.img`
  ${maxHeight}
  ${maxWidth}
  ${margin}
`
