import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Customer } from '@/network/graphql.g'
import { notifySuccess } from '@/core/toast'
import useTranslation from 'next-translate/useTranslation'
import useSWR from 'swr'
import { Session } from '@/core/auth/session'
import { Mutex, withTimeout } from 'async-mutex'
import { handleResponse, isAccessTokenExpired } from '@/core/auth/helpers'
import { fetchData } from '@/core/fetchData'
import { useCookie } from 'next-cookie'
import * as Sentry from '@sentry/nextjs'
import { FetchError } from '@/core/auth/fetchError'
import { isSSR } from '@/core/utils'
import objectHash from '@/core/crypto/objectHash'

// type will be User after api implemented
const UserContext = React.createContext<UserContextType>(null)
type UserContextType = {
  user: Customer
  isLoggedIn: boolean
  isLoggedInBySession: () => boolean
  isEmployee: boolean
  getSession: () => Promise<Session>
  updateUser: (user: Customer, notify?: boolean) => void
  getUserChecksum: () => string
  canManageProducts: boolean
}

export const useUser = (): UserContextType => useContext(UserContext)

type UserProviderProps = { user: Customer; session: Session }

const fetcher = (url: string): Promise<Session> =>
  new Promise((resolve, reject) => {
    const transaction = Sentry.startTransaction({
      name: 'getSession',
      tags: {
        host: isSSR ? globalThis.HOST : window.location.host
      }
    })
    fetchData(url)
      .then((r) => handleResponse(r))
      .then(({ data }) => {
        resolve(data as Session)
        transaction.setTag('error', false)
      })
      .catch((e) => {
        Sentry.withScope((scope) => {
          if (e instanceof FetchError) {
            const { errorText, ...error } = e.toJSON() as { errorText: string }
            scope.setExtra('errorName', 'FetchError')
            scope.setExtra('error', {
              ...error,
              ...{ errorText: errorText?.substring(0, 500) }
            })
          } else {
            scope.setExtra('errorName', 'Error')
            scope.setExtra('error', e)
          }

          if (
            'data' in e &&
            'error' in e.data &&
            e.data.error instanceof Error
          ) {
            scope.setExtra('errorType', '{ data, response }')
            Sentry.captureException(e.data.error)
          } else if (e instanceof Error) {
            Sentry.captureException(e)
          }
        })
        reject(e)
        transaction.setTag('error', true)
      })
      .finally(() => {
        transaction.finish()
      })
  })

const getGlobal = () => {
  if (typeof globalThis !== 'undefined') {
    return globalThis
  }
  if (typeof self !== 'undefined') {
    return self
  }
  if (typeof window !== 'undefined') {
    return window
  }
  if (typeof global !== 'undefined') {
    return global
  }
  return null
}

type Lock = {
  acquire: () => Promise<() => void>
}

const createLock = () => {
  if (getGlobal() && !getGlobal()?.['graphqlLock']) {
    getGlobal()['graphqlLock'] = withTimeout(new Mutex(), 5000)
  }
}

const getLock = (): Lock => {
  if (getGlobal()?.['graphqlLock']) {
    return getGlobal()?.['graphqlLock']
  } else {
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      acquire: () => Promise.resolve(() => {})
    }
  }
}

createLock()

const ChecksumCookieName = 'user-checksum'
const ChecksumCookieOptions = {
  maxAge: 24 * 60 * 60, // 1 day
  path: '/'
}

const UserProvider: React.FC<UserProviderProps> = ({
  user: initialUser,
  session: initialSession,
  children
}) => {
  const [user, setUser] = useState<Customer>(initialUser)
  const { t } = useTranslation('common')
  const sessionRef = useRef<Session>(initialSession)

  useEffect(() => {
    if (user?.email || !user) {
      const cookie = useCookie()
      cookie.set(
        ChecksumCookieName,
        objectHash(user ?? null),
        ChecksumCookieOptions
      )
    }
  }, [user])

  const getUserChecksum = () => {
    const cookie = useCookie()
    return cookie.get<string>(ChecksumCookieName)
  }

  const refreshSession = async () => {
    try {
      sessionRef.current = await fetcher('/api/auth/session?userProvider')
      // eslint-disable-next-line no-empty
    } catch {}
    return sessionRef.current
  }

  useSWR<Session>('/api/auth/session?userProvider', () => {
    return refreshSession()
  })

  const getSession = async () => {
    const release = await getLock().acquire()
    try {
      if (sessionRef.current && !sessionRef.current.isLoggedIn) {
        return sessionRef.current
      } else if (isAccessTokenExpired(sessionRef.current?.expiration)) {
        return await refreshSession()
      } else {
        return sessionRef.current
      }
    } finally {
      release()
    }
  }

  const updateUser = async (newUser: Customer, notify = true) => {
    setUser(newUser)
    if (notify) {
      notifySuccess(t('Account.Info.successUpdateUserTitle'))
    }
  }

  const isLoggedInBySession = () => !!sessionRef.current?.accessToken

  const isLoggedIn = useMemo(
    () => !!(user && sessionRef.current?.accessToken),
    [sessionRef.current, user]
  )

  const isEmployee = useMemo(
    () => isLoggedIn && user?.isEmployee,
    [sessionRef.current, user, isLoggedIn]
  )

  const canManageProducts = useMemo(
    () => isLoggedIn && user?.canManageProducts,
    [sessionRef.current, user, isLoggedIn]
  )

  const cookie = useCookie()
  cookie.set('isEmployee', isEmployee, ChecksumCookieOptions)

  useEffect(() => {
    if (!isLoggedIn) {
      setUser(null)
    }
  }, [isLoggedIn])

  return (
    <UserContext.Provider
      value={{
        user,
        isLoggedIn,
        isLoggedInBySession,
        isEmployee,
        getSession,
        updateUser,
        getUserChecksum,
        canManageProducts
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider

export const isLoggedInBySession = (session: Session): boolean => {
  return !!session?.accessToken
}
