import { FC } from 'react'
import { Box } from '@/components/atoms/Grid'
import styled, { css, keyframes } from 'styled-components'

export const RollingSadFace: FC<Rest> = ({ ...rest }) => (
  <SadFaceWrapper {...rest}>
    <SadFace>
      <LeftEye />
      <RightEye />
      <SadMouth />
    </SadFace>
    <MovingShadow />
  </SadFaceWrapper>
)

export const BouncingHappyFace: FC<Rest> = ({ ...rest }) => (
  <HappyFaceWrapper {...rest}>
    <HapyFace>
      <LeftEye />
      <RightEye />
      <HappyMouth />
    </HapyFace>
    <ScalingShadow />
  </HappyFaceWrapper>
)

const LeftEye = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
`

const RightEye = styled(LeftEye)`
  left: 68%;
`

const ShadowBase = css`
  position: relative;
  width: 63px;
  height: 10px;
  opacity: 0.5;
  background: #777;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 1;
`

const move = keyframes`
  0% {
    left: 0;
  }
  50% {
    left: 60px;
  }
  100% {
    left: 0;
  }
`

const MovingShadow = styled.div`
  ${ShadowBase};
  animation: ${move} 3s ease-in-out infinite;
`

const scale = keyframes`
 50% {
    transform: scale(0.9);
  }
`

const ScalingShadow = styled.div`
  ${ShadowBase};
  animation: ${scale} 1s ease-in infinite;
`

const MouthBase = css`
  position: absolute;
  top: 53%;
  left: 41%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

const HappyMouth = styled.div`
  ${MouthBase};
  border: 2px solid;
  border-color: transparent #777 #777 transparent;
  transform: rotate(45deg);
`

const SadMouth = styled.div`
  ${MouthBase};
  top: 55%;
  border: 2px solid;
  border-color: #777 transparent transparent #777;
  transform: rotate(45deg);
`

const bounce = keyframes`
  50% {
    transform: translateY(-10px);
}
`

const roll = keyframes`
  0% {
    transform: rotate(0deg);
  left: 0;
}
  50% {
    left: 60px;
    transform: rotate(135deg);
}
  100% {
    transform: rotate(0deg);
  left: 0;
}
`

const FaceBase = css`
  position: relative;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777;
  z-index: 2;
`

const HapyFace = styled.div`
  ${FaceBase};
  animation: ${bounce} 1s ease-in infinite;
`

const HappyFaceWrapper = styled(Box)`
  animation: ${scale} 1s ease-in infinite;
`

const SadFace = styled.div`
  ${FaceBase};
  transform: rotate(45deg);
  animation: ${roll} 3s ease-in-out infinite;
`

const SadFaceWrapper = styled(Box)`
  animation: ${move} 3s ease-in-out infinite;
`
