import { Product } from '@/network/graphql.g'
import { applyImagePolicy, ImageMode, ImageSizes } from '@/utils/imageExtension'
import { ID } from 'graphql-ws'

export type MhubProductData = {
  brand: string
  category: string
  categoryId: number
  fullPrice: number
  id: number
  imageUrl: string
  imageUrlBox: string
  labels: string
  localFullPrice: number
  localPocketPrice: number
  localPrice: number
  localTax: number
  modelId: ID
  modelName: string
  name: string
  oldProductModel: string
  pocketPrice: number
  price: number
  productUrl: string
  supplierId: number
  tax: number
}

type MhubProductSizeTablePositionEvent = {
  event: string
  action: {
    custom: {
      context1: string
      context2: string | number
      context3: string
      context4: string
      context5: number
      context6: string
    }
  }
}

export const getMhubProductData = (
  product: Product,
  imageMode: ImageMode
): MhubProductData => ({
  brand: product?.productBrand,
  category: product?.MHubProperties?.category,
  categoryId: product?.mainCategory?.id ? +product?.mainCategory?.id : null,
  fullPrice: product?.MHubProperties?.fullPrice,
  id: product?.id ? +product?.id : null,
  imageUrl: applyImagePolicy(product?.MHubProperties?.imageUrl, {
    size: ImageSizes['1400x1400'],
    mode: imageMode
  }),
  imageUrlBox: applyImagePolicy(product?.MHubProperties?.imageUrl, {
    size: ImageSizes['410x615'],
    mode: ImageMode.Local
  }),
  labels: product?.MHubProperties?.labels,
  localFullPrice: product?.MHubProperties?.localFullPrice,
  localPocketPrice: product?.MHubProperties?.localPocketPrice,
  localPrice: product?.MHubProperties?.localPrice,
  localTax: product?.MHubProperties?.localTax ?? null,
  modelId: product?.MHubProperties?.modelId,
  modelName: product?.MHubProperties?.modelName,
  name: product?.MHubProperties?.name,
  oldProductModel: product?.MHubProperties?.oldProductModel,
  pocketPrice: product?.MHubProperties?.pocketPrice,
  price: product?.MHubProperties?.price,
  productUrl: product?.MHubProperties?.productUrl,
  supplierId: product?.MHubProperties?.supplierId ?? null,
  tax: product?.MHubProperties?.tax
})

export const getProductSizeTablePositionEvent = (
  product: Product,
  supplierId: number,
  category: string
): MhubProductSizeTablePositionEvent => {
  return {
    event: 'action.custom.event',
    action: {
      custom: {
        context1: 'size_table_popup_view',
        context2: product?.id ? +product?.id : null,
        context3: product?.productBrand,
        context4: product?.name,
        context5: supplierId,
        context6: category
      }
    }
  }
}
