import { FC } from 'react'

const WishlistIconDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="wishlist-icon-definition">
      <defs>
        <filter id="62lr818lwa" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-254 -56)">
          <g strokeLinecap="round" strokeWidth="1.5">
            <path
              d="M7 1.59l-.483-.481C4.933-.465 2.473-.35 1.022 1.368c-1.451 1.717-1.344 4.385.24 5.96l5.667 5.635c.04.039.103.039.142 0l5.668-5.636h0c1.583-1.574 1.69-4.242.239-5.96C11.527-.348 9.067-.464 7.483 1.11L7 1.589z"
              transform="translate(255 56) translate(.5 1.983)"
            />
          </g>
        </g>
      </g>
    </symbol>
  </svg>
)

export default WishlistIconDefinition
