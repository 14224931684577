import { FC } from 'react'

const TracksuitWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="tracksuit-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="11.62" y="2" width="44.43" height="10.29" />
        <path d="M65.69,152V34.32c0-8.64-3.69-16.44-9.62-22.03H11.62C5.69,17.88,2,25.68,2,34.32v117.68H23.18l10.66-107.14,10.66,107.14h21.18Z" />
        <path d="M15.07,13.15c2.4,3.7,2.15,8.93-.98,12.95-2.84,3.65-7.26,5.31-11.23,4.61" />
        <path d="M52.18,13.15c-2.4,3.7-2.15,8.93,.98,12.95,2.84,3.65,7.26,5.31,11.23,4.61" />
      </g>
    </symbol>
  </svg>
)

export default TracksuitWomenDefinition
