const useWaiter = (
  retry = 100,
  interval = 100
): {
  wait: (condition: () => boolean) => Promise<void>
} => {
  const wait = (condition: () => boolean): Promise<void> =>
    new Promise((resolve) => {
      if (!condition()) {
        let iteration = 0
        const handler = setInterval(() => {
          if (condition() || ++iteration === retry) {
            clearInterval(handler)
            resolve(null)
          }
        }, interval)
      } else {
        resolve(null)
      }
    })

  return { wait }
}

export default useWaiter
