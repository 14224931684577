import { FC } from 'react'

const TopsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="tops-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2,68.59c4.06-1.37,9.45-6.01,14.42-12.89,4.98-6.87,8.34-21.2,9.33-26.81" />
        <path d="M25.76,28.9c-.14,5.62,2.32,14.87,7,25.06,3.47,7.54,11.05,16.64,16.75,22.77" />
        <path d="M73.25,28.9c.14,5.62-2.32,14.87-7,25.06-3.47,7.54-11.05,16.63-16.75,22.77" />
        <path d="M97,68.59c-9.44,21.53-.73,60.15,0,83.41H2c.73-23.26,9.44-61.88,0-83.41" />
        <path d="M97,68.59c-4.06-1.37-9.45-6.01-14.42-12.89-4.98-6.87-8.34-21.2-9.33-26.81" />
        <line x1="25.75" y1="28.9" x2="25.75" y2="2" />
        <line x1="73.25" y1="28.9" x2="73.25" y2="2" />
        <line x1="49.5" y1="76.73" x2="35.8" y2="74.35" />
        <line x1="49.5" y1="76.73" x2="37.43" y2="80.26" />
        <line x1="49.5" y1="76.73" x2="63.2" y2="74.35" />
        <line x1="49.5" y1="76.73" x2="61.58" y2="80.26" />
      </g>
    </symbol>
  </svg>
)

export default TopsDefinition
