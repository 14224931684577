import { css } from 'styled-components'
import { breakpoints, BreakpointKey } from './breakpoints'

export const validFor = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media screen and (min-width: ${breakpoints[label].min +
        'px'}) ${breakpoints[label].max &&
        `and (max-width: ${breakpoints[label].max + 'px'})`} {
        ${css(...args)};
      }
    `
    return accumulator
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as Record<BreakpointKey, any>
)
