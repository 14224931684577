import styled, { css } from 'styled-components'
import { validFor } from '../../theme'
import {
  space,
  color,
  textAlign,
  display,
  maxWidth,
  margin,
  padding,
  lineHeight,
  opacity,
  zIndex,
  width
} from 'styled-system'

const TextFunctions = css`
  ${space};
  ${color};
  ${textAlign};
  ${display};
  ${maxWidth};
  ${margin};
  ${padding};
  ${lineHeight};
  ${opacity};
  ${zIndex};
  ${width};
`

const TypographyBase = css`
  background-color: transparent;
  ${TextFunctions};
`

export const H1 = styled.h1`
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  ${validFor.mobile`
    font-size: 20px;
  `}
  ${TypographyBase};
`

const H2Base = css`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  ${TypographyBase};
`
const H2mBase = css`
  font-size: 20px;
  line-height: 1.5;
`

export const H2 = styled.h2`
  ${H2Base}
  ${validFor.tablet`
	    ${H2mBase}
	`}
`

export const InformationH2 = styled.h2`
  ${H2Base}
  ${validFor.mobile`
	    ${H2mBase}
	`}
`

export const H3Base = css`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  ${validFor.mobile`
	    font-size: 16px;
	    line-height: 1.5;
	`}
  ${TypographyBase};
`

export const H3 = styled.h2`
  ${H3Base}
`

export const LeadBase = css`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Lead = styled.p`
  ${LeadBase};
`

export const Body2 = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Body3 = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Body = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06;
  letter-spacing: normal;
  ${validFor.tablet`
    font-size: 14px;
    line-height: 1.86;
	`}
  ${TypographyBase};
`

const SmallBase = css`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Small = styled.p`
  ${SmallBase};
`

export const Small2Base = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`
export const Small2 = styled.p`
  ${Small2Base}
`

export const Small3Base = css`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Small3 = styled.p`
  ${Small3Base}
`

const CapitalizedBase = css`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Capitalized = styled.p`
  ${CapitalizedBase}
`

export const Capitalized2 = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Tiny = styled.p`
  font-family: Factcool;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Tiny2Base = css`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const Tiny2 = styled.p`
  ${Tiny2Base}
`

export const MediumTinyBold = styled.p`
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`

export const ExtraTinyBold = styled.p`
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ${TypographyBase};
`
