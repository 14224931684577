import { FC } from 'react'

const FilledHeartIconDefinition: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    style={{ display: 'none' }}
  >
    <symbol id="filledHeart-icon-definition" viewBox="0 0 17 17">
      <g fill="none" fillRule="evenodd">
        <g
          fill="#f04e23"
          stroke="#f04e23"
          strokeLinecap="round"
          strokeWidth="2"
        >
          <path
            d="M7 1.59l-.483-.481C4.933-.465 2.473-.35 1.022 1.368c-1.451 1.717-1.344 4.385.24 5.96l5.667 5.635c.04.039.103.039.142 0l5.668-5.636h0c1.583-1.574 1.69-4.242.239-5.96C11.527-.348 9.067-.464 7.483 1.11L7 1.589z"
            transform="translate(-342 -170) translate(343 170) translate(.5 1.983)"
          />
        </g>
      </g>
    </symbol>
  </svg>
)

export default FilledHeartIconDefinition
