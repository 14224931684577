import type { IronSessionOptions } from 'iron-session'
import { Cookie } from 'next-cookie'

export type Session = {
  isLoggedIn: boolean
  accessToken?: string
  refreshToken?: string
  facebookRegistration?: boolean
  firstname?: string
  lastname?: string
  expiration?: number
}

export type SSRSession = {
  session: Session
  host: string
  cookie: Cookie
}

export const sessionOptions: IronSessionOptions = {
  password: process.env.AUTH_COOKIE_PASSWORD,
  cookieName: 'auth-session',
  cookieOptions: {
    secure: process.env.NODE_ENV === 'production',
    maxAge: 365 * 24 * 60 * 60
  }
}

declare module 'iron-session' {
  interface IronSessionData {
    user?: Session
  }
}
