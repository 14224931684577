import { FC } from 'react'

const JacketsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="jacket-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6.6,120.06L24.02,25.19c.84-4.57,3.8-8.47,7.98-10.5l10.15-4.93" />
        <path d="M127.19,124.66l-12.01-63.7h0c-8.18,23.01-9.58,47.89-4.02,71.67l4.02,17.22H38.82l4.02-17.22c5.56-23.78,4.16-48.66-4.02-71.67h0s-12.03,63.79-12.03,63.79" />
        <path d="M111.84,9.76l10.15,4.93c4.18,2.03,7.14,5.93,7.98,10.5l17.41,94.81" />
        <path d="M49.98,2s2.32,18.76,12.14,32.14c9.82,13.38,14.88,30.59,14.88,30.59,0,0-20.07-7.84-31.76-21.07-11.69-13.23-3.13-33.98-3.13-33.98l7.87-7.68Z" />
        <path d="M104.02,2s-2.32,18.76-12.14,32.14c-9.82,13.38-14.88,30.59-14.88,30.59,0,0,20.07-7.84,31.76-21.07,11.69-13.23,3.13-33.98,3.13-33.98l-7.87-7.68Z" />
        <line x1="49.98" y1="2" x2="103.95" y2="2" />
        <path d="M25.34,138.22c-.47,2.62-2.51,4.27-4.55,3.67l-15.84-4.67c-2.04-.6-3.32-3.23-2.85-5.85l1.36-7.61c.47-2.62,2.51-4.27,4.55-3.67l15.84,4.67c2.04,.6,3.32,3.23,2.85,5.85l-1.36,7.61Z" />
        <path d="M128.66,138.22c.47,2.62,2.51,4.27,4.55,3.67l15.84-4.67c2.04-.6,3.32-3.23,2.85-5.85l-1.36-7.61c-.47-2.62-2.51-4.27-4.55-3.67l-15.84,4.67c-2.04,.6-3.32,3.23-2.85,5.85l1.36,7.61Z" />
        <line x1="77" y1="64.72" x2="77" y2="149.85" />
        <line x1="53.38" y1="16.36" x2="100.62" y2="16.36" />
      </g>
    </symbol>
  </svg>
)

export default JacketsWomenDefinition
