import { ActiveFiltersPriceType } from '@/types/activeFiltersType'

enum FilterNames {
  Size = 'size_table_item#size',
  InStock = 'in_stock#undefined',
  InSale = 'warehouse_liquidation#undefined',
  Colour = 'colour#colour',
  Motive = 'motive#motive',
  Material = 'material#material',
  Manufacturer = 'manufacturer#manufacturer',
  Price = 'price',
  Sorting = 'sorting',
  NewProducts = 'new_products#undefined',
  Gender = 'gender#',
  Category = 'category#category',
  Tag = 'tag_{tagId}#{tagTitle}',
  MobileCategories = 'category#quickFilterMobileCategory'
}

type MhubFilterAdd = {
  add: { filter: string; option: string | ActiveFiltersPriceType }
}

type MhubFilterRemove = {
  remove: { filter: string; option: string | ActiveFiltersPriceType }
}

type MhubFilterChangeEvent = {
  event: string
  action: MhubFilterAdd | MhubFilterRemove
}

type MhubFilterChooseSizeEvent = {
  event: string
  action: {
    chooseSize: {
      size: string
    }
  }
}

export const getSortingFilterAdd = (value: string): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.Sorting, value)

export const getPriceFilterAdd = (
  value: ActiveFiltersPriceType
): MhubFilterChangeEvent => getFilterAdd(FilterNames.Price, value)

export const getPriceFilterRemove = (
  value: ActiveFiltersPriceType
): MhubFilterChangeEvent => getFilterRemove(FilterNames.Price, value)

export const getManufacturerFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.Manufacturer, `${value}#${name}`)

export const getManufacturerFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Manufacturer, `${value}#${name}`)

export const getColorFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent => getFilterAdd(FilterNames.Colour, `${value}#${name}`)

export const getColorFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Colour, `${value}#${name}`)

export const getMotiveFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent => getFilterAdd(FilterNames.Motive, `${value}#${name}`)

export const getMotiveFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Motive, `${value}#${name}`)

export const getMaterialFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.Material, `${value}#${name}`)

export const getMaterialFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Material, `${value}#${name}`)

export const getSizeFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent => getFilterAdd(FilterNames.Size, `${value}#${name}`)

export const getSizeFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Size, `${value}#${name}`)

export const getGenderFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent => getFilterAdd(FilterNames.Gender, `${value}#${name}`)

export const getGenderFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Gender, `${value}#${name}`)

export const getCategoryFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.Category, `${value}#${name}`)

export const getCategoryFilterRemove = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.Category, `${value}#${name}`)

export const getTagFilterAdd = (
  tagId: string,
  tagTitle: string,
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterAdd(
    FilterNames.Tag.replace('{tagId}', tagId).replace('{tagTitle}', tagTitle),
    `${value}#${name}`
  )

export const getTagFilterRemove = (
  tagId: string,
  tagTitle: string,
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(
    FilterNames.Tag.replace('{tagId}', tagId).replace('{tagTitle}', tagTitle),
    `${value}#${name}`
  )

export const getInStockFilterAdd = (name: string): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.InStock, `1#${name}`)

export const getInStockFilterRemove = (name: string): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.InStock, `1#${name}`)

export const getInSaleFilterAdd = (name: string): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.InSale, `1#${name}`)

export const getInSaleFilterRemove = (name: string): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.InSale, `1#${name}`)

export const getNewProductsFilterAdd = (name: string): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.NewProducts, `1#${name}`)

export const getMobileCategoriesQuickFilterAdd = (
  value: string,
  name: string
): MhubFilterChangeEvent =>
  getFilterAdd(FilterNames.MobileCategories, `${value}#${name}`)

export const getNewProductsFilterRemove = (
  name: string
): MhubFilterChangeEvent =>
  getFilterRemove(FilterNames.NewProducts, `1#${name}`)

export const getSizeChoose = (value: string): MhubFilterChooseSizeEvent => ({
  event: 'action.chooseSize.product',
  action: {
    chooseSize: {
      size: value
    }
  }
})

const getFilterAdd = (
  name: string,
  value: string | ActiveFiltersPriceType
): MhubFilterChangeEvent => getFilterChange('action.add.filter', name, value)

const getFilterRemove = (
  name: string,
  value: string | ActiveFiltersPriceType
): MhubFilterChangeEvent => getFilterChange('action.remove.filter', name, value)

const getFilterChange = (
  event: string,
  name: string,
  value: string | ActiveFiltersPriceType
): MhubFilterChangeEvent => {
  const isAddFilter = event.split('.').includes('add')
  const action = isAddFilter
    ? {
        add: {
          filter: name,
          option: value
        }
      }
    : {
        remove: {
          filter: name,
          option: value
        }
      }
  return {
    event: event,
    action
  }
}
