import { Currency } from '@/network/graphql.g'
import { Translate } from 'next-translate'
import { ParsedUrlQuery, stringify } from 'querystring'

export const mapPriceToString = (
  translate: Translate,
  price: number,
  currency: Currency,
  forFree?: string,
  ignoreSign?: boolean
): string => {
  if (price == null) {
    return ''
  } else if (price === 0) {
    return forFree ? forFree : translate('Common.forFree')
  } else if (price > 0) {
    return makePriceString({
      price,
      currency,
      signType: ignoreSign ? null : PriceSignType.plus
    })
  } else {
    return makePriceString({ price, currency })
  }
}

export const mapPriceToStringNew = (
  translate: Translate,
  price: number,
  currency: Currency,
  forFree?: string
): string => {
  if (price == null) {
    return ''
  } else if (price === 0) {
    return forFree ? forFree : translate('Common.forFree')
  } else {
    return makePriceString({ price, currency })
  }
}

export enum PriceSignType {
  plus,
  minus
}

export const makePriceString = ({
  price,
  currency,
  allowZero = false,
  signType,
  leaveNumberAsIs = false
}: {
  price: number
  currency: Currency
  allowZero?: boolean
  signType?: PriceSignType
  leaveNumberAsIs?: boolean
}): string => {
  if ((!allowZero && !price) || (allowZero && price == null)) {
    return ''
  }
  let sign = ''
  switch (signType) {
    case PriceSignType.minus:
      sign = '-'
      break
    case PriceSignType.plus:
      sign = '+'
      break
  }

  const finalPrice = leaveNumberAsIs
    ? price
    : roundPrice(price, currency).toFixed(getDecimals(currency))
  return `${currency?.leftSymbol ?? ''}${sign}${finalPrice}${
    currency?.rightSymbol ?? ''
  }`.trim()
}

const getDecimals = (currency: Currency) => currency?.decimalPlaces ?? 2

export const roundPrice = (price: number, currency: Currency): number => {
  const decimals = getDecimals(currency)
  const multiplicator = Math.pow(10, decimals)
  return Math.round(price * multiplicator) / multiplicator
}

type ConvertQueryParamsAndRemoveEmptyGetParameters = {
  query: ParsedUrlQuery
  allowOnly?: string[]
  clearParams?: string[]
}

const removeEmptyGetParameters = ({
  query,
  allowOnly = null,
  clearParams = []
}: ConvertQueryParamsAndRemoveEmptyGetParameters): ParsedUrlQuery => {
  query.slug = null
  clearParams.forEach((param) => (query[param] = null))
  const filteredQuery: ParsedUrlQuery = {}

  // to allow params like 'tag_{tagId}[]'
  const regex = allowOnly
    ?.map(
      (item) =>
        `(${item
          .replace('[', '\\[')
          .replace(']', '\\]')
          .replace(/\{.+\}/, '[0-9]+')})`
    )
    .join('|')

  for (const key in query) {
    if (
      query[key] != null &&
      (!Array.isArray(query[key]) || query[key]?.length > 0) &&
      (!allowOnly || key.match(new RegExp(regex)))
    ) {
      filteredQuery[key] = query[key]
    }
  }

  return filteredQuery
}

const sortQueryParamsByConfig = (query) => {
  const sortConfig = [
    'category',
    'size_table_item',
    'colour',
    'material',
    'manufacturer'
  ]

  return Object.keys(query)
    .sort((a, b) => {
      const indexA = sortConfig.indexOf(a.replace('[]', ''))
      const indexB = sortConfig.indexOf(b.replace('[]', ''))
      const maxIndex = sortConfig.length

      return (
        (indexA === -1 ? maxIndex : indexA) -
        (indexB === -1 ? maxIndex : indexB)
      )
    })
    .reduce((acc, key) => {
      acc[key] = query[key]
      return acc
    }, {})
}

export const convertQueryParamsAndRemoveEmptyGetParameters = ({
  query,
  allowOnly = null,
  clearParams = []
}: ConvertQueryParamsAndRemoveEmptyGetParameters): string => {
  const cleanedQuery = removeEmptyGetParameters({
    query,
    allowOnly,
    clearParams
  })

  const sortedQuery = sortQueryParamsByConfig(cleanedQuery)

  const queryString = stringify(sortedQuery)

  return queryString.replace(/[^?=&]+=(&|$)/g, '').replace(/&$/, '')
}

export const toFirstLetterUpperCase = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1)

export const calculateDiscount = (
  basePrice: number,
  referencePrice: number
): number => {
  return Math.round(((basePrice - referencePrice) / referencePrice) * 100)
}
