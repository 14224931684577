import { FC, Fragment } from 'react'
import Banner, {
  BannerProps,
  BannerWithPlaceholder,
  ImageSize
} from '@/components/molecules/banner'
import { Box, Flex, Grid } from '@/components/atoms/Grid'
import styled from 'styled-components'
import {
  Desktop,
  MediaContextProvider,
  Mobile,
  Tablet
} from '@/components/atoms/media'
import { HomeBanner, HomeBannerSize } from '@/network/graphql.g'
import { BreakpointKey } from '@/theme/breakpoints'
import { toFirstLetterUpperCase } from '@/core/toStringHelper'
import { H1 } from '@/components/atoms/typography'
import { validFor } from '@/theme/validFor'
import Link from '@/components/atoms/link'
import { SpecialLandingPageBannerContent } from '@/components/organisms/specialLandingPage/specialLandingPageBannerContent'
import Trans from 'next-translate/Trans'

export enum BannersPositionType {
  Right = 'RIGHT',
  Center = 'CENTER',
  Top = 'TOP'
}

export enum BannerDescriptionType {
  UnderCarousel = 'UNDER_CAROUSEL',
  Carousel = 'CAROUSEL'
}

export enum BannerPageLocationType {
  Home = 'HOME_PAGE',
  LandingPage = 'LANDING_PAGE',
  Dropdown = 'DROP_DOWN_MENU'
}

export enum BannersType {
  Home,
  Campaign,
  Manufacturer,
  Dropdown,
  LandingPage,
  SpecialLandingPage,
  CategoryProduct,
  MainCategory,
  Micropage,
  Gifts,
  GiftsSimple
}

export enum PreloadMode {
  All,
  First
}

export type BannersLayoutProps = {
  banners: HomeBanner[]
  gridColumnGap?: number
  gridRowGap?: number
  bannerDst: BannersType
  size?: ImageSize
  preloadMode?: PreloadMode
  preload?:
    | boolean
    | {
        desktop: boolean
        tablet: boolean
        mobile: boolean
      }
  lazy?: boolean
} & Rest

const BannersLayout: FC<BannersLayoutProps> = ({
  banners,
  gridColumnGap = 12,
  gridRowGap = 12,
  bannerDst,
  size,
  preloadMode = PreloadMode.All,
  preload = false,
  lazy = false,
  ...rest
}) => {
  const desktopRows = makeRows(bannerDst, BreakpointKey.desktop, banners)
  const tabletRows = makeRows(bannerDst, BreakpointKey.tablet, banners)
  const mobileRows = makeRows(bannerDst, BreakpointKey.mobile, banners)

  const renderBanners = (rows: BannerProps[][], media: BreakpointKey) => {
    const getPreload = (flatIndex: number): boolean => {
      if (
        preloadMode === PreloadMode.All ||
        (preloadMode === PreloadMode.First && flatIndex === 0)
      ) {
        if (typeof preload === 'boolean') {
          return preload
        } else {
          switch (media) {
            case BreakpointKey.desktop:
              return preload.desktop
            case BreakpointKey.tablet:
              return preload.tablet
            case BreakpointKey.mobile:
              return preload.mobile
          }
        }
      }
      return false
    }
    return (
      <>
        {rows &&
          rows.length > 0 &&
          rows.map((row, offset) => (
            <GridCell
              key={offset}
              numberOfColumns={row.length}
              gridColumnGap={gridColumnGap}
              gridRowGap={gridRowGap}
              mb={rows.length - 1 === offset ? 0 : gridRowGap}
            >
              {row.map((banner, rowOffset) => {
                const flatIndex = offset * rows?.[0]?.length + rowOffset
                return (
                  <Fragment key={rowOffset}>
                    {banner.ratio ? (
                      <BannerWithPlaceholder
                        preload={getPreload(flatIndex)}
                        preloadDefaultMedia={media}
                        lazy={lazy}
                        desktop={
                          desktopRows.flatMap((item) => item)?.[flatIndex]
                            ?.imageUrl
                        }
                        tablet={
                          tabletRows.flatMap((item) => item)?.[flatIndex]
                            ?.imageUrl
                        }
                        mobile={
                          mobileRows.flatMap((item) => item)?.[flatIndex]
                            ?.imageUrl
                        }
                        {...banner}
                      />
                    ) : (
                      <Banner {...banner} size={size} />
                    )}
                  </Fragment>
                )
              })}
            </GridCell>
          ))}
      </>
    )
  }

  return (
    <Box {...rest}>
      <MediaContextProvider>
        <Desktop>{renderBanners(desktopRows, BreakpointKey.desktop)}</Desktop>
        <Tablet>{renderBanners(tabletRows, BreakpointKey.tablet)}</Tablet>
        <Mobile>{renderBanners(mobileRows, BreakpointKey.mobile)}</Mobile>
      </MediaContextProvider>
    </Box>
  )
}

export default BannersLayout

type BannersConfigType = Record<
  BannersType,
  Record<
    BreakpointKey.desktop | BreakpointKey.tablet | BreakpointKey.mobile,
    {
      countPerRow: Record<HomeBannerSize, number>
      data: (
        banner: HomeBanner & { routerPath?: string; category?: string }
      ) => BannerProps
    }
  >
>

const BannersConfig: BannersConfigType = {
  [BannersType.Home]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 3, [HomeBannerSize.Small]: 3 },
      data: (banner) => ({
        imageUrl: banner.imageSmall,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        title: banner.title,
        ratio: 280 / 600,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: BannersPositionType.Center,
        page: BannerPageLocationType.Home,
        description: BannerDescriptionType.UnderCarousel
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 3, [HomeBannerSize.Small]: 3 },
      data: (banner) => ({
        imageUrl: banner.imageSmall,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        title: banner.title,
        ratio: 280 / 600,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: BannersPositionType.Center,
        page: BannerPageLocationType.Home,
        description: BannerDescriptionType.UnderCarousel
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageSmall,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        title: banner.title,
        ratio: 280 / 600,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: BannersPositionType.Center,
        page: BannerPageLocationType.Home,
        description: BannerDescriptionType.UnderCarousel
      })
    }
  },
  [BannersType.Campaign]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 2 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        title: banner.title
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 2 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        title: banner.title
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageSmall || banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        title: banner.title
      })
    }
  },
  [BannersType.Manufacturer]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        title: banner.title,
        ratio: 279 / 1400
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        title: banner.title,
        ratio: 279 / 1400
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageSmall || banner.imageBig,
        title: banner.title,
        ratio: 453 / 926
      })
    }
  },
  [BannersType.LandingPage]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 2 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: banner.position,
        page: BannerPageLocationType.LandingPage,
        description: banner.title,
        title: banner.title,
        routerPath: banner.routerPath,
        content: banner.title && (
          <Link href={banner.url1}>
            <LandingPageTitle>{banner.title}</LandingPageTitle>
          </Link>
        )
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: banner.position,
        page: BannerPageLocationType.LandingPage,
        description: banner.title,
        title: banner.title,
        routerPath: banner.routerPath,
        content: banner.title && (
          <Link href={banner.url1}>
            <LandingPageTitle>{banner.title}</LandingPageTitle>
          </Link>
        )
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageSmall || banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: banner.position,
        page: BannerPageLocationType.LandingPage,
        description: banner.title,
        title: banner.title,
        routerPath: banner.routerPath,
        content: banner.title && (
          <Link href={banner.url1}>
            <LandingPageTitle>{banner.title}</LandingPageTitle>
          </Link>
        )
      })
    }
  },
  [BannersType.SpecialLandingPage]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 3 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        content: (
          <SpecialLandingPageBannerContent
            url={banner.url1}
            title={banner.title}
          />
        )
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 3 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        content: (
          <SpecialLandingPageBannerContent
            url={banner.url1}
            title={banner.title}
          />
        )
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageSmall || banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        content: (
          <SpecialLandingPageBannerContent
            url={banner.url1}
            title={banner.title}
          />
        )
      })
    }
  },
  [BannersType.Dropdown]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl:
          toFirstLetterUpperCase(banner.size) === HomeBannerSize.Big
            ? banner.imageBig
            : banner.imageSmall,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: BannersPositionType.Right,
        page: BannerPageLocationType.Dropdown,
        title: banner.title,
        category: banner.category
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl:
          toFirstLetterUpperCase(banner.size) === HomeBannerSize.Big
            ? banner.imageBig
            : banner.imageSmall,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: BannersPositionType.Right,
        page: BannerPageLocationType.Dropdown,
        title: banner.title,
        category: banner.category
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl:
          toFirstLetterUpperCase(banner.size) === HomeBannerSize.Big
            ? banner.imageBig
            : banner.imageSmall,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        id: banner.id,
        sortOrder: banner.sortOrder,
        position: BannersPositionType.Right,
        page: BannerPageLocationType.Dropdown,
        title: banner.title,
        category: banner.category
      })
    }
  },
  [BannersType.CategoryProduct]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 127 / 1400
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 127 / 1400
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageMobile || banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: banner.imageMobile ? 240 / 768 : 127 / 1400
      })
    }
  },
  [BannersType.Gifts]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 141 / 1400,
        content: <GiftsTitle />
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 141 / 1400,
        content: <GiftsTitle />
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageMobile || banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: banner.imageMobile ? 224 / 780 : 141 / 1400,
        content: <GiftsTitle />
      })
    }
  },
  [BannersType.GiftsSimple]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: () => ({
        imageUrl: null,
        destinationUrl1: null,
        destinationUrl2: null,
        ratio: null
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: () => ({
        imageUrl: null,
        destinationUrl1: null,
        destinationUrl2: null,
        ratio: null
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageMobile,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 127 / 768
      })
    }
  },
  [BannersType.MainCategory]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 2, [HomeBannerSize.Small]: 2 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 350 / 572
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 2, [HomeBannerSize.Small]: 2 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 350 / 572
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2,
        ratio: 350 / 572
      })
    }
  },
  [BannersType.Micropage]: {
    [BreakpointKey.desktop]: {
      countPerRow: { [HomeBannerSize.Big]: 3, [HomeBannerSize.Small]: 3 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        title: banner.title,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2
      })
    },
    [BreakpointKey.tablet]: {
      countPerRow: { [HomeBannerSize.Big]: 3, [HomeBannerSize.Small]: 3 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        title: banner.title,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2
      })
    },
    [BreakpointKey.mobile]: {
      countPerRow: { [HomeBannerSize.Big]: 1, [HomeBannerSize.Small]: 1 },
      data: (banner) => ({
        imageUrl: banner.imageBig,
        title: banner.title,
        destinationUrl1: banner.url1,
        destinationUrl2: banner.url2
      })
    }
  }
}

const makeRows = (
  bannerDst: BannersType,
  breakpoint: BreakpointKey,
  banners: HomeBanner[]
): Array<BannerProps[]> => {
  const bannersCopy = Object.assign([], banners)
  const finalBanners: Array<BannerProps[]> = []
  let rowIndex = -1
  while (bannersCopy.length > 0) {
    ++rowIndex
    const banner: HomeBanner = bannersCopy[0]
    const countPerRow =
      BannersConfig[bannerDst][breakpoint]?.countPerRow[banner.size] ??
      BannersConfig[bannerDst][breakpoint]?.countPerRow[
        toFirstLetterUpperCase(banner.size)
      ]

    if (!countPerRow) {
      throw new Error()
    }
    finalBanners[rowIndex] = makeColumns(
      banner.size,
      bannerDst,
      breakpoint,
      bannersCopy,
      countPerRow
    )
  }
  return finalBanners
}

const makeColumns = (
  bannerSize: HomeBannerSize,
  bannerDst: BannersType,
  breakpoint: BreakpointKey,
  banners: HomeBanner[],
  count: number
): BannerProps[] => {
  const finalBanners: BannerProps[] = []
  for (let i = 0; i < count; ++i) {
    const index = banners.findIndex(({ size }) => size === bannerSize)
    if (index > -1) {
      finalBanners[i] = BannersConfig[bannerDst][breakpoint].data(
        banners.splice(index, 1)[0]
      )
      continue
    }
    break
  }
  return finalBanners
}

type GridCellProps = {
  numberOfColumns: number
}

const GridCell = styled<GridCellProps>(Grid)`
  grid-template-columns: ${(props) => `repeat(${props.numberOfColumns}, 1fr)`};
`

const LandingPageTitle = styled(H1)`
  position: absolute;
  right: 0;
  top: 50%;
  text-transform: uppercase;
  width: 70%;
  text-align: center;
  font-size: 47px;
  color: ${({ theme }) => theme.colors.background};
  ${validFor.mobile`
    font-size: 22px;
  `}
`

const GiftsTitle: FC = () => (
  <StyledFlex alignItems="center" height="100%">
    <H1 pl={{ desktop: 80, tablet: 60, mobile: 40 }}>
      <Trans
        i18nKey="common:Gifts.bannerText"
        components={[<div />, <div />]}
      />
    </H1>
  </StyledFlex>
)

const StyledFlex = styled(Flex)`
  ${H1} {
    line-height: normal;
    font-size: 34px;
    ${validFor.tablet`
      font-size: 24px;
    `}
    ${validFor.mobile`
      font-size: 32px;
    `}
  }
`
