import { AdditionalInfoPageType, FilterInput, Sdk } from '@/network/graphql.g'
import {
  FC,
  useContext,
  createContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import { makeFilterInputFromQuery } from '@/providers/filterProvider/parsingFilters'
import { useRouter } from 'next/router'
import useGraphQl from 'hooks/useGraphQl'
import useLoading from 'hooks/useLoading'
import { Routes } from '@/core/routes'

const AdditionalInfoContext = createContext<AdditionalInfoContextType>(null)

type AdditionalInfoContextType = {
  additionalData: string
  loading: boolean
  setParameters: (
    pageType: AdditionalInfoPageType,
    id: string,
    filter?: FilterInput[]
  ) => void
  show: boolean
  setShow: (show: boolean) => void
  handleChange: () => void
  showAdditionalMenu: boolean
  pageType: AdditionalInfoPageType
  id: string
  showAdditionalMenuOnHP: boolean
}

export const useAdditionalInfoProvider = (): AdditionalInfoContextType =>
  useContext(AdditionalInfoContext)

const AdditionalInfoProvider: FC = ({ children }) => {
  const router = useRouter()
  const [filter, setFilter] = useState<FilterInput[]>([])
  const [id, setId] = useState<string>()
  const { requestNotifyError } = useGraphQl()
  const [additionalData, setAdditionalData] = useState<string>()
  const { loading, setLoading } = useLoading()
  const [pageType, setPageType] = useState<AdditionalInfoPageType>()
  const [show, setShow] = useState<boolean>(false)
  const handleChange = () => {
    setShow((show) => !show)
  }

  const showAdditionalMenu = useMemo(() => {
    return !!(pageType && id)
  }, [pageType, id])

  const showAdditionalMenuOnHP = router.pathname === Routes.home

  const setParameters = (pageType, id, filter) => {
    setPageType(pageType)
    setId(id)
    setFilter(filter)
  }

  useEffect(() => {
    async function fetchAdditionalData() {
      const filter = makeFilterInputFromQuery(router.query)
      if (pageType && id && show) {
        const { response, success } = await requestNotifyError(
          (sdk: Sdk) => ({
            method: sdk.getAdditionalInfo,
            variables: {
              pageType,
              id,
              filter
            }
          }),
          true
        )
        setAdditionalData(success ? response?.getAdditionalInfo?.html : '')
        setLoading(false)
      } else {
        setAdditionalData(null)
        setLoading(false)
      }
    }
    fetchAdditionalData()
  }, [pageType, id, filter, show])

  return (
    <AdditionalInfoContext.Provider
      value={{
        additionalData,
        loading,
        setParameters,
        show,
        handleChange,
        setShow,
        showAdditionalMenu,
        pageType,
        id,
        showAdditionalMenuOnHP
      }}
    >
      {children}
    </AdditionalInfoContext.Provider>
  )
}
export default AdditionalInfoProvider
