import CheckIcon from '@/components/atoms/svg/CheckIcon'
import ExclamationMarkIcon from '@/components/atoms/svg/ExclamationMarkIcon'
import MenuCloseIcon from '@/components/atoms/svg/MenuCloseIcon'
import { Body } from '@/components/atoms/typography'
import { FC } from 'react'
import { ToastContent, ToastOptions } from 'react-toastify'
import styled, { withTheme } from 'styled-components'

export const notifySuccess = async (
  content: ToastContent,
  id?: string,
  options?: ToastOptions
): Promise<void> => {
  const toast = (await import('react-toastify')).toast
  toast(content, {
    ...{ type: 'success', icon: SuccessIcon, toastId: id },
    ...options
  })
}

export const notifyError = async (
  content: ToastContent,
  id?: string,
  options?: ToastOptions
): Promise<void> => {
  const toast = (await import('react-toastify')).toast
  toast(content, {
    ...{ type: 'error', icon: ErrorIcon, toastId: id },
    ...options
  })
}

export const notifyWarning = async (
  content: ToastContent,
  id?: string,
  options?: ToastOptions
): Promise<void> => {
  const toast = (await import('react-toastify')).toast
  toast(content, {
    ...{ type: 'warning', icon: WarningIcon, toastId: id },
    ...options
  })
}

export const notifyInfo = async (
  content: ToastContent,
  id?: string,
  options?: ToastOptions
): Promise<void> => {
  const toast = (await import('react-toastify')).toast
  toast(content, {
    ...{ type: 'info', icon: InfoIcon, toastId: id },
    ...options
  })
}

const SuccessIcon: FC = withTheme(({ theme }) => {
  return <CheckIcon width={20} height={16} color={theme.colors.success} />
})

const ErrorIcon: FC = withTheme(({ theme }) => {
  return <MenuCloseIcon width={18} height={18} color={theme.colors.error} />
})

const WarningIcon: FC = withTheme(({ theme }) => {
  return (
    <ExclamationMarkIcon width={17} height={17} color={theme.colors.warning} />
  )
})

const InfoIcon: FC = () => {
  return <StyledBody>i</StyledBody>
}

const StyledBody = styled(Body)`
  color: ${({ theme }) => theme.colors.info};
  margin: 0;
  line-height: normal;
  font-size: 20px;
  width: 18px;
  text-align: center;
  font-weight: bold;
`
