import { FC } from 'react'

const JewelleryDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="jevellery-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M52,108.37c0-24.09-25-43.63-25-43.63,0,0-25,19.53-25,43.63s25,43.63,25,43.63c0,0,25-19.53,25-43.63Z" />
        <path d="M42.45,108.37c0-14.89-15.45-26.96-15.45-26.96,0,0-15.45,12.07-15.45,26.96s15.45,26.96,15.45,26.96c0,0,15.45-12.07,15.45-26.96Z" />
        <path d="M27,64.75v-17.65c-33.33,0-33.33-45.1,0-45.1,12.75,0,17.38,8.92,25,19.61" />
        <path d="M121.49,108.37c0-24.09-25-43.63-25-43.63,0,0-25,19.53-25,43.63s25,43.63,25,43.63c0,0,25-19.53,25-43.63Z" />
        <path d="M111.94,108.37c0-14.89-15.45-26.96-15.45-26.96,0,0-15.45,12.07-15.45,26.96s15.45,26.96,15.45,26.96c0,0,15.45-12.07,15.45-26.96Z" />
        <path d="M96.49,64.75v-17.65c-33.33,0-33.33-45.1,0-45.1,12.75,0,17.38,8.92,25,19.61" />
      </g>
    </symbol>
  </svg>
)

export default JewelleryDefinition
