import { FC } from 'react'

const BodyDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="body-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.91,100.92c-4.62,6.79-7.91,14.47-7.91,22.88H22.29c11,0,19.91,8.92,19.91,19.91v8.28h12.74v-8.28c0-11,8.92-19.91,19.91-19.91h20.29c0-8.41-3.19-16.3-7.81-23.08l-11.81-16.3,11.04-23.37c1.45-2.75,2.26-5.87,2.26-9.19,0-6.19-2.85-11.73-7.33-15.38-1.01-2.3-1.84-5.11-2.42-8.86-1.63-10.55-.94-17.3,1.46-23.71l-14.71-1.91V31.99l.06,.16c-9.78,1.36-17.31,9.65-17.31,19.71,0-10.04-7.51-18.32-17.26-19.7v-.05s0-18.99,0-18.99V2l-14.71,1.91c2.4,6.41,3.09,13.15,1.46,23.71-.58,3.75-1.41,6.56-2.42,8.86-4.47,3.66-7.33,9.19-7.33,15.38,0,3.39,.86,6.59,2.37,9.39l11.04,23.37-11.81,16.3" />
        <line x1="41.11" y1="137.6" x2="56.03" y2="137.6" />
      </g>
    </symbol>
  </svg>
)

export default BodyDefinition
