import { FC } from 'react'

const VouchersFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="vouchers-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M79,46.06s9.08,33.65,45.85,33.65" />
        <path d="M79,46.06s-9.08,33.65-45.85,33.65" />
        <rect x="4" y="4" width="150" height="93.4" />
        <path d="M116,64.8c11.8,0,11.8-43.07,0-43.07-13.25-.72-23.85,9.08-33.07,24.33,8.41,13.47,23.76,18.74,33.07,18.74Z" />
        <path d="M42,64.8c-11.8,0-11.8-43.07,0-43.07,13.25-.72,23.85,9.08,33.07,24.33-8.41,13.47-23.76,18.74-33.07,18.74Z" />
        <line x1="49.8" y1="46.06" x2="108.2" y2="46.06" />
        <circle cx="79" cy="46.06" r="9.08" />
        <line x1="4" y1="28.65" x2="33.15" y2="4" />
        <line x1="124.85" y1="97.4" x2="154" y2="72.76" />
      </g>
    </symbol>
  </svg>
)

export default VouchersFrogiesDefinition
