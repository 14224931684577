import { Capitalized } from '@/components/atoms/typography'
import styled from 'styled-components'

type StyledCapitalizedProps = {
  highlight?: boolean
  bold?: boolean
}

const StyledCapitalized = styled<StyledCapitalizedProps>(Capitalized)`
  cursor: pointer;
  color: ${(props) =>
    props.highlight
      ? props.theme.colors.accent
      : props.theme.colors.onBackground};
`

export default StyledCapitalized
