import { FC } from 'react'
import { SvgProps } from './svgProps'

const CartIcon: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 19}
    height={height || 17}
    viewBox="0 0 19 17"
    {...rest}
  >
    <defs>
      <filter id="1g8c6jplja" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-280 -56)">
        <g>
          <path d="M0 0H17V17H0z" transform="translate(281 56)" />
          <g transform="translate(281 56) translate(.567 2.267)">
            <path
              stroke={color || '#595959'}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M0 0h.125c1.414 0 2.637.988 2.933 2.371l.514 2.4c.396 1.845 2.026 3.162 3.912 3.162h3.436c1.76 0 3.314-1.15 3.826-2.835l1.12-3.681h0"
            />
            <circle
              cx="6.233"
              cy="11.333"
              r="1.133"
              stroke={color || '#595959'}
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <circle
              cx="11.9"
              cy="11.333"
              r="1.133"
              stroke={color || '#595959'}
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <path
              fill={color || '#595959'}
              d="M6.517 3.967c.47 0 .85.38.85.85s-.38.85-.85.85-.85-.38-.85-.85.38-.85.85-.85zm2.833 0c.47 0 .85.38.85.85s-.38.85-.85.85-.85-.38-.85-.85.38-.85.85-.85zm2.833 0c.47 0 .85.38.85.85s-.38.85-.85.85-.85-.38-.85-.85.38-.85.85-.85zM6.517 1.133c.47 0 .85.38.85.85s-.38.85-.85.85-.85-.38-.85-.85.38-.85.85-.85zm2.833 0c.47 0 .85.38.85.85s-.38.85-.85.85-.85-.38-.85-.85.38-.85.85-.85zm2.833 0c.47 0 .85.38.85.85s-.38.85-.85.85-.85-.38-.85-.85.38-.85.85-.85z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CartIcon
