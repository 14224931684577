import { FC } from 'react'

const CapsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="caps-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M58.9,33.9c-1.89-3.16-2.98-6.86-2.98-10.82,0-11.64,9.44-21.08,21.08-21.08s21.08,9.44,21.08,21.08c0,4.02-1.13,7.79-3.08,10.98" />
        <path d="M14.86,97.3c0-36.3,27.82-65.72,62.14-65.72s62.14,29.42,62.14,65.72" />
        <line x1="12.4" y1="97.84" x2="12.4" y2="134.29" />
        <line x1="28.55" y1="97.84" x2="28.55" y2="134.29" />
        <line x1="44.7" y1="97.84" x2="44.7" y2="134.29" />
        <line x1="60.85" y1="97.84" x2="60.85" y2="134.29" />
        <line x1="77" y1="97.84" x2="77" y2="134.29" />
        <line x1="93.15" y1="97.84" x2="93.15" y2="134.29" />
        <line x1="125.45" y1="97.84" x2="125.45" y2="134.29" />
        <line x1="141.6" y1="97.84" x2="141.6" y2="134.29" />
        <line x1="109.3" y1="97.84" x2="109.3" y2="134.29" />
      </g>
    </symbol>
  </svg>
)

export default CapsDefinition
