import { FC } from 'react'
import BoxersFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/BoxersFrogiesDefinition'
import BrasFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/BrasFrogiesDefinition'
import CapsFrogiesDefintion from '@/components/atoms/svg/iconsDefinitions/CapsFrogiesDefintion'
import CheerssocksFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/CheerssocksFrogiesDefinition'
import DressesFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/DressesFrogiesDefinition'
import GiftboxFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/GiftboxFrogiesDefinition'
import GlovesFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/GlovesFrogiesDefinition'
import JacketsFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/JacketsFrogiesDefinition'
import PantiesFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/PantiesFrogiesDefinition'
import PyjamasFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/PyjamasFrogiesDefinition'
import ShawlsFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/ShawlsFrogiesDefinition'
import SlidersFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/SlidersFrogiesDefinition'
import SlippersFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/SlippersFrogiesDefinition'
import SocksFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/SocksFrogiesDefinition'
import StockingFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/StockingFrogiesDefinition'
import SweatersFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/SweatersFrogiesDefinition'
import SwimwearMenFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/SwimwearMenFrogiesDefinition'
import TshirtsMenFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/TshirtsMenFrogiesDefinition'
import TshirtsWomenFrogiesDefinition from '@/components/atoms/svg/iconsDefinitions/TshirtsWomenFrogiesDefinition'
import VouchersFrogiesDefinition from '@/components/atoms/svg/categoryIcons/frogies/VouchersFrogiesDefinition'
import UserIconDefinition from '@/components/atoms/svg/iconsDefinitions/UserIconDefinition'
import WishlistIconDefinition from '@/components/atoms/svg/iconsDefinitions/WishlistIconDefinition'
import ArrowDownIconDefinition from '@/components/atoms/svg/iconsDefinitions/ArrowDownIconDefinition'
import HeartIconDefinition from '@/components/atoms/svg/iconsDefinitions/HeartIconDefinition'
import FilledHeartIconDefinition from '@/components/atoms/svg/iconsDefinitions/FilledHeartIconDefinition'
import FilledHeartIconListingDefinition from '@/components/atoms/svg/iconsDefinitions/FilledHeartIconListingDefinition'

const IconsDefinitionListFrogies: FC = () => {
  return (
    <>
      <BoxersFrogiesDefinition />
      <BrasFrogiesDefinition />
      <CapsFrogiesDefintion />
      <CheerssocksFrogiesDefinition />
      <DressesFrogiesDefinition />
      <GiftboxFrogiesDefinition />
      <GlovesFrogiesDefinition />
      <JacketsFrogiesDefinition />
      <PantiesFrogiesDefinition />
      <PyjamasFrogiesDefinition />
      <ShawlsFrogiesDefinition />
      <SlidersFrogiesDefinition />
      <SlippersFrogiesDefinition />
      <SocksFrogiesDefinition />
      <StockingFrogiesDefinition />
      <SweatersFrogiesDefinition />
      <SwimwearMenFrogiesDefinition />
      <TshirtsMenFrogiesDefinition />
      <TshirtsWomenFrogiesDefinition />
      <VouchersFrogiesDefinition />
      <UserIconDefinition />
      <WishlistIconDefinition />
      <ArrowDownIconDefinition />
      <HeartIconDefinition />
      <FilledHeartIconDefinition />
      <FilledHeartIconListingDefinition />
    </>
  )
}

export default IconsDefinitionListFrogies
