import { FC } from 'react'
import { SvgProps } from './svgProps'

const CheckIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 12}
    height={height || 9}
    viewBox="0 0 12 9"
    data-cy="checkIcon"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={color || '#FFF'} strokeWidth="1.5">
        <path
          d="M415 367.267L418.478 371 425 364"
          transform="translate(-414.000000, -363.000000)"
        />
      </g>
    </g>
  </svg>
)

export default CheckIcon
