import dynamic from 'next/dynamic'
import { Suspense } from 'react'
// import styled from 'styled-components'
import { Box } from '@/components/atoms/Grid'

const LoadingReplace = () => <Box width={18} />

const ShowAll = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/ShowAll'),
  {
    suspense: true
  }
)
/* -----------------MEN--------------------*/
const BlazersMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/BlazersMen'),
  {
    suspense: true
  }
)
const JacketsMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/JacketsMen'),
  {
    suspense: true
  }
)
const JeansMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/JeansMen'),
  {
    suspense: true
  }
)
const PantsMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/PantsMen'),
  {
    suspense: true
  }
)
const PyjamasMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/PyjamasMen'),
  {
    suspense: true
  }
)
const ShirtsMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/ShirtsMen'),
  {
    suspense: true
  }
)
const ShortsMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/ShortsMen'),
  {
    suspense: true
  }
)
const SweatersMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/SweatersMen'),
  {
    suspense: true
  }
)
const SweatshirtsMen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/mensClothing/SweatshirtsMen'),
  {
    suspense: true
  }
)
const Swimwear = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/Swimwear'),
  {
    suspense: true
  }
)
const ThermalMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/ThermalMen'),
  {
    suspense: true
  }
)
const TracksuitMen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/mensClothing/TracksuitMen'),
  {
    suspense: true
  }
)
const UnderwearMen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/mensClothing/UnderwearMen'),
  {
    suspense: true
  }
)
const TshirtsMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/TshirtsMen'),
  {
    suspense: true
  }
)

const Multipacks = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/Multipacks'),
  {
    suspense: true
  }
)
const Littleones = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/mensClothing/Littleones'),
  {
    suspense: true
  }
)
/*------------------SHOES--------------*/
const AnkleshoesMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/AnkleshoesMen'),
  {
    suspense: true
  }
)
const Sneakers = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Sneakers'),
  {
    suspense: true
  }
)
const Slipon = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Slipon'),
  {
    suspense: true
  }
)
const Runningshoes = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Runningshoes'),
  {
    suspense: true
  }
)
const Hikingshoes = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Hikingshoes'),
  {
    suspense: true
  }
)
const Moccasins = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Moccasins'),
  {
    suspense: true
  }
)
const SandalsMen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/SandalsMen'),
  {
    suspense: true
  }
)
const Sliders = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Sliders'),
  {
    suspense: true
  }
)
const Footballboots = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Footballboots'),
  {
    suspense: true
  }
)
const Slippers = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Slippers'),
  {
    suspense: true
  }
)
const WinterShoes = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Wintershoes'),
  {
    suspense: true
  }
)
const AnkleShoesWomen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/AnkleshoesWomen'),
  {
    suspense: true
  }
)
const Balerinas = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Balerinas'),
  {
    suspense: true
  }
)
const Espadrilles = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Espadrilles'),
  {
    suspense: true
  }
)
const SandalsWomen = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/SandalsWomen'),
  {
    suspense: true
  }
)
const Courtshoes = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Courtshoes'),
  {
    suspense: true
  }
)
const Boots = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Boots'),
  {
    suspense: true
  }
)
const Wellingtons = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/shoes/Wellingtons'),
  {
    suspense: true
  }
)
/* ----------------ACCESSORIES--------------------*/
const Happysocks = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Happysocks'),
  {
    suspense: true
  }
)
const Baseballcaps = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Baseballcaps'),
  {
    suspense: true
  }
)
const Sunglasses = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Sunglasses'),
  {
    suspense: true
  }
)
const Watches = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Watches'),
  {
    suspense: true
  }
)
const Wallets = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Wallets'),
  {
    suspense: true
  }
)
const Belts = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Belts'),
  {
    suspense: true
  }
)
const Hats = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Hats'),
  {
    suspense: true
  }
)
const Accessories = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Accessories'),
  {
    suspense: true
  }
)
const Ties = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Ties'),
  {
    suspense: true
  }
)
const Caps = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Caps'),
  {
    suspense: true
  }
)
const Shawls = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Shawls'),
  {
    suspense: true
  }
)
const Gloves = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Gloves'),
  {
    suspense: true
  }
)
const Towels = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Towels'),
  {
    suspense: true
  }
)
const Handbags = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Handbags'),
  {
    suspense: true
  }
)
const Jewellery = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/accessories/Jewellery'),
  {
    suspense: true
  }
)
/* ----------------WOMEN--------------------*/
const Blouses = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Blouses'),
  {
    suspense: true
  }
)
const Dresses = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Dresses'),
  {
    suspense: true
  }
)

const JacketsWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/JacketsWomen'),
  {
    suspense: true
  }
)
const SweatersWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/SweatersWomen'),
  {
    suspense: true
  }
)
const SweatShirtsWomen = dynamic(
  () =>
    import(
      '@/components/atoms/svg/categoryIcons/womensClothing/SweatshirtsWomen'
    ),
  {
    suspense: true
  }
)
const TShirtsWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/TShirtsWomen'),
  {
    suspense: true
  }
)
const PyjamasWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/PyjamasWomen'),
  {
    suspense: true
  }
)
const Swimsuits = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Swimsuits'),
  {
    suspense: true
  }
)
const UnderwearWomen = dynamic(
  () =>
    import(
      '@/components/atoms/svg/categoryIcons/womensClothing/UnderwearWomen'
    ),
  {
    suspense: true
  }
)
const JeansWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/JeansWomen'),
  {
    suspense: true
  }
)
const TracksuitWomen = dynamic(
  () =>
    import(
      '@/components/atoms/svg/categoryIcons/womensClothing/TracksuitWomen'
    ),
  {
    suspense: true
  }
)
const Leggings = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Leggings'),
  {
    suspense: true
  }
)
const PantsWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/PantsWomen'),
  {
    suspense: true
  }
)
const Skirts = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Skirts'),
  {
    suspense: true
  }
)
const ShirtsWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/ShirtsWomen'),
  {
    suspense: true
  }
)
const Sets = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Sets'),
  {
    suspense: true
  }
)
const Jumpsuits = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Jumpsuits'),
  {
    suspense: true
  }
)
const Tops = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Tops'),
  {
    suspense: true
  }
)
const BlazersWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/BlazersWomen'),
  {
    suspense: true
  }
)
const ShortsWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/ShortsWomen'),
  {
    suspense: true
  }
)
const ThermalWomen = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/womensClothing/ThermalWomen'),
  {
    suspense: true
  }
)
const Maternity = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Maternity'),
  {
    suspense: true
  }
)
const Body = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/womensClothing/Body'),
  {
    suspense: true
  }
)
/* ----------------FROGIES--------------------*/
const BoxersFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/BoxersFrogies'),
  {
    suspense: true
  }
)
const BrasFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/BrasFrogies'),
  {
    suspense: true
  }
)
const CapsFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/CapsFrogies'),
  {
    suspense: true
  }
)
const CheerssocksFrogies = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/frogies/CheerssocksFrogies'),
  {
    suspense: true
  }
)
const DressesFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/DressesFrogies'),
  {
    suspense: true
  }
)
const GiftboxFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/GiftboxFrogies'),
  {
    suspense: true
  }
)
const GlovesFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/GlovesFrogies'),
  {
    suspense: true
  }
)
const JacketsFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/JacketsFrogies'),
  {
    suspense: true
  }
)
const PantiesFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/PantiesFrogies'),
  {
    suspense: true
  }
)
const PyjamasFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/PyjamasFrogies'),
  {
    suspense: true
  }
)
const ShawlsFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/ShawlsFrogies'),
  {
    suspense: true
  }
)
const SlidersFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/SlidersFrogies'),
  {
    suspense: true
  }
)
const SlippersFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/SlippersFrogies'),
  {
    suspense: true
  }
)
const SocksFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/SocksFrogies'),
  {
    suspense: true
  }
)
const SweatersFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/SweatersFrogies'),
  {
    suspense: true
  }
)
const SwimwearMenFrogies = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/frogies/SwimwearMenFrogies'),
  {
    suspense: true
  }
)
const TshirtsMenFrogies = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/frogies/TshirtsMenFrogies'),
  {
    suspense: true
  }
)
const TshirtsWomenFrogies = dynamic(
  () =>
    import('@/components/atoms/svg/categoryIcons/frogies/TshirtsWomenFrogies'),
  {
    suspense: true
  }
)
const VouchersFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/VouchersFrogies'),
  {
    suspense: true
  }
)
const StockingsFrogies = dynamic(
  () => import('@/components/atoms/svg/categoryIcons/frogies/StockingsFrogies'),
  {
    suspense: true
  }
)

export const CategoryIconsList: Record<string, JSX.Element> = {
  // Show All
  '-1': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  // Show all Zeny
  '349': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '351': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '353': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '1829': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  // Show all Muzi
  '355': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '357': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '359': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  // Show all Deti
  '377': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '379': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '363': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  '365': (
    <Suspense fallback={<LoadingReplace />}>
      <ShowAll />
    </Suspense>
  ),
  // Mens Clothes
  '1405': (
    <Suspense fallback={<LoadingReplace />}>
      <BlazersMen />
    </Suspense>
  ),
  '234': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsMen />
    </Suspense>
  ),
  '1225': (
    <Suspense fallback={<LoadingReplace />}>
      <JeansMen />
    </Suspense>
  ),
  '242': (
    <Suspense fallback={<LoadingReplace />}>
      <PantsMen />
    </Suspense>
  ),
  '78': (
    <Suspense fallback={<LoadingReplace />}>
      <PyjamasMen />
    </Suspense>
  ),
  '48': (
    <Suspense fallback={<LoadingReplace />}>
      <ShirtsMen />
    </Suspense>
  ),
  '70': (
    <Suspense fallback={<LoadingReplace />}>
      <ShortsMen />
    </Suspense>
  ),
  '54': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersMen />
    </Suspense>
  ),
  '235': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatshirtsMen />
    </Suspense>
  ),
  '72': (
    <Suspense fallback={<LoadingReplace />}>
      <Swimwear />
    </Suspense>
  ),
  '493': (
    <Suspense fallback={<LoadingReplace />}>
      <ThermalMen />
    </Suspense>
  ),
  '64': (
    <Suspense fallback={<LoadingReplace />}>
      <TracksuitMen />
    </Suspense>
  ),
  '241': (
    <Suspense fallback={<LoadingReplace />}>
      <TshirtsMen />
    </Suspense>
  ),
  '195': (
    <Suspense fallback={<LoadingReplace />}>
      <UnderwearMen />
    </Suspense>
  ),
  '75': (
    <Suspense fallback={<LoadingReplace />}>
      <Happysocks />
    </Suspense>
  ),
  // Mens Shoes
  '268': (
    <Suspense fallback={<LoadingReplace />}>
      <AnkleshoesMen />
    </Suspense>
  ),
  '263': (
    <Suspense fallback={<LoadingReplace />}>
      <Sneakers />
    </Suspense>
  ),
  '653': (
    <Suspense fallback={<LoadingReplace />}>
      <Slipon />
    </Suspense>
  ),
  '182': (
    <Suspense fallback={<LoadingReplace />}>
      <Runningshoes />
    </Suspense>
  ),
  '283': (
    <Suspense fallback={<LoadingReplace />}>
      <Hikingshoes />
    </Suspense>
  ),
  '278': (
    <Suspense fallback={<LoadingReplace />}>
      <Moccasins />
    </Suspense>
  ),
  '649': (
    <Suspense fallback={<LoadingReplace />}>
      <SandalsMen />
    </Suspense>
  ),
  '271': (
    <Suspense fallback={<LoadingReplace />}>
      <Sliders />
    </Suspense>
  ),
  '190': (
    <Suspense fallback={<LoadingReplace />}>
      <Footballboots />
    </Suspense>
  ),
  '1591': (
    <Suspense fallback={<LoadingReplace />}>
      <Slippers />
    </Suspense>
  ),
  '285': (
    <Suspense fallback={<LoadingReplace />}>
      <WinterShoes />
    </Suspense>
  ),
  // Men Accessories
  '184': (
    <Suspense fallback={<LoadingReplace />}>
      <Baseballcaps />
    </Suspense>
  ),
  '707': (
    <Suspense fallback={<LoadingReplace />}>
      <Sunglasses />
    </Suspense>
  ),
  '705': (
    <Suspense fallback={<LoadingReplace />}>
      <Watches />
    </Suspense>
  ),
  '709': (
    <Suspense fallback={<LoadingReplace />}>
      <Wallets />
    </Suspense>
  ),
  '134': (
    <Suspense fallback={<LoadingReplace />}>
      <Belts />
    </Suspense>
  ),
  '1601': (
    <Suspense fallback={<LoadingReplace />}>
      <Accessories />
    </Suspense>
  ),
  '1355': (
    <Suspense fallback={<LoadingReplace />}>
      <Happysocks />
    </Suspense>
  ),
  '1602': (
    <Suspense fallback={<LoadingReplace />}>
      <Hats />
    </Suspense>
  ),
  '1440': (
    <Suspense fallback={<LoadingReplace />}>
      <Ties />
    </Suspense>
  ),
  '183': (
    <Suspense fallback={<LoadingReplace />}>
      <Caps />
    </Suspense>
  ),
  '193': (
    <Suspense fallback={<LoadingReplace />}>
      <Shawls />
    </Suspense>
  ),
  '185': (
    <Suspense fallback={<LoadingReplace />}>
      <Gloves />
    </Suspense>
  ),
  '1541': (
    <Suspense fallback={<LoadingReplace />}>
      <Towels />
    </Suspense>
  ),
  // Women Clothes
  '1073': (
    <Suspense fallback={<LoadingReplace />}>
      <Dresses />
    </Suspense>
  ),
  '243': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsWomen />
    </Suspense>
  ),
  '312': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersWomen />
    </Suspense>
  ),
  '246': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatShirtsWomen />
    </Suspense>
  ),
  '250': (
    <Suspense fallback={<LoadingReplace />}>
      <TShirtsWomen />
    </Suspense>
  ),
  '81': (
    <Suspense fallback={<LoadingReplace />}>
      <PyjamasWomen />
    </Suspense>
  ),
  '1265': (
    <Suspense fallback={<LoadingReplace />}>
      <UnderwearWomen />
    </Suspense>
  ),
  '62': (
    <Suspense fallback={<LoadingReplace />}>
      <Swimsuits />
    </Suspense>
  ),
  '1205': (
    <Suspense fallback={<LoadingReplace />}>
      <JeansWomen />
    </Suspense>
  ),
  '44': (
    <Suspense fallback={<LoadingReplace />}>
      <TracksuitWomen />
    </Suspense>
  ),
  '40': (
    <Suspense fallback={<LoadingReplace />}>
      <Leggings />
    </Suspense>
  ),
  '260': (
    <Suspense fallback={<LoadingReplace />}>
      <PantsWomen />
    </Suspense>
  ),
  '1063': (
    <Suspense fallback={<LoadingReplace />}>
      <Skirts />
    </Suspense>
  ),
  '13': (
    <Suspense fallback={<LoadingReplace />}>
      <Blouses />
    </Suspense>
  ),
  '1197': (
    <Suspense fallback={<LoadingReplace />}>
      <ShirtsWomen />
    </Suspense>
  ),
  '1449': (
    <Suspense fallback={<LoadingReplace />}>
      <Sets />
    </Suspense>
  ),
  '1065': (
    <Suspense fallback={<LoadingReplace />}>
      <Jumpsuits />
    </Suspense>
  ),
  '1475': (
    <Suspense fallback={<LoadingReplace />}>
      <Tops />
    </Suspense>
  ),
  '1393': (
    <Suspense fallback={<LoadingReplace />}>
      <BlazersWomen />
    </Suspense>
  ),
  '41': (
    <Suspense fallback={<LoadingReplace />}>
      <ShortsWomen />
    </Suspense>
  ),
  '495': (
    <Suspense fallback={<LoadingReplace />}>
      <ThermalWomen />
    </Suspense>
  ),
  '1899': (
    <Suspense fallback={<LoadingReplace />}>
      <Maternity />
    </Suspense>
  ),
  // Women Shoes
  '102': (
    <Suspense fallback={<LoadingReplace />}>
      <Sneakers />
    </Suspense>
  ),
  '685': (
    <Suspense fallback={<LoadingReplace />}>
      <Slipon />
    </Suspense>
  ),
  '89': (
    <Suspense fallback={<LoadingReplace />}>
      <Moccasins />
    </Suspense>
  ),
  '279': (
    <Suspense fallback={<LoadingReplace />}>
      <AnkleShoesWomen />
    </Suspense>
  ),
  '533': (
    <Suspense fallback={<LoadingReplace />}>
      <Runningshoes />
    </Suspense>
  ),
  '83': (
    <Suspense fallback={<LoadingReplace />}>
      <Balerinas />
    </Suspense>
  ),
  '87': (
    <Suspense fallback={<LoadingReplace />}>
      <Espadrilles />
    </Suspense>
  ),
  '647': (
    <Suspense fallback={<LoadingReplace />}>
      <SandalsWomen />
    </Suspense>
  ),
  '98': (
    <Suspense fallback={<LoadingReplace />}>
      <Sliders />
    </Suspense>
  ),
  '180': (
    <Suspense fallback={<LoadingReplace />}>
      <Hikingshoes />
    </Suspense>
  ),
  '294': (
    <Suspense fallback={<LoadingReplace />}>
      <Courtshoes />
    </Suspense>
  ),
  '286': (
    <Suspense fallback={<LoadingReplace />}>
      <Boots />
    </Suspense>
  ),
  '314': (
    <Suspense fallback={<LoadingReplace />}>
      <WinterShoes />
    </Suspense>
  ),
  '300': (
    <Suspense fallback={<LoadingReplace />}>
      <Slippers />
    </Suspense>
  ),
  '224': (
    <Suspense fallback={<LoadingReplace />}>
      <Wellingtons />
    </Suspense>
  ),
  // Women Accessories
  '1636': (
    <Suspense fallback={<LoadingReplace />}>
      <Handbags />
    </Suspense>
  ),
  '1643': (
    <Suspense fallback={<LoadingReplace />}>
      <Jewellery />
    </Suspense>
  ),
  '156': (
    <Suspense fallback={<LoadingReplace />}>
      <Sunglasses />
    </Suspense>
  ),
  '292': (
    <Suspense fallback={<LoadingReplace />}>
      <Watches />
    </Suspense>
  ),
  '132': (
    <Suspense fallback={<LoadingReplace />}>
      <Belts />
    </Suspense>
  ),
  '128': (
    <Suspense fallback={<LoadingReplace />}>
      <Wallets />
    </Suspense>
  ),
  '367': (
    <Suspense fallback={<LoadingReplace />}>
      <Baseballcaps />
    </Suspense>
  ),
  '1603': (
    <Suspense fallback={<LoadingReplace />}>
      <Hats />
    </Suspense>
  ),
  '1353': (
    <Suspense fallback={<LoadingReplace />}>
      <Happysocks />
    </Suspense>
  ),
  '186': (
    <Suspense fallback={<LoadingReplace />}>
      <Caps />
    </Suspense>
  ),
  '59': (
    <Suspense fallback={<LoadingReplace />}>
      <Shawls />
    </Suspense>
  ),
  '60': (
    <Suspense fallback={<LoadingReplace />}>
      <Gloves />
    </Suspense>
  ),
  '1543': (
    <Suspense fallback={<LoadingReplace />}>
      <Towels />
    </Suspense>
  ),
  // Women Plus Size
  '1830': (
    <Suspense fallback={<LoadingReplace />}>
      <TShirtsWomen />
    </Suspense>
  ),
  '1833': (
    <Suspense fallback={<LoadingReplace />}>
      <Tops />
    </Suspense>
  ),
  '1834': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatShirtsWomen />
    </Suspense>
  ),
  '1835': (
    <Suspense fallback={<LoadingReplace />}>
      <PantsWomen />
    </Suspense>
  ),
  '1836': (
    <Suspense fallback={<LoadingReplace />}>
      <Leggings />
    </Suspense>
  ),
  '1837': (
    <Suspense fallback={<LoadingReplace />}>
      <Body />
    </Suspense>
  ),
  '1838': (
    <Suspense fallback={<LoadingReplace />}>
      <Sets />
    </Suspense>
  ),
  '1839': (
    <Suspense fallback={<LoadingReplace />}>
      <UnderwearWomen />
    </Suspense>
  ),
  '1840': (
    <Suspense fallback={<LoadingReplace />}>
      <Skirts />
    </Suspense>
  ),
  '1898': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersWomen />
    </Suspense>
  ),
  '1841': (
    <Suspense fallback={<LoadingReplace />}>
      <PyjamasWomen />
    </Suspense>
  ),
  '1845': (
    <Suspense fallback={<LoadingReplace />}>
      <Swimsuits />
    </Suspense>
  ),
  '1842': (
    <Suspense fallback={<LoadingReplace />}>
      <Dresses />
    </Suspense>
  ),
  '1843': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsWomen />
    </Suspense>
  ),
  // Children Girls Clothes
  '381': (
    <Suspense fallback={<LoadingReplace />}>
      <TShirtsWomen />
    </Suspense>
  ),
  '389': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatShirtsWomen />
    </Suspense>
  ),
  '397': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsMen />
    </Suspense>
  ),
  '407': (
    <Suspense fallback={<LoadingReplace />}>
      <Dresses />
    </Suspense>
  ),
  '451': (
    <Suspense fallback={<LoadingReplace />}>
      <Jumpsuits />
    </Suspense>
  ),
  '1309': (
    <Suspense fallback={<LoadingReplace />}>
      <UnderwearWomen />
    </Suspense>
  ),
  '411': (
    <Suspense fallback={<LoadingReplace />}>
      <ShortsWomen />
    </Suspense>
  ),
  '143': (
    <Suspense fallback={<LoadingReplace />}>
      <Swimsuits />
    </Suspense>
  ),
  '135': (
    <Suspense fallback={<LoadingReplace />}>
      <Leggings />
    </Suspense>
  ),
  '409': (
    <Suspense fallback={<LoadingReplace />}>
      <PantsWomen />
    </Suspense>
  ),
  '405': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersWomen />
    </Suspense>
  ),
  '1347': (
    <Suspense fallback={<LoadingReplace />}>
      <ThermalWomen />
    </Suspense>
  ),
  '1623': (
    <Suspense fallback={<LoadingReplace />}>
      <Multipacks />
    </Suspense>
  ),
  '1797': (
    <Suspense fallback={<LoadingReplace />}>
      <Littleones />
    </Suspense>
  ),
  // Children Boys Clothes
  '415': (
    <Suspense fallback={<LoadingReplace />}>
      <TshirtsMen />
    </Suspense>
  ),
  '425': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatshirtsMen />
    </Suspense>
  ),
  '433': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsMen />
    </Suspense>
  ),
  '453': (
    <Suspense fallback={<LoadingReplace />}>
      <Jumpsuits />
    </Suspense>
  ),
  '1311': (
    <Suspense fallback={<LoadingReplace />}>
      <UnderwearMen />
    </Suspense>
  ),
  '445': (
    <Suspense fallback={<LoadingReplace />}>
      <ShortsMen />
    </Suspense>
  ),
  '1321': (
    <Suspense fallback={<LoadingReplace />}>
      <Swimwear />
    </Suspense>
  ),
  '449': (
    <Suspense fallback={<LoadingReplace />}>
      <TracksuitMen />
    </Suspense>
  ),
  '443': (
    <Suspense fallback={<LoadingReplace />}>
      <PantsMen />
    </Suspense>
  ),
  '441': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersMen />
    </Suspense>
  ),
  '447': (
    <Suspense fallback={<LoadingReplace />}>
      <ShirtsMen />
    </Suspense>
  ),
  '119': (
    <Suspense fallback={<LoadingReplace />}>
      <ThermalMen />
    </Suspense>
  ),
  '1796': (
    <Suspense fallback={<LoadingReplace />}>
      <Littleones />
    </Suspense>
  ),
  '1624': (
    <Suspense fallback={<LoadingReplace />}>
      <Multipacks />
    </Suspense>
  ),
  // Children shoes
  '309': (
    <Suspense fallback={<LoadingReplace />}>
      <Sneakers />
    </Suspense>
  ),
  '306': (
    <Suspense fallback={<LoadingReplace />}>
      <AnkleshoesMen />
    </Suspense>
  ),
  '189': (
    <Suspense fallback={<LoadingReplace />}>
      <Balerinas />
    </Suspense>
  ),
  '151': (
    <Suspense fallback={<LoadingReplace />}>
      <SandalsMen />
    </Suspense>
  ),
  '308': (
    <Suspense fallback={<LoadingReplace />}>
      <Moccasins />
    </Suspense>
  ),
  '1271': (
    <Suspense fallback={<LoadingReplace />}>
      <Hikingshoes />
    </Suspense>
  ),
  '681': (
    <Suspense fallback={<LoadingReplace />}>
      <Wellingtons />
    </Suspense>
  ),
  '150': (
    <Suspense fallback={<LoadingReplace />}>
      <WinterShoes />
    </Suspense>
  ),
  '191': (
    <Suspense fallback={<LoadingReplace />}>
      <Footballboots />
    </Suspense>
  ),
  '192': (
    <Suspense fallback={<LoadingReplace />}>
      <Slippers />
    </Suspense>
  ),
  '155': (
    <Suspense fallback={<LoadingReplace />}>
      <Sneakers />
    </Suspense>
  ),
  // Children Accessories
  '177': (
    <Suspense fallback={<LoadingReplace />}>
      <Baseballcaps />
    </Suspense>
  ),
  '1357': (
    <Suspense fallback={<LoadingReplace />}>
      <Happysocks />
    </Suspense>
  ),
  '141': (
    <Suspense fallback={<LoadingReplace />}>
      <Caps />
    </Suspense>
  ),
  '140': (
    <Suspense fallback={<LoadingReplace />}>
      <Shawls />
    </Suspense>
  ),
  '142': (
    <Suspense fallback={<LoadingReplace />}>
      <Gloves />
    </Suspense>
  ),
  // Frogies
  '1873': (
    <Suspense fallback={<LoadingReplace />}>
      <BoxersFrogies />
    </Suspense>
  ),
  '1890': (
    <Suspense fallback={<LoadingReplace />}>
      <BoxersFrogies />
    </Suspense>
  ),
  '1907': (
    <Suspense fallback={<LoadingReplace />}>
      <BoxersFrogies />
    </Suspense>
  ),
  '1857': (
    <Suspense fallback={<LoadingReplace />}>
      <BrasFrogies />
    </Suspense>
  ),
  '1883': (
    <Suspense fallback={<LoadingReplace />}>
      <CapsFrogies />
    </Suspense>
  ),
  '1869': (
    <Suspense fallback={<LoadingReplace />}>
      <CapsFrogies />
    </Suspense>
  ),
  '1894': (
    <Suspense fallback={<LoadingReplace />}>
      <CapsFrogies />
    </Suspense>
  ),
  '1875': (
    <Suspense fallback={<LoadingReplace />}>
      <CheerssocksFrogies />
    </Suspense>
  ),
  '1861': (
    <Suspense fallback={<LoadingReplace />}>
      <CheerssocksFrogies />
    </Suspense>
  ),
  '1863': (
    <Suspense fallback={<LoadingReplace />}>
      <DressesFrogies />
    </Suspense>
  ),
  '1886': (
    <Suspense fallback={<LoadingReplace />}>
      <GiftboxFrogies />
    </Suspense>
  ),
  '1871': (
    <Suspense fallback={<LoadingReplace />}>
      <GiftboxFrogies />
    </Suspense>
  ),
  '1896': (
    <Suspense fallback={<LoadingReplace />}>
      <GiftboxFrogies />
    </Suspense>
  ),
  '1884': (
    <Suspense fallback={<LoadingReplace />}>
      <GlovesFrogies />
    </Suspense>
  ),
  '1868': (
    <Suspense fallback={<LoadingReplace />}>
      <GlovesFrogies />
    </Suspense>
  ),
  '1895': (
    <Suspense fallback={<LoadingReplace />}>
      <GlovesFrogies />
    </Suspense>
  ),
  '1876': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsFrogies />
    </Suspense>
  ),
  '1862': (
    <Suspense fallback={<LoadingReplace />}>
      <JacketsFrogies />
    </Suspense>
  ),
  '1858': (
    <Suspense fallback={<LoadingReplace />}>
      <PantiesFrogies />
    </Suspense>
  ),
  '1891': (
    <Suspense fallback={<LoadingReplace />}>
      <PantiesFrogies />
    </Suspense>
  ),
  '1909': (
    <Suspense fallback={<LoadingReplace />}>
      <PantiesFrogies />
    </Suspense>
  ),
  '1879': (
    <Suspense fallback={<LoadingReplace />}>
      <PyjamasFrogies />
    </Suspense>
  ),
  '1859': (
    <Suspense fallback={<LoadingReplace />}>
      <PyjamasFrogies />
    </Suspense>
  ),
  '1889': (
    <Suspense fallback={<LoadingReplace />}>
      <PyjamasFrogies />
    </Suspense>
  ),
  '1885': (
    <Suspense fallback={<LoadingReplace />}>
      <ShawlsFrogies />
    </Suspense>
  ),
  '1870': (
    <Suspense fallback={<LoadingReplace />}>
      <ShawlsFrogies />
    </Suspense>
  ),
  '1880': (
    <Suspense fallback={<LoadingReplace />}>
      <SlidersFrogies />
    </Suspense>
  ),
  '1865': (
    <Suspense fallback={<LoadingReplace />}>
      <SlidersFrogies />
    </Suspense>
  ),
  '1893': (
    <Suspense fallback={<LoadingReplace />}>
      <SlidersFrogies />
    </Suspense>
  ),
  '1881': (
    <Suspense fallback={<LoadingReplace />}>
      <SlippersFrogies />
    </Suspense>
  ),
  '1866': (
    <Suspense fallback={<LoadingReplace />}>
      <SlippersFrogies />
    </Suspense>
  ),
  '1874': (
    <Suspense fallback={<LoadingReplace />}>
      <SocksFrogies />
    </Suspense>
  ),
  '1860': (
    <Suspense fallback={<LoadingReplace />}>
      <SocksFrogies />
    </Suspense>
  ),
  '1888': (
    <Suspense fallback={<LoadingReplace />}>
      <SocksFrogies />
    </Suspense>
  ),
  '1906': (
    <Suspense fallback={<LoadingReplace />}>
      <SocksFrogies />
    </Suspense>
  ),
  '1908': (
    <Suspense fallback={<LoadingReplace />}>
      <SocksFrogies />
    </Suspense>
  ),
  '1882': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersFrogies />
    </Suspense>
  ),
  '1867': (
    <Suspense fallback={<LoadingReplace />}>
      <SweatersFrogies />
    </Suspense>
  ),
  '1878': (
    <Suspense fallback={<LoadingReplace />}>
      <SwimwearMenFrogies />
    </Suspense>
  ),
  '1877': (
    <Suspense fallback={<LoadingReplace />}>
      <TshirtsMenFrogies />
    </Suspense>
  ),
  '1864': (
    <Suspense fallback={<LoadingReplace />}>
      <TshirtsWomenFrogies />
    </Suspense>
  ),
  '1887': (
    <Suspense fallback={<LoadingReplace />}>
      <VouchersFrogies />
    </Suspense>
  ),
  '1872': (
    <Suspense fallback={<LoadingReplace />}>
      <VouchersFrogies />
    </Suspense>
  ),
  '1897': (
    <Suspense fallback={<LoadingReplace />}>
      <VouchersFrogies />
    </Suspense>
  ),
  '1892': (
    <Suspense fallback={<LoadingReplace />}>
      <StockingsFrogies />
    </Suspense>
  )
}
