import { GetServerSidePropsContext, NextPageContext } from 'next'
import { useCookie } from 'next-cookie'
import uniqid from 'uniqid'
import { v4 as uuidv4 } from 'uuid'
import { CookieSetOptions } from 'universal-cookie'
import md5 from '@/core/crypto/md5'

export type CookieContext = NextPageContext | GetServerSidePropsContext | string

const ambienteSessionPropertyName = 'FACTCOOL'
const propertyName = 'fcSessionId'
const options: CookieSetOptions = {
  maxAge: 30 * 24 * 60 * 60, // 30 days
  path: '/',
  secure: process.env.NODE_ENV === 'production',
  sameSite: 'lax'
}

const generateSessionId = (): string =>
  md5(`${uuidv4()}-${uniqid()}-${uuidv4()}`)

export const clearSessionId = (context?: CookieContext): void => {
  const cookie = useCookie(context)
  cookie.remove(propertyName)
  cookie.remove(ambienteSessionPropertyName)
}

export const initializeSessionId = (context?: CookieContext): string => {
  const cookie = useCookie(context)
  let value: string = null
  if (cookie.has(propertyName)) {
    value = cookie.get<string>(propertyName)
  } else if (cookie.has(ambienteSessionPropertyName)) {
    value = cookie.get<string>(ambienteSessionPropertyName)
    cookie.remove(ambienteSessionPropertyName)
  } else {
    value = generateSessionId()
  }
  cookie.set(propertyName, value, options)
  return value
}

export const getSessionId = (context?: CookieContext): string => {
  const cookie = useCookie(context)
  return cookie.has(propertyName)
    ? cookie.get(propertyName)
    : initializeSessionId(context)
}
