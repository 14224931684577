import { createMedia } from '@artsy/fresnel'
import { breakpoints } from 'theme/breakpoints'
import { useEffect, useState } from 'react'
import type { FC } from 'react'
import useDevice from 'hooks/useDevice'

const {
  MediaContextProvider: BaseMediaContextProvider,
  Media,
  createMediaStyle
} = createMedia({
  breakpoints: {
    mobile: breakpoints.mobile.min,
    tablet: breakpoints.tablet.min,
    desktop: breakpoints.desktop.min
  }
})

export const MediaContextProvider: FC = ({ children }) => (
  // TODO PLM remove disableDynamicMediaQueries if issue with hydration is fixed https://github.com/artsy/fresnel/issues/260
  <BaseMediaContextProvider disableDynamicMediaQueries>
    {children}
  </BaseMediaContextProvider>
)

export const mediaStyles = createMediaStyle()

const useMedia = (isDevice: boolean) => {
  const [show, setShow] = useState<boolean>(true)
  useEffect(() => {
    setShow(isDevice)
  }, [isDevice])
  return show
}

export const Desktop: FC = ({ children }) => {
  const { isDesktop } = useDevice()
  const show = useMedia(isDesktop)
  return <Media greaterThanOrEqual="desktop">{show && children}</Media>
}

export const Tablet: FC = ({ children }) => {
  const { isTablet } = useDevice()
  const show = useMedia(isTablet)
  return <Media between={['tablet', 'desktop']}>{show && children}</Media>
}

export const Mobile: FC = ({ children }) => {
  const { isMobile } = useDevice()
  const show = useMedia(isMobile)
  return <Media lessThan="tablet">{show && children}</Media>
}

export const TabletOrMobile: FC = ({ children }) => {
  const { isTablet, isMobile } = useDevice()
  const show = useMedia(isTablet || isMobile)
  return <Media lessThan="desktop">{show && children}</Media>
}

export const DesktopOrTablet: FC = ({ children }) => {
  const { isDesktop, isTablet } = useDevice()
  const show = useMedia(isDesktop || isTablet)
  return <Media greaterThan="mobile">{show && children}</Media>
}
