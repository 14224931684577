import { FC } from 'react'

const LegginsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="leggins-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="5.55" y="2" width="44.43" height="10.29" />
        <path d="M50,12.29c5.93,5.59,2.63,139.71,2.63,139.71h-11.16s-2.78-107.14-13.7-107.14c-10.91,0-13.7,107.14-13.7,107.14H2.92S-.39,17.88,5.55,12.29" />
      </g>
    </symbol>
  </svg>
)

export default LegginsDefinition
