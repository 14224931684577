import { FC } from 'react'

const PantiesFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="panties-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <rect x="9.73" y="4" width="138.68" height="12.72" />
        <path d="M148.27,16.72H9.73L4,52.98s44.85,5.41,54.07,19.72c8.37,12.99,7.57,16.28,19.01,16.78l-.07,.07h3.98l-.07-.07c11.44-.5,10.64-3.8,19.01-16.78,9.22-14.31,54.07-19.72,54.07-19.72l-5.73-36.26Z" />
      </g>
    </symbol>
  </svg>
)

export default PantiesFrogiesDefinition
