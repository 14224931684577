import { FC } from 'react'

const TShirtsWomenDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="tshirts-women-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M29.55,75.03c2.5-9.49,3.59-10.86,5.73-19.22,1.92,12.91,4.6,17.86,4.14,32.96,.34,11.14-1.11,29.18-3.68,48.54H118.2c-2.57-19.36-4.02-37.41-3.68-48.54-.47-15.1,2.22-20.05,4.14-32.96,2.14,8.36,3.23,9.73,5.73,19.22l27.61-4c-2.49-9.96-9.76-35.72-12.23-45.38-.1-1.28-1.1-2.83-3.07-4.51,0,0-26.98-19.15-33.63-19.15H50.93c-6.65,0-33.63,19.15-33.63,19.15-1.97,1.68-2.96,3.23-3.07,4.51-2.48,9.65-9.74,35.41-12.23,45.38l27.55,4Z" />
        <path d="M49.19,2.39c2.64,15.33,14.13,26.87,27.92,26.87s25.27-11.54,27.91-26.87" />
      </g>
    </symbol>
  </svg>
)

export default TShirtsWomenDefinition
