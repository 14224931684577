import {
  isSentryEnabled,
  sentryIgnoreErrors,
  sentrySampleRate,
  sentryTracesSampleRate
} from '@/providers/storeProvider'
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://examplePublicKey@o0.ingest.sentry.io/0',
  maxBreadcrumbs: 1000,
  normalizeDepth: 10,
  enabled: isSentryEnabled(window.location.host),
  tracesSampleRate: sentryTracesSampleRate,
  sampleRate: sentrySampleRate,
  ignoreErrors: sentryIgnoreErrors
})
