import { FC } from 'react'

const MultipacksDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="multipacks-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M121.13,52.44V2H91.43V44.88a25.31,25.31,0,0,0-4.09,4.06L66.44,75.25c-7.45,9.39-7,22.26,1,28.61s20.64,3.87,28.09-5.51L116.42,72C121.17,66.05,122.7,58.66,121.13,52.44Z" />
        <path d="M105.06,59.37c2.41-5.66,9.44-8.18,16.25-6.1-.06-.28-.11-.56-.18-.83V2H91.43V44.88a25.31,25.31,0,0,0-4.09,4.06L66.44,75.25c-7.45,9.39-7,22.26,1,28.61s20.64,3.87,28.09-5.51L113.27,76C106.15,72.82,102.48,65.44,105.06,59.37Z" />
        <path d="M121.31,53.27c-.06-.28-.11-.56-.18-.83V2H91.43V44.88a25.31,25.31,0,0,0-4.09,4.06L66.44,75.25A26,26,0,0,0,64,78.93l1.07,0A27.17,27.17,0,0,1,91.94,102a25.26,25.26,0,0,0,3.58-3.65L113.27,76c-7.12-3.18-10.79-10.56-8.21-16.63C107.47,53.71,114.5,51.19,121.31,53.27Z" />
        <path d="M62,52.44V2H32.3V44.88a25.31,25.31,0,0,0-4.09,4.06L7.31,75.25c-7.45,9.39-7,22.26,1,28.61s20.64,3.87,28.09-5.51L57.29,72C62,66.05,63.57,58.66,62,52.44Z" />
        <path d="M45.93,59.37c2.42-5.66,9.44-8.18,16.25-6.1-.06-.28-.11-.56-.18-.83V2H32.3V44.88a25.31,25.31,0,0,0-4.09,4.06L7.31,75.25c-7.45,9.39-7,22.26,1,28.61s20.64,3.87,28.09-5.51L54.14,76C47,72.82,43.35,65.44,45.93,59.37Z" />
        <path d="M62.18,53.27c-.06-.28-.11-.56-.18-.83V2H32.3V44.88a25.31,25.31,0,0,0-4.09,4.06L7.31,75.25a26,26,0,0,0-2.43,3.68l1.07,0A27.17,27.17,0,0,1,32.81,102a25.26,25.26,0,0,0,3.58-3.65L54.14,76C47,72.82,43.35,65.44,45.93,59.37,48.35,53.71,55.37,51.19,62.18,53.27Z" />
        <line x1="32.3" y1="9.53" x2="62.18" y2="9.53" />
        <line x1="91.22" y1="9.53" x2="121.13" y2="9.53" />
        <path d="M131.63,97.05V46.62h-29.7V89.49a25.31,25.31,0,0,0-4.09,4.06l-20.9,26.32c-7.45,9.38-7,22.26,1,28.61S98.56,152.35,106,143l20.9-26.31A23,23,0,0,0,131.63,97.05Z" />
        <path d="M115.56,104c2.41-5.67,9.44-8.19,16.25-6.11-.06-.27-.11-.55-.18-.83V46.62h-29.7V89.49a25.31,25.31,0,0,0-4.09,4.06l-20.9,26.32c-7.45,9.38-7,22.26,1,28.61S98.56,152.35,106,143l17.75-22.34C116.65,117.43,113,110.05,115.56,104Z" />
        <path d="M131.81,97.88c-.06-.27-.11-.55-.18-.83V46.62h-29.7V89.49a25.31,25.31,0,0,0-4.09,4.06l-20.9,26.32a24.37,24.37,0,0,0-2.43,3.68c.35,0,.71,0,1.07,0a27.16,27.16,0,0,1,26.85,23.1A24.89,24.89,0,0,0,106,143l17.75-22.34c-7.12-3.19-10.8-10.57-8.21-16.63C118,98.32,125,95.8,131.81,97.88Z" />
        <path d="M72.5,97.05V46.62H42.8V89.49a25.31,25.31,0,0,0-4.09,4.06l-20.9,26.32c-7.45,9.38-7,22.26,1,28.61s20.63,3.87,28.09-5.52l20.9-26.31A23,23,0,0,0,72.5,97.05Z" />
        <path d="M56.43,104c2.41-5.67,9.44-8.19,16.25-6.11-.06-.27-.11-.55-.18-.83V46.62H42.8V89.49a25.31,25.31,0,0,0-4.09,4.06l-20.9,26.32c-7.45,9.38-7,22.26,1,28.61s20.63,3.87,28.09-5.52l17.75-22.34C57.52,117.43,53.84,110.05,56.43,104Z" />
        <path d="M72.68,97.88c-.06-.27-.11-.55-.18-.83V46.62H42.8V89.49a25.31,25.31,0,0,0-4.09,4.06l-20.9,26.32a24.37,24.37,0,0,0-2.43,3.68c.35,0,.71,0,1.07,0a27.16,27.16,0,0,1,26.85,23.1A24.89,24.89,0,0,0,46.89,143l17.75-22.34c-7.12-3.19-10.8-10.57-8.21-16.63C58.84,98.32,65.87,95.8,72.68,97.88Z" />
        <line x1="42.8" y1="54.14" x2="72.68" y2="54.14" />
        <line x1="101.72" y1="54.14" x2="131.63" y2="54.14" />
      </g>
    </symbol>
  </svg>
)

export default MultipacksDefinition
