import { FC } from 'react'

const HatsDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="hats-icon-definition">
      <g
        fill="none"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M77,2C19.45,2,33.43,19.33,29.63,42.06c8.06,21.6,86.68,21.6,94.75,0-3.81-22.73,11.36-40.06-47.37-40.06Z" />
        <path d="M123.91,38.75c17.13,4.72,28.09,11.96,28.09,20.08,0,14.21-33.58,25.73-75,25.73S2,73.04,2,58.83c0-8.12,10.97-15.37,28.11-20.08" />
        <path d="M31.26,34.63c29.15,17.65,59.91,17.67,92.28,0" />
      </g>
    </symbol>
  </svg>
)

export default HatsDefinition
