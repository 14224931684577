import { OrderData } from '@/network/graphql.g'

type MHubOrderData = {
  id: string
  coupon: string
  currencyCode: string
  delivery: number
  deliveryType: string
  discount: number
  localCurrencyCode: string
  localDelivery: number
  localDiscount: number
  localRevenue: number
  localTax: number
  localTotalValue: number
  paymentType: string
  processingType: string
  revenue: number
  tax: number
  totalValue: number
}

export type MHubOrder = {
  order: MHubOrderData
}

export const getEmptyMhubOrder = (): MHubOrder => getMhubOrder(null)

export const getMhubOrder = (order: OrderData): MHubOrder => {
  return {
    order: order
      ? {
          coupon: order.coupon,
          currencyCode: order.currencyCode,
          delivery: order.delivery,
          deliveryType: order.deliveryType,
          discount: order.discount,
          id: order.id,
          localCurrencyCode: order.localCurrencyCode,
          localDelivery: order.localDelivery,
          localDiscount: order.localDiscount,
          localRevenue: order.localRevenue,
          localTax: order.localTax,
          localTotalValue: order.localTotalValue,
          paymentType: order.paymentType,
          processingType: order.processingType,
          revenue: order.revenue,
          tax: order.tax,
          totalValue: order.totalValue
        }
      : null
  }
}
