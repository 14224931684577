import { FC } from 'react'

const SweatersFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="sweaters-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M154.93,126.08l-19.21-98.27c-1.01-5.5-3.88-11-6.37-12.21-2.49-1.21-23.52-11.6-26.28-11.6H57.09c-2.76,0-23.79,10.39-26.28,11.6-2.49,1.21-5.19,5.82-6.01,10.24-.69,3.74-19.57,100.24-19.57,100.24-1.01,5.5,2.51,11.26,7.83,12.78l1.4,.4c5.32,1.52,10.52-1.73,11.55-7.23l10.51-50.67c1.04-5.5,2.31-11.22,2.83-12.71,.52-1.49,.94,7.46,.94,13.06v72.28H119.86V81.73c0-5.6,.19-11.46,.42-13.02,.23-1.56,2.31,7.16,3.35,12.66l10.51,50.67c1.04,5.5,6.24,8.75,11.55,7.23l1.4-.4c5.32-1.52,8.84-7.28,7.83-12.78Z" />
        <polyline points="19.91 139.19 17.09 147.64 4 143.16 6.97 134.26" />
        <polyline points="140.16 139.19 142.98 147.64 156.07 143.16 153.1 134.26" />
        <path d="M103.16,4c0,12.91-10.33,23.38-23.08,23.38s-23.08-10.47-23.08-23.38" />
      </g>
    </symbol>
  </svg>
)

export default SweatersFrogiesDefinition
