import { FC } from 'react'

const PyjamasFrogiesDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="pyjamas-frogies-icon-definition">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8px"
      >
        <path d="M122.59,49.03L105.03,12.13h.02l-21.31-8.13H42.88l-17.23,6.58c-2.62,1-4.69,2.85-5.79,5.16L4,49.03l17.86,8.64,9.5-15.31V107.17h63.89V42.42l9.47,15.26,17.86-8.64Z" />
        <path d="M43.42,4c0,9.89,8.9,17.91,19.87,17.91s19.87-8.02,19.87-17.91" />
        <polyline points="31.24 106.95 17.46 148.08 58.09 154 63.25 137.06 68.41 154 109.13 148.08 95.26 106.95" />
      </g>
    </symbol>
  </svg>
)

export default PyjamasFrogiesDefinition
