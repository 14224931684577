import { Cookies } from '@/core/graphQlCookieManager'
import useDevice from 'hooks/useDevice'
import { useCookie } from 'next-cookie'

type MhubSessionData = {
  application: string
  deviceType: string
  env: string
  ip: string
  machine: string
  serverTime: number
}

type MhubSession = {
  session: MhubSessionData
}

const internalIps = [
  '78.141.75.228',
  '127.0.0.1',
  '172.20.0.2',
  '90.176.14.147',
  '62.197.213.27',
  '195.98.27.197',
  '213.81.168.98',
  '172.20.0.1',
  '90.176.14.147'
]

export const useMhubSession = (
  ip: string,
  serverTime: number
): {
  getSession: () => MhubSession
} => {
  const { isTablet, isDesktop } = useDevice()

  const getSession = (): MhubSession => ({
    session: {
      application: 'react.1.0',
      deviceType: isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile',
      env: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
      ip,
      machine: getMachineTraffic(ip),
      serverTime
    }
  })

  return {
    getSession
  }
}

const getMachineTraffic = (ip: string) => {
  const loginSubscriberCookie = !!useCookie().get<string>(
    Cookies.factcoolAdminUser
  )
  return internalIps.includes(ip) || loginSubscriberCookie
    ? 'internal'
    : 'external'
}
